import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import useImageSource from '../hooks/useImageSource/useImageSource';
import Loader from './Loader';
// import ErrorSplash from './ErrorSplash';
import Alert from '@mui/material/Alert';

const styles = ({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
    // transition: theme.transitions.create('all'),
    transition: theme.transitions.create('all'),
    '&.selected': {
        backgroundColor: 'rgba(25, 118, 210, 0.08)'
    },
});

const MediaLoader = styled(Loader)(({ theme }) => ({
}));


const ErrorSplash = styled(({ imageRatio: _, ...props }) => (
    <Alert severity="error" variant="filled" {...props} />
))(({ theme, iconSize = 50, imageRatio }) => ({
    padding: '0px',
    paddingTop: (100 * imageRatio) + '%',
    position: 'relative',
    '& .MuiAlert-icon': {
        padding: '0px',
        margin: '0px',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .MuiAlert-icon svg': {
        width: iconSize,
        height: iconSize,
    },
    '& .MuiAlert-message': {
        display: 'none',
    }
}));



function AppCard(props) {
    const {
        className, title, subTitle, children,
        image, imageRatio = 1, width, selected,
        disabled,
        ...forwardProps
    } = props;
    const hasTitle = title || subTitle;
    const {
        url: imageUrl,
        loading: imageLoading,
        error: imageError,
    } = useImageSource(image);

    const rootClasses = clsx(className, { selected });
    return (
        <Card
            raised={selected}
            className={rootClasses}
            sx={{ width, minWidth: width, maxWidth: width }}
            {...forwardProps}
        >
            {imageLoading && (
                <MediaLoader
                    size={80}
                    disableLoadingText
                    sx={{ height: imageRatio * width }}
                />
            )}
            {imageError && (
                <ErrorSplash imageRatio={imageRatio} />
            )}
            {imageUrl && (
                <CardMedia
                    component="img"
                    height={imageRatio * width}
                    alt={title}
                    image={imageUrl}
                />
            )}
            {hasTitle && (
                <CardHeader
                    title={title}
                    subheader={subTitle}
                    titleTypographyProps={{
                        noWrap: true,
                    }}
                    sx={{
                        '& .MuiCardHeader-content': {
                            overflow: 'hidden',
                        }
                    }}
                />
            )}
        </Card >
    );
}

export default styled(AppCard)(styles);

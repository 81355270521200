import { useState, useEffect } from 'react';
import useThrottle from './useThrottle';

function useBrowserSize({ eventThrottle = 250 } = {}) {
    const [size, setSize] = useState({});
    const { callback: setSizeThrottled } = useThrottle(setSize, { throttle: eventThrottle });
    useEffect(() => {
        const handleWindowResize = ({
            innerHeight,
            outerHeight,
            innerWidth,
            outerWidth,
        }) => setSizeThrottled({
            innerHeight,
            outerHeight,
            innerWidth,
            outerWidth,
        });
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    return size;
}

export default useBrowserSize;

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Badge } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Icon from 'components/Icon';
import { useGETState } from 'hooks/useQueryParams';
import React, { forwardRef, useCallback, useState } from 'react';
import useSelection from '../../hooks/useSelection';
import BackgroundProperties, { BACKGROUND_PROPERTIES } from './BackgroundProperties';
import BorderProperties, { BORDER_PROPERTIES } from './BorderProperties';
import FilterProperties from './FilterProperties';
import FontProperties, { FONT_PROPERTIES } from './FontProperties';
import LayerProperties from './LayerProperties';
import PositioningProperties, { POSITION_FIELDS } from './PositioningProperties';
import PostcardCanvasSwitcher from './PostcardCanvasSwitcher';

const styles = ({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxWidth: 350,
    minWidth: 350,
    '& .accordionIcon': {
        marginRight: theme.spacing(1),
    },

});

function getErrorCount(layerErrors, keys) {
    return Object.keys(layerErrors)
        .filter(errorKey => keys.includes(errorKey))
        .length;
}

const ELEVATION = 3;

function EditorSection(props) {
    const {
        title,
        icon,
        errorCount,
        onToggle,
        expanded,
        children,
        viewport,
    } = props;
    const [state, setState] = useState({ open: expanded });
    if (expanded) {
        state.open = true;
    }
    return (
        <Accordion
            TransitionProps={{
                addEndListener() {
                    setState({ open: false });
                }
            }}
            elevation={ELEVATION}
            expanded={expanded}
            onChange={onToggle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Badge
                    badgeContent={errorCount}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <Icon className="accordionIcon" name={icon} />
                </Badge>
                <Typography>{title}</Typography>
            </AccordionSummary>
            {state.open && children}
        </Accordion>
    );
}

const reduceLayerErrorCount = (accumulator, currentValue) => accumulator + Object.keys(currentValue).length;

function EditorProperties(props, ref) {
    const {
        className,
        state,
        layerSelection,
        viewport,
        currentPageIdentifier,
        layers,
        focusFront,
        focusBack,
        describeLayer,
        fieldErrors,
        pageErrors,
    } = props;
    const {
        locked,
        layerErrors,
        updateSelection,
        properties: selectionProperties,
    } = layerSelection;
    // console.log({ layerSelection })

    const disabled = locked; //Boolean(selectedLayers.length === 0);

    const handleUpdate = (event, ...args) => {
        if (typeof event === 'object' && 'target' in event) {
            updateSelection(event.target.name, event.target.value);
        } else {
            updateSelection(event, ...args);
        }
    };

    const {
        state: {
            panel: openPanel = undefined
        },
        replaceState
    } = useGETState();
    const setOpenPanel = useCallback((...args) => replaceState('panel', ...args), [replaceState]);
    const { isItemSelected, toggleItem } = useSelection({ multiple: false, stateHandle: [openPanel, setOpenPanel] });

    const handlePanelToggle = (panel) => () => toggleItem(panel);


    const layerErrorCount = pageErrors?.layers?.reduce(reduceLayerErrorCount, 0) || 0;
    const filtersLabel = selectionProperties.filters.length
        ? `Filters (${selectionProperties.filters.length})`
        : `Filters`;

    return (
        <div className={className} ref={ref}>
            <EditorSection
                title="Layers"
                errorCount={layerErrorCount}
                icon="layerProperties"
                expanded={isItemSelected('layersProperties')}
                onToggle={handlePanelToggle('layersProperties')}>
                <LayerProperties
                    viewport={viewport}
                    layers={layers}
                    layerErrors={layerErrors}
                    layerSelection={layerSelection}
                    pageErrors={pageErrors}
                    onChange={handleUpdate}
                />
            </EditorSection>
            <EditorSection
                title="Position"
                icon="positionProperties"
                errorCount={getErrorCount(layerErrors, POSITION_FIELDS)}
                expanded={isItemSelected('positionProperties')}
                onToggle={handlePanelToggle('positionProperties')}>
                <AccordionDetails>
                    <PositioningProperties
                        layerErrors={layerErrors}
                        describe={describeLayer}
                        disabled={disabled}
                        properties={selectionProperties}
                        onChange={handleUpdate}
                    />
                </AccordionDetails>
            </EditorSection>
            <EditorSection
                title="Background"
                icon="backgroundProperties"
                errorCount={getErrorCount(layerErrors, BACKGROUND_PROPERTIES)}
                expanded={isItemSelected('backgroundProperties')}
                onToggle={handlePanelToggle('backgroundProperties')}>
                <AccordionDetails>
                    <BackgroundProperties
                        layerErrors={layerErrors}
                        describe={describeLayer}
                        disabled={disabled}
                        properties={selectionProperties}
                        onChange={handleUpdate}
                    />
                </AccordionDetails>
            </EditorSection>
            <EditorSection
                title="Font Style"
                icon="fontProperties"
                errorCount={getErrorCount(layerErrors, FONT_PROPERTIES)}
                expanded={isItemSelected('fontProperties')}
                onToggle={handlePanelToggle('fontProperties')}>
                <AccordionDetails>
                    <FontProperties
                        layerErrors={layerErrors}
                        describe={describeLayer}
                        disabled={disabled}
                        properties={selectionProperties}
                        onChange={handleUpdate}
                    />
                </AccordionDetails>
            </EditorSection>
            <EditorSection
                title="Border Style"
                icon="borderProperties"
                errorCount={getErrorCount(layerErrors, BORDER_PROPERTIES)}
                expanded={isItemSelected('borderProperties')}
                onToggle={handlePanelToggle('borderProperties')}>
                <AccordionDetails>
                    <BorderProperties
                        layerErrors={layerErrors}
                        describe={describeLayer}
                        disabled={disabled}
                        properties={selectionProperties}
                        onChange={handleUpdate}
                    />
                </AccordionDetails>
            </EditorSection>
            <EditorSection
                title={filtersLabel}
                icon="filterProperties"
                expanded={isItemSelected('filterProperties')}
                onToggle={handlePanelToggle('filterProperties')}>
                <FilterProperties
                    layerErrors={layerErrors}
                    describe={describeLayer}
                    disabled={disabled}
                    properties={selectionProperties}
                    onChange={handleUpdate}
                    layerSelection={layerSelection}
                />
            </EditorSection>

            {/* {state.preview && ( */}
            <PostcardCanvasSwitcher
                state={state}
                preview={state.preview}
                currentPageIdentifier={currentPageIdentifier}
                onFocusFront={focusFront}
                onFocusBack={focusBack}
                viewport={viewport}
                layers={layers}
                layerSelection={layerSelection}
                fieldErrors={fieldErrors}
            />
            {/* )} */}
        </div>
    );
}

export default styled(forwardRef(EditorProperties))(styles);

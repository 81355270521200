import React, { useEffect, useRef, useState } from 'react';
import styledMaptypeConfig from '../configs/styledMapType.json';
import { MapProvider } from '../hooks/useMap';
import useScript from '../hooks/useScript';

window.gMapsLoaded = function () {
    // console.log('yay!');
    // intentional no op lol
};

function getMapCenter(events) {
    if (!events) {
        return { lat: 47.5698442, lng: -122.6673285 };
    }
    return { lat: events[0].Latitude, lng: events[0].Longitude };
    // let lng = 0;//events.reduce((prevValue, {Longitude}) => prevValue + Longitude)/events.length;
    // let lat = 0;//events.reduce((prevValue, {Latitude}) => prevValue + Latitude)/events.length;
    // events.forEach(({Longitude, Latitude}) => {
    //     lng += Longitude;
    //     lat += Latitude;
    // });
    // return {lat: lat/events.length, lng: lng/events.length};
}



function Map({ className, events, onLoaded, children, ...props }) {
    const [map, setMap] = useState(false);
    const mapRef = useRef(null);
    const libIsLoading = !useScript('//maps.googleapis.com/maps/api/js?key=AIzaSyDMTNyLKxA-osBuMZ6TS5bn1W8CVYpEZ3k&callback=gMapsLoaded');
    useEffect(() => {
        if (!libIsLoading) {
            // build map
            const map = new window.google.maps.Map(mapRef.current, {
                zoom: 14,
                // center: getMapCenter(events),
                disableDefaultUI: true,
                keyboardShortcuts: false,
                gestureHandling: 'none',
                zoomControl: false
            });
            if (onLoaded) {
                map.addListener('tilesloaded', onLoaded);
            }

            // let path = [],
            //     north = null,
            //     west = null,
            //     south = null,
            //     east = null;
            // events && events.forEach(({ Longitude, Latitude, City, State, Description }) => {
            //     const position = { lng: Longitude, lat: Latitude };
            //     if (north === null || Latitude > north) north = Latitude;
            //     if (south === null || Latitude < south) south = Latitude;
            //     if (west === null || Longitude < west) west = Longitude;
            //     if (east === null || Longitude > east) east = Longitude;
            //     path.push(position);
            //     new window.google.maps.Marker({
            //         position,
            //         map,
            //         icon: {
            //             path: window.google.maps.SymbolPath.CIRCLE,
            //             fillColor: '#ffffff',
            //             fillOpacity: 1,
            //             strokeColor: '#8ec3b9',
            //             strokeWeight: 8,
            //             scale: 16
            //         },
            //         // tilt: 45,
            //         title: `${City} ${State} - ${Description}`
            //     });
            // });
            // const line = new window.google.maps.Polyline({
            //     path,
            //     geodesic: true,
            //     strokeColor: '#8ec3b9',
            //     strokeWeight: 8,
            // });
            // line.setMap(map);
            // map.fitBounds({north, south, east, west}, {bottom: 210, left: 10, right: 10, top: 10});
            // map.fitBounds({}, {bottom: 210, left: 10, right: 10, top: 10});
            var styledMapType = new window.google.maps.StyledMapType(
                styledMaptypeConfig,
                { name: 'Styled Map' }
            );
            map.mapTypes.set('styled_map', styledMapType);
            map.setMapTypeId('styled_map');
            setMap(map);
        }
    }, [libIsLoading, setMap, onLoaded]);
    return (
        <MapProvider value={map}>
            <div className={className} ref={mapRef} {...props} />
            {children}
        </MapProvider>
    );
}

export default Map;

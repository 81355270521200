import axios from 'axios';

class GoogleFonts {
    apiKey = "AIzaSyBLbJn5JnBCS6bz493MSO7AoNUcE7671tM";

    getWebFonts = ({ sort = "popularity" } = {}) => {
        return axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${this.apiKey}&sort=${sort}`)
            .then(response => response.data);
    }
}

const googleFonts = new GoogleFonts();
export default googleFonts;

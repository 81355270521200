import { useTheme } from '@emotion/react';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, Tab, Tabs, Toolbar, styled, useMediaQuery } from '@mui/material';
import useImageLoader from 'hooks/useImageSource/useImageSource';
import React, { useCallback, useRef, useState } from 'react';
import { Checkboard } from 'react-color/lib/components/common';
import { useMojipostClient } from '../connectors/MojipostClient';
import usePersistentStateObject, { POST_SAVE_BAHAVIOR_CLEAR } from '../hooks/usePersistentStateObject';
import AppPreviewPanel from './AppPreviewPanel';
import DetailLayout from './DetailLayout';
import { MODE_WRITE } from './Field';
import Icon from './Icon';
import PersistentSetManager from './PersistentSetManager';
import Search from './Search';

const IMAGE_LAYOUT = [
    'file',
    'name',
    'description'
];

const IMAGE_DETAIL_LAYOUT = [
    'name',
    'description',
    [{
        name: 'dateUpdated',
        cellProps: { xs: 6 },
    }, {
        name: 'dateCreated',
        cellProps: { xs: 6 },
    }]
];

const TAB_UPLOAD_IMAGE = 'upload';
const TAB_IMAGE_LIBRARY = 'library';

const styles = ({ theme }) => ({
    '& .MuiDialog-paper': {
        [theme.breakpoints.up('sm')]: {
            height: '80%',
        }
        // transition: theme.transitions.create('height')
    },
    '& .grow': {
        flexGrow: 1,
    },
    '& .uploadPreview': {
        // backgroundColor: 'black',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundOrigin: 'content-box',
        padding: theme.spacing(1),
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
    },
    '& .imageGrid': {
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
    },
    '& .manager': {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    '& .rightPanel': {
        [theme.breakpoints.up('sm')]: {
            width: '300px',
            minWidth: '300px',
        },
        boxShadow: theme.shadows[6],
        borderLeft: '1px solid ' + theme.palette.divider,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
    },
    '& .imagePreview': {
        flexGrow: '1',
        overflow: 'auto',
    },
    '& .uploadContainer': {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    }
});

function ImageDialog(props) {
    const {
        title,
        onClose,
        onImage,
        imageLibraryPrimaryActionLabel = "Insert Image",
        uploadImagePrimaryActionLabel = "Upload and Insert",
        ...forwardProps
    } = props;

    const client = useMojipostClient();

    const imageRef = useRef();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const onSave = useCallback((image) => {
        onImage && onImage(image.id, image);
        onClose && onClose();
    }, [onImage, onClose]);

    const {
        state,
        loading,
        updateState,
        isDirty,
        resetState,
        save,
        saving,
        describe,
        fieldErrors,
        resetStatePath,
        cancel,
    } = usePersistentStateObject({
        onSave,
        onCreate: client.postImage,
        onDescribe: client.describeImage,
        postSaveBehavior: POST_SAVE_BAHAVIOR_CLEAR
    });

    const cancelDialog = () => {
        cancel();
        onClose && onClose();
    };

    const disableForm = loading || saving;

    const { url, image, loading: imageLoading, error } = useImageLoader(state.file);
    imageRef.current = image;

    const [tab, setTab] = useState(TAB_UPLOAD_IMAGE);
    const handleTabChange = (_, newTab) => setTab(newTab);
    const [selectedImageInfo, setSelectedImageInfo] = useState(undefined);
    const [selectedImage] = selectedImageInfo || [undefined];
    const setImageSelection = useCallback((id, record) => {
        setSelectedImageInfo([id, record]);
    }, [setSelectedImageInfo]);
    const insertSelectedImage = () => {
        onImage && onImage(...selectedImageInfo);
        onClose();
    };

    const [selectionIsDirty, setSelectionIsDirty] = useState(false);
    const canUseImage = Boolean(!selectionIsDirty && selectedImage);
    const canCancel = !selectionIsDirty;
    const [imageSearch, setImageSearch] = useState('');
    const hasSearch = imageSearch.length > 0;
    const handleSearchChange = event => setImageSearch(event.target.value);

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            fullScreen={mobile}
            disablePortal
            {...forwardProps}
            onClose={cancelDialog}
        >

            <DialogTitle sx={{ display: 'flex', alignItems: 'center', columnGap: 1, position: 'relative' }}>
                <Icon name="addImage" />
                {title}
                <div className="grow" />
                <Fade in={tab === TAB_IMAGE_LIBRARY}>
                    <Search
                        value={imageSearch}
                        onChange={handleSearchChange}
                    />
                </Fade>
            </DialogTitle>
            <div>
                <Collapse in={!hasSearch && !selectedImage || !mobile}>
                    <Tabs value={tab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tab label="Upload Image" value={TAB_UPLOAD_IMAGE} />
                        <Tab label="Image Library" value={TAB_IMAGE_LIBRARY} />
                    </Tabs>
                </Collapse>
            </div>
            <Box className="manager" display="flex" overflow="hidden" flexGrow={1}>
                {tab === TAB_IMAGE_LIBRARY && (
                    <>
                        <PersistentSetManager
                            search={imageSearch}
                            onSearchChange={setImageSearch}
                            disabled={selectionIsDirty}
                            className="imageGrid"
                            value={selectedImage}
                            onChange={setImageSelection}
                            title="Image Library"
                            onGet={client.getImages}
                            cardGridPropertyMapper={record => ({
                                title: record.name,
                                image: record.file,
                                imageRatio: 1
                            })}
                        />

                        <Box className="rightPanel">
                            {selectedImage && (
                                <AppPreviewPanel
                                    className="imagePreview"
                                    id={selectedImage}
                                    onGet={client.getImage}
                                    onUpdate={client.patchImage}
                                    onDescribe={client.describeImage}
                                    onDirtyChange={setSelectionIsDirty}
                                    layout={IMAGE_DETAIL_LAYOUT}
                                    FieldProps={{
                                        variant: "subtitle:typography"
                                    }}
                                    imageMap={image => mobile
                                        ? ([])
                                        : ([{
                                            key: 'preview',
                                            title: 'Image Thumbnail',
                                            src: image?.file,
                                            sx: {
                                                width: '100%',
                                                height: '250px',
                                            }
                                        }])}
                                />
                            )}
                            {!selectionIsDirty && (
                                <Toolbar>
                                    <Fade in={canCancel} unmountOnExit>
                                        <Button variant="outlined" onClick={onClose}>
                                            Cancel
                                        </Button>
                                    </Fade>
                                    <div className="grow" />
                                    <Fade in={canUseImage} unmountOnExit>
                                        <Button
                                            variant="contained"
                                            onClick={insertSelectedImage}
                                        >{imageLibraryPrimaryActionLabel}</Button>
                                    </Fade>
                                </Toolbar>
                            )}
                        </Box>
                    </>
                )}
                {tab === TAB_UPLOAD_IMAGE && (
                    <Grid container className="uploadContainer">
                        <Grid item md={7} sx={{
                            position: 'relative',
                            [theme.breakpoints.down('sm')]: {
                                flexGrow: 1
                            }
                        }}>
                            <Checkboard />
                            <Box className="uploadPreview" sx={{ backgroundImage: `url(${url})` }} />
                        </Grid>
                        <Grid item sm={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <DialogContent sx={{ overflow: 'visible' }}>
                                {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
                                <DetailLayout
                                    layout={IMAGE_LAYOUT}
                                    value={state}
                                    onChange={updateState}
                                    isDirty={isDirty}
                                    onUndo={resetStatePath}
                                    describe={describe}
                                    disabled={disableForm}
                                    error={fieldErrors}
                                    FieldProps={{
                                        mode: MODE_WRITE,
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    disabled={disableForm}
                                    color="primary"
                                    variant="outlined"
                                    onClick={cancelDialog}
                                >Cancel</Button>
                                <div style={{ flexGrow: 1 }} />
                                <Button
                                    disabled={disableForm}
                                    color="primary"
                                    variant="contained"
                                    onClick={save}
                                >{uploadImagePrimaryActionLabel}</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Dialog >
    );
}

export default styled(ImageDialog)(styles);

import { Fab, Tab, Tabs, styled } from "@mui/material";
import Icon from "components/Icon";
import { useGETState } from "hooks/useQueryParams";
import { forwardRef } from "react";
import BackgroundProperties from "./BackgroundProperties";
import BorderProperties from "./BorderProperties";
import FilterProperties from "./FilterProperties";
import FrontProperties from "./FontProperties";
import LayerProperties from "./LayerProperties";
import PositioningProperties from "./PositioningProperties";

const styles = ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    '& .tabs': {
        borderBottom: '1px solid ' + theme.palette.divider,
    },
    '& .filterContainer': {
        padding: theme.spacing(1)
    },
    '& .fabbyGrabby': {
        position: 'absolute',
        top: '0px',
        marginLeft: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

function MobileEditorProperties(props, ref) {
    const {
        className,
        state,
        layerSelection,
        viewport,
        currentPageIdentifier,
        layers,
        focusFront,
        focusBack,
        describeLayer,
        fieldErrors,
        pageErrors,
        ...forwardProps
    } = props;
    const {
        locked: disabled,
        layerErrors,
        updateSelection,
        properties: selectionProperties,
    } = layerSelection;
    const handleUpdate = (event, ...args) => {
        if (typeof event === 'object' && 'target' in event) {
            updateSelection(event.target.name, event.target.value);
        } else {
            updateSelection(event, ...args);
        }
    };
    const {
        state: {
            panel: openPanel = 'closed'
        },
        replaceState
    } = useGETState();
    const handlePanelSelection = (_, panel) => replaceState('panel', panel);
    const closePanel = () => replaceState('panel', 'closed');
    return (
        <div ref={ref} className={className} {...forwardProps}>
            <Tabs
                className="tabs"
                // visibleScrollbar
                variant="scrollable"
                scrollButtons="auto"
                value={openPanel}
                onChange={handlePanelSelection}
            >
                <Tab sx={{ width: '1px', padding: 0, minWidth: '1px' }} value="closed" />
                <Tab
                    value="layersProperties"
                    label="Layers"
                    icon={<Icon name="layerProperties" />}
                />
                <Tab
                    value="positionProperties"
                    label="Position"
                    icon={<Icon name="positionProperties" />}
                />
                <Tab
                    value="backgroundProperties"
                    label="Background"
                    icon={<Icon name="backgroundProperties" />}
                />
                <Tab
                    value="fontProperties"
                    label="Font Style"
                    icon={<Icon name="fontProperties" />}
                />
                <Tab
                    value="borderProperties"
                    label="Border Style"
                    icon={<Icon name="borderProperties" />}
                />
                <Tab
                    value="filterProperties"
                    label="Filters"
                    icon={<Icon name="filterProperties" />}
                />
            </Tabs>
            {openPanel !== 'closed' && (
                <Fab size="small" color="secondary" className="fabbyGrabby" onClick={closePanel}>
                    <Icon name="close" />
                </Fab>
            )}
            {openPanel === 'layersProperties' && (
                <LayerProperties
                    viewport={viewport}
                    layers={layers}
                    layerErrors={layerErrors}
                    layerSelection={layerSelection}
                    pageErrors={pageErrors}
                    onChange={handleUpdate}
                />
            )}
            {openPanel === 'positionProperties' && (
                <PositioningProperties
                    className="filterContainer"
                    layerErrors={layerErrors}
                    describe={describeLayer}
                    disabled={disabled}
                    properties={selectionProperties}
                    onChange={handleUpdate}
                />
            )}
            {openPanel === 'backgroundProperties' && (
                <BackgroundProperties
                    className="filterContainer"
                    describe={describeLayer}
                    disabled={disabled}
                    properties={selectionProperties}
                    onChange={handleUpdate}
                />
            )}
            {openPanel === 'fontProperties' && (
                <FrontProperties
                    className="filterContainer"
                    layerErrors={layerErrors}
                    describe={describeLayer}
                    disabled={disabled}
                    properties={selectionProperties}
                    onChange={handleUpdate}
                />
            )}
            {openPanel === 'borderProperties' && (
                <BorderProperties
                    className="filterContainer"
                    layerErrors={layerErrors}
                    describe={describeLayer}
                    disabled={disabled}
                    properties={selectionProperties}
                    onChange={handleUpdate}
                />
            )}
            {openPanel === 'filterProperties' && (
                <FilterProperties
                    layerErrors={layerErrors}
                    describe={describeLayer}
                    disabled={disabled}
                    properties={selectionProperties}
                    onChange={handleUpdate}
                    layerSelection={layerSelection}
                />
            )}
        </div>
    );
}

export default styled(forwardRef(MobileEditorProperties))(styles);

import { useCallback, useEffect, useRef } from "react";
import useAsyncCallback from "./useAsyncCallback";
import useThrottle from "./useThrottle";

function usePersistentSet(onGet, {
    pageParam = 'p',
    initialPage = 1,
    pageSizeParam = 'ps',
    initialPageSize = 30,
    search = '',
    searchParam = 'search',
    defaultParams = {},
    autoCall = false,
    onSearchChange,
    addListener,
    removeListener,
}) {
    const currentParamsRef = useRef({
        [pageParam]: initialPage,
        [pageSizeParam]: initialPageSize,
        [searchParam]: search,
        ...defaultParams,
    });

    const {
        callback: fetch,
        loading,
        error: loadError,
        result,
        reset: clearLoadError,
    } = useAsyncCallback(onGet, { autoCall, autoCallArgs: [currentParamsRef.current] });
    const {
        callback: fetchThrottled,
    } = useThrottle(fetch, { throttle: 200, delay: true })

    const { count: numberOfRecords } = result || {};
    const totalPages = Math.ceil(numberOfRecords / currentParamsRef.current[pageSizeParam]);
    const lastPageNumber = initialPage + totalPages - 1;
    const hasNextPage = currentParamsRef.current[pageParam] < lastPageNumber;
    const hasPreviousPage = currentParamsRef.current[pageParam] > initialPage;

    const refresh = useCallback(() => {
        return fetchThrottled(currentParamsRef.current);
    }, [currentParamsRef, fetchThrottled]);

    const nextPage = useCallback(() => {
        currentParamsRef.current[pageParam]++;
        // console.log(currentParamsRef.current);
        return fetchThrottled(currentParamsRef.current);
    }, [currentParamsRef, pageParam, fetchThrottled]);

    const previousPage = useCallback(() => {
        currentParamsRef.current[pageParam]--;
        if (currentParamsRef.current[pageParam] < initialPage) {
            currentParamsRef.current[pageParam] = initialPage;
        }
        return fetchThrottled(currentParamsRef.current);
    }, [currentParamsRef, pageParam, initialPage, fetchThrottled]);

    const firstPage = useCallback(() => {
        currentParamsRef.current[pageParam] = initialPage;
        return fetchThrottled(currentParamsRef.current);
    }, [currentParamsRef, initialPage, fetchThrottled, pageParam]);

    const lastPage = useCallback(() => {
        currentParamsRef.current[pageParam] = initialPage + totalPages - 1;
        return fetchThrottled(currentParamsRef.current);
    }, [currentParamsRef, pageParam, totalPages, fetchThrottled, initialPage]);

    const gotoPage = useCallback(page => {
        currentParamsRef.current[pageParam] = page;
        return fetchThrottled(currentParamsRef.current);
    }, [currentParamsRef, pageParam, fetchThrottled]);

    const setPageSize = useCallback(number => {
        currentParamsRef.current[pageSizeParam] = number;
        currentParamsRef.current[pageParam] = initialPage;
        return fetchThrottled(currentParamsRef.current);
    }, [currentParamsRef, pageSizeParam, pageParam, initialPage, fetchThrottled]);

    const setSearch = useCallback((search, updateController = true) => {
        currentParamsRef.current[searchParam] = search;
        currentParamsRef.current[pageParam] = initialPage;
        updateController && onSearchChange(search);
        return fetchThrottled(currentParamsRef.current);
    }, [currentParamsRef, searchParam, pageParam, initialPage, fetchThrottled, onSearchChange]);

    useEffect(() => {
        if (onSearchChange) {
            setSearch(search, false);
        }
    }, [onSearchChange, search, setSearch]);

    return {
        result,
        resultCount: numberOfRecords,
        currentPage: currentParamsRef.current[pageParam],
        currentPageSize: currentParamsRef.current[pageSizeParam],
        search: currentParamsRef.current[searchParam],
        fetch,
        refresh,
        loading,
        loadError,
        clearLoadError,
        firstPage,
        firstPageNumber: initialPage,
        totalPages,
        lastPageNumber,
        nextPage,
        hasNextPage,
        previousPage,
        hasPreviousPage,
        lastPage,
        gotoPage,
        setPageSize,
        setSearch,
    };
}

export default usePersistentSet;

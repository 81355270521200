import React from 'react';
import {
    BrowserRouter
} from "react-router-dom";

import { blue } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const colorMode = 'light';
const theme = createTheme({
    palette: {
        mode: colorMode,
        primary: blue,
        secondary: {
            main: '#ff5722',
        },
        background: {
            default: colorMode === 'light' ? '#f2f2f2' : '#000',
            paper: colorMode === 'light' ? '#fff' : '#1d1d1d',
        }
    }
});

function BrowserEnvironment(props) {
    const { children } = props;

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default BrowserEnvironment;

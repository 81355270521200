
import AccountIcon from '@mui/icons-material/AccountCircleRounded';
import EmailIcon from '@mui/icons-material/EmailRounded';
import GoogleIcon from '@mui/icons-material/Google';
import PasswordIcon from '@mui/icons-material/Password';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useCookies } from 'react-cookie';
import useStateObject from '../hooks/useStateObject';


function SignInWithGoogleButtonInner(props) {
    const [cookies,] = useCookies(['csrftoken']);
    let url = '/accounts/google/login/';
    if (window.location.hostname === 'localhost') {
        url = '//localhost:8000' + url;
    }
    return (
        <form className={props.className} method="post" action={url}>
            <input type="hidden" name="csrfmiddlewaretoken" value={cookies.csrftoken} />
            <Button
                className="google-login-button"
                fullWidth
                margin="normal"
                variant="contained"
                type="submit"
                startIcon={<GoogleIcon />}
            >Sign in with Google</Button>
        </form>
    );
}
const SignInWithGoogleButton = styled(SignInWithGoogleButtonInner)(({ theme }) => ({
    '.google-login-button': {
        backgroundColor: '#dd4b39',
        '&:hover': {
            backgroundColor: '#e47365'
        }
    },
    marginTop: theme.spacing(2),
}));

function Login() {

    const {
        state,
        updateState,
    } = useStateObject({
        email: '',
        password: '',
    });
    return (
        <Card>
            <CardHeader
                avatar={<Avatar><AccountIcon /></Avatar>}
                title="Sign in to MojiPost." />
            <CardContent>
                <TextField
                    fullWidth
                    // margin="normal"
                    name="email"
                    label="Email address"
                    type="email"
                    // margin="dense"
                    value={state.email}
                    //error={message !== false}
                    onChange={updateState}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined" />
                <TextField
                    fullWidth
                    // className={classes.input}
                    name="password"
                    label="Password"
                    type="password"
                    margin="normal"
                    value={state.password}
                    // error={message !== false}
                    // helperText={message}
                    onChange={updateState}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <PasswordIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined" />
            </CardContent>
            <CardActions>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="contained" type="submit">Submit</Button>
            </CardActions>
            <Divider>OR</Divider>
            <CardContent>
                <SignInWithGoogleButton />
            </CardContent>
        </Card>
    );
}

export default Login;

import React, { useEffect } from 'react';
import useMap from '../hooks/useMap';

export default function Marker(props) {
    const { lng, lat, address, center } = props;
    const map = useMap();

    useEffect(() => {
        const hasMap = Boolean(map);
        const hasPosition = Boolean(lng && lat);
        const hasAddress = Boolean(address);
        const marker = {};
        if (hasMap) {
            if (hasPosition) {
                marker.instance = new window.google.maps.Marker({
                    map,
                    position: { lng, lat },
                    icon: {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: '#ffffff',
                        fillOpacity: 1,
                        strokeColor: '#8ec3b9',
                        strokeWeight: 8,
                        scale: 16
                    },
                });
            } else if (hasAddress) {
                const geocoder = new window.google.maps.Geocoder();
                const addressString = `${address.addressLine1}, ${address.city}, ${address.state} ${address.zip}`
                geocoder.geocode({ address: addressString }, function (results, status) {
                    if (status === 'OK') {
                        const position = results[0].geometry.location;
                        if (position) {
                            marker.instance = new window.google.maps.Marker({
                                map,
                                position
                            });
                            if (center) {
                                map.setCenter(position);
                            }
                        }
                    } else {
                        console.log('ADDRESS NOT OK');
                    }
                });
            }
        }

        // this will remove the marker from the map when the component is removed
        return () => {
            if (marker.instance) {
                marker.instance.setMap(null);
            }
        };
    }, [map, lng, lat, address, center])

    return <div />;
}

import { useMojipostClient } from 'connectors/MojipostClient';
import { useEffect, useRef, useState } from "react";

export const MANAGED_STATE_LOADING = 'managed-state:loading';
export const MANAGED_STATE_ERROR = 'managed-state:error';
export const MANAGED_STATE_EMPTY = 'managed-state:empty';
const IMAGE_LOAD_MODE = 'decode';
export const imageManager = {
    aliases: {},
    addAlias(newAlias, url) {
        // alias = 2
        // url = http://..
        const urlKey = Symbol.for(url);
        const {
            [urlKey]: aliases = new Set()
        } = this.aliases;
        // add alias to
        aliases.add(newAlias);
        this.aliases[urlKey] = aliases;
    },
    removeAlias(url, removeAlias) {
        const urlKey = Symbol.for(url)
        const { [urlKey]: aliases = new Set() } = this.aliases;
        aliases.delete(removeAlias);
        this.aliases[urlKey] = aliases;
    },
    isAlias(potentialAlias) {
        const potentialUrlKey = Symbol.for(potentialAlias);
        if (potentialUrlKey in this.aliases) {
            delete this.aliases[potentialUrlKey];
        }
    },

    listeners: {},
    addListener(url, listener) {
        const resourceKey = Symbol.for(url);
        const { [resourceKey]: imageLoaderListeners = [] } = this.listeners;
        this.listeners[resourceKey] = [...imageLoaderListeners, listener];
    },
    hasActiveListeners(url) {
        const resourceKey = Symbol.for(url);
        if (this.listeners[resourceKey].length > 0) {
            return true;
        }
        return false;
        // TODO figure out how to handle this with aliases
        // const { [resourceKey]: aliases = [] } = this.aliases;
        // return Object.keys(aliases).some(aliasResourceKey => {
        //     if (this.aliases[aliasResourceKey].includes(url)) {
        //         return this.listeners[aliasResourceKey].length > 0;
        //     }
        //     return false;
        // });
    },
    removeListener(url, listener) {
        const resourceKey = Symbol.for(url);
        // remove listeners
        const { [resourceKey]: imageLoaderListeners = [] } = this.listeners;
        this.listeners[resourceKey] = imageLoaderListeners.filter(imageListener => imageListener !== listener);
        // clear our cache for anything that has no active listeners
        if (!this.hasActiveListeners(url)) {
            // we remove from our "cache" when no more listeners exist.
            // we do this because if the browser does have to fetch it from the cache there
            // can be a non zero load time.
            delete this.listeners[resourceKey];
            delete this.imageStates[resourceKey];
            this.listeners = { ...this.listeners };
        }
    },
    imageStates: {},
    setImageState(url, state) {
        const resourceKey = Symbol.for(url);
        const actualState = state;
        this.imageStates[resourceKey] = actualState;
        const { [resourceKey]: imageLoaderListeners = [] } = this.listeners;
        // console.log('UPDATING', imageLoaderListeners.length, 'Liseners', state)
        imageLoaderListeners.forEach(listener => listener(actualState));

        const { [resourceKey]: aliases = [] } = this.aliases;
        aliases.forEach(alias => this.setImageState(alias, { ...actualState, alias: url }));
    },
    getImageLoaderState(url) {
        const resourceKey = Symbol.for(url);
        const {
            [resourceKey]: imageLoaderState = {
                alias: null,
                resource: url,
                url: null,
                image: null,
                loading: url === MANAGED_STATE_LOADING ? true : false,
                error: url === MANAGED_STATE_ERROR ? {} : null,
            }
        } = this.imageStates;
        this.imageStates[resourceKey] = imageLoaderState;
        return imageLoaderState;
    },
    loadImage(url, initializeLoadState = true) {
        let imageLoaderState = this.getImageLoaderState(url);
        if ([MANAGED_STATE_ERROR, MANAGED_STATE_LOADING].includes(url)) {
            // console.log('SETTING MANAGED STATE');
            this.setImageState(url, imageLoaderState);
        } else if (url) {
            if (!imageLoaderState.url) {
                if (url instanceof File || url instanceof Blob) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const { target: { result: src } } = event;
                        const img = new Image();
                        img.onload = () => {
                            this.setImageState(url, {
                                url: src,
                                image: img,
                                loading: false,
                                error: null
                            })
                        }
                        img.src = src;
                    };
                    reader.readAsDataURL(url);
                } else {
                    const image = document.createElement('img');
                    if (IMAGE_LOAD_MODE === 'events') {
                        image.onload = () => {
                            // console.log('EVENTS MODE Loaded');
                            this.setImageState(url, {
                                url,
                                image,
                                loading: false,
                                error: null,
                            });
                        };
                        image.onerror = error => this.setImageState(url, {
                            url: null,
                            image: null,
                            loading: false,
                            error,
                        });
                        image.src = url;
                    } else if (IMAGE_LOAD_MODE === 'decode') {
                        // image.onload = error => {
                        //     console.log('DECODE MODE ERRORED');
                        //     // this.setImageState(url, {
                        //     //     url: null,
                        //     //     image: null,
                        //     //     loading: false,
                        //     //     error,
                        //     // });
                        // };
                        // image.addEventListener('error', (error) => {
                        //     console.log('ERROR', error);
                        // });
                        image.src = url;
                        image.decode().then((args) => {
                            this.setImageState(url, {
                                url,
                                image,
                                loading: false,
                                error: null,
                            })
                        }).catch((error) => {
                            this.setImageState(url, {
                                url: null,
                                image: null,
                                loading: false,
                                error,
                            })
                        });
                    }
                    if (initializeLoadState) {
                        this.setImageState(url, {
                            url: null,
                            image,
                            loading: true,
                            error: null,
                        });
                    }
                }
            }
        }
    }
};
Window.imageManager = imageManager;

export default function useImageLoader(url, { loadTransitionVariant = 'loadBetween' } = {}) {
    // code handles loading image data
    // console.log('useImageLoader', url);
    const client = useMojipostClient();

    // const defaultStateShouldBeLoading = Boolean(url);
    const [state, setState] = useState(imageManager.getImageLoaderState(MANAGED_STATE_EMPTY));

    const latestUrl = useRef();
    const urlHasChanged = url !== latestUrl.current;
    if (urlHasChanged) {
        latestUrl.current = url;
    }
    const ignoreState = state?.resource !== url;
    useEffect(() => {
        // any time the url changes
        const weAreTheRequestedUrl = () => url === latestUrl.current;
        const safeSetState = (state) => weAreTheRequestedUrl() && setState(state);
        const urlIsImageId = Boolean(`${parseInt(url, 10)}` === `${url}`);
        const imageId = urlIsImageId ? `${parseInt(url, 10)}` : undefined;
        const urlIsFile = Boolean(url instanceof File || url instanceof Blob);
        const imageFile = urlIsFile ? url : undefined;
        const urlIsImageUrl = Boolean(!urlIsFile && !urlIsImageId && url);
        const imageUrl = urlIsImageId ? undefined : url;


        // then we figure out what to load!
        if (urlIsImageUrl) {
            imageManager.addListener(imageUrl, safeSetState);
            imageManager.loadImage(imageUrl);
            return () => {
                imageManager.removeListener(imageUrl, safeSetState);
            };
        } else if (urlIsFile) {
            imageManager.addListener(imageFile, safeSetState);
            setState(imageManager.loadImage(imageFile));
            return () => {
                imageManager.removeListener(imageFile, safeSetState);
            };
        } else if (urlIsImageId) {
            // TODO: refactor imageManager to accept image providers
            imageManager.addListener(imageId, safeSetState);
            const imageIdState = imageManager.getImageLoaderState(imageId);
            if (imageIdState.url) {
                safeSetState(imageIdState);
            } else {
                const image = client.getImage(imageId);
                if (image instanceof Promise) {
                    // we need to update the state to indicate we are loading something
                    imageManager.setImageState(imageId, imageManager.getImageLoaderState(MANAGED_STATE_LOADING));
                    // wait on the image record
                    image
                        .then(({ file }) => {
                            const fileState = imageManager.getImageLoaderState(file);
                            if (fileState.url) {
                                safeSetState(fileState);
                            } else {
                                imageManager.addAlias(imageId, file);
                                imageManager.loadImage(file);
                            }
                        })
                        .catch(error => {
                            imageManager.setImageState(imageId, {
                                ...imageManager.getImageLoaderState(MANAGED_STATE_ERROR),
                                error,
                            });
                        });
                } else {
                    // TODO: test when supacache is ready
                    // we have the image record data
                    const { file } = image;
                    const fileState = imageManager.getImageLoaderState(file);
                    imageManager.addAlias(imageId, file);
                    if (fileState.url) {
                        imageManager.setImageState(file, fileState);
                    } else {
                        imageManager.loadImage(file);
                    }
                }
            }
            return () => {
                imageManager.removeListener(imageId, safeSetState);
            };
        } else {
            safeSetState(imageManager.getImageLoaderState(MANAGED_STATE_EMPTY));
        }
    }, [url, setState, client]);

    let result = { ...state, urlHasChanged };
    if (Boolean(url) === false) {
        Object.assign(result, imageManager.getImageLoaderState(MANAGED_STATE_EMPTY));
    } else if (ignoreState) {
        // optimization
        // console.log(state, imageManager.getImageLoaderState(url))
        Object.assign(result, imageManager.getImageLoaderState(url));
    }

    return result;
}

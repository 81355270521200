import { useCallback, useRef, useState } from "react";
import { setStateObject, setXStateObject, updateStateObject } from "./useXState";

const DEFAULT_INITIAL_VALUE = {};

function mergeSetCallback(...update) {
    return initialValue => setStateObject(initialValue, ...update);
}
function mergeUpdateCallback(...update) {
    return initialValue => updateStateObject(initialValue, ...update);
}
function deepCompare(value1, value2) {
    if (typeof value1 === typeof value2) {
        if (Array.isArray(value1)) {
            return value1.every((value, index) => deepCompare(value, value2[index]));
        } else if (typeof value1 === 'object') {
            return Object.keys({ ...value1, ...value2 }).every(key => deepCompare(value1[key], value2[key]));
        }
        return value1 === value2;
    }
    return false;
}
function useStateObject(initialValue = DEFAULT_INITIAL_VALUE, config = {}) {
    const { diffListeners } = config;
    // code to manage initial Value ref
    const initialValueRef = useRef(initialValue);
    const setInitialValue = useCallback(
        (...update) => initialValueRef.current = setXStateObject({}, initialValueRef.current, ...update),
        [initialValueRef]
    );
    const updateInitialValue = useCallback(
        (...update) => initialValueRef.current = setXStateObject({ update: true }, initialValueRef.current, ...update),
        [initialValueRef]
    );
    const clearInitialValue = useCallback(
        () => initialValueRef.current = {},
        [initialValueRef]
    );

    // code to manage current state
    const [state, baseSetState] = useState(initialValue);
    const stateRef = useRef(state);
    const setState = useCallback(
        (...update) => baseSetState(previousState => {
            const newState = setXStateObject({ diffListeners }, previousState, ...update);
            stateRef.current = newState;
            return newState;
        }),
        [baseSetState, diffListeners]
    );
    // useEffect(() => {
    //     console.log('diffListeners changed', diffListeners)
    // }, [diffListeners])
    // useEffect(() => {
    //     console.log('UPDATED', diffListeners)
    // }, [diffListeners]);
    const updateState = useCallback(
        (...update) => baseSetState(previousState => {
            const newState = setXStateObject({ diffListeners, update: true }, previousState, ...update);
            stateRef.current = newState;
            return newState;
        }),
        [baseSetState, diffListeners]
    );
    const resetState = useCallback(
        () => {
            baseSetState(initialValueRef.current);
            stateRef.current = initialValueRef.current;
        },
        [baseSetState, initialValueRef, stateRef]
    );
    // experimental resetStatePath
    const resetStatePath = useCallback(
        (...chain) => {
            chain = chain.map(item => `${item}`.split('.')).flat();
            let initialValue = initialValueRef.current;
            chain.forEach(chainStep => {
                initialValue = (initialValue && initialValue[chainStep]);
            });
            setState(...chain, initialValue);
        },
        [initialValueRef, setState]
    );
    const clearState = useCallback(() => setState({}), [setState]);


    // insightful helpers, TODO: add option for deep compare
    const isDirty = useCallback(
        (...chain) => {
            chain = chain.map(item => `${item}`.split('.')).flat();
            let initialValue = initialValueRef.current;
            let stateValue = stateRef.current;
            while (chain.length) {
                let chainStep = chain.shift();
                initialValue = (initialValue && initialValue[chainStep]) || undefined;
                stateValue = (stateValue && stateValue[chainStep]) || undefined;
            }
            return !deepCompare(initialValue, stateValue);
        },
        [initialValueRef, stateRef]
    );

    return {
        // initial value interface
        initialValueRef,
        initialValue: initialValueRef.current,
        setInitialValue,
        updateInitialValue,
        clearInitialValue,

        // state interface
        stateRef,
        state,
        setState,
        updateState,
        resetState,
        clearState,

        // experimental
        resetStatePath,

        // helpers
        isDirty,
    };
}

export default useStateObject;
export {
    mergeSetCallback,
    mergeUpdateCallback
};

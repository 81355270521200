import Avatar from "components/Avatar";

export const USER_ACCOUNT_LAYOUT = [
    (props) => {
        const {
            value: {
                image
            }
        } = props;
        return (
            <Avatar
                src={image}
                sx={{
                    width: 150,
                    height: 150,
                    margin: 'auto'
                }}
            />
        );
    },
    { name: 'image', cellProps: { textAlign: 'center' } },
    'user.username',
    'user.email',
    ['user.firstName', 'user.lastName'],
    {
        label: 'Address Lookup',
        name: 'address.addSearch',
        placeholder: 'Enter Location',
    },
    "address.addressLine1",
    "address.addressLine2",
    "address.city",
    ["address.state", "address.zip"]
]

import { useCallback } from "react";

function shallowCopy(data) {
    return Array.isArray(data)
        ? [...data]
        : typeof data === 'object' && data !== null
            ? { ...data }
            : data;
}

/**
 * @typedef {string|number|PathSection[]|any} PathSection
*/

/**
 * @typedef {PathSection[]} Path
 */

/**
 * @typedef {object} DependentField
 * @property {Path} path
 * @property {object} updates
 */

/**
 * @typedef {object} StateSetterConfig
 * @property {Array<DependentField>} dependentFields
 * @property {Array<DiffListenerConfig>} diffListeners
 */

/**
 * @typedef {object} DiffEvent
 * @property {any} previousValue
 * @property {any} newValue
 * @property {Path} path
 */

/**
 * @callback diffCallback
 * @param {DiffEvent} diffEvent
 */

/**
 * @typedef {object} DiffListenerConfig
 * @property {Path} path
 * @property {diffCallback} callback
 */

/**
 *
 * @param {Array<DiffListenerConfig>} diffListenerConfig
 * @param {any} previousValue
 * @param {*} newValue
 * @param {*} newValueParent
 * @param {*} trail
 */
export function triggerDiffListeners(diffListenerConfig, eventProps, previousValue, newValue) {
    const diffListeners = diffListenerConfig.map(
        diffListener => ({
            previousValue,
            newValue,
            callback: diffListener.callback,
            path: [...diffListener.path],
            location: [],
        })
    );

    // loop through change listeners to detect changes
    searchingDiffListeners: while (diffListeners.length) {
        let {
            previousValue: scopedPreviousValue,
            newValue: scopedNewValue,
            callback: scopedCallback,
            path: scopedPath,
            location: scopedLocation,
        } = diffListeners.shift();

        if (scopedPath.length > 0) {
            let pathSection = scopedPath.shift();

            // handling branching paths
            if (Array.isArray(pathSection)) {
                pathSection.forEach(
                    branch => diffListeners.push({
                        previousValue: scopedPreviousValue,
                        newValue: scopedNewValue,
                        callback: scopedCallback,
                        path: [branch, ...scopedPath],
                        location: [...scopedLocation],
                    })
                );
                continue searchingDiffListeners;
            }

            // walk the pathSection
            let pathSteps = `${pathSection}`.split('.');

            // checking listeners past the known trail (off roading ...)
            while (pathSteps.length) {
                const pathStep = pathSteps.shift();
                // handle wildcard pathStep
                if (pathStep === '*') {
                    const keySpace = [...new Set([
                        ...Object.keys(scopedPreviousValue || {}),
                        ...Object.keys(scopedNewValue || {})
                    ])];
                    diffListeners.push({
                        previousValue: scopedPreviousValue,
                        newValue: scopedNewValue,
                        callback: scopedCallback,
                        path: [keySpace, ...pathSteps, ...scopedPath],
                        location: scopedLocation,
                    });
                    continue searchingDiffListeners;
                }

                if (
                    (
                        scopedPreviousValue &&
                        typeof scopedPreviousValue === 'object' &&
                        pathStep in scopedPreviousValue
                    ) ||
                    (
                        scopedNewValue &&
                        typeof scopedNewValue === 'object' &&
                        pathStep in scopedNewValue
                    )
                ) {
                    // step exists keep searching
                    scopedLocation.push(pathStep);
                    scopedPreviousValue = scopedPreviousValue?.[pathStep];
                    scopedNewValue = scopedNewValue?.[pathStep];

                    if (scopedPreviousValue === scopedNewValue) {
                        // no changes on this step
                        continue searchingDiffListeners;

                    }

                    // reorganizing array shouldn't call change listeners on children
                    if (Array.isArray(scopedPreviousValue) && Array.isArray(scopedNewValue)) {
                        // TODO: still some thinking through to do on this one.
                        // currently supports events on changes on children
                        // no events for deleted children now
                        // might cause issues if order changes AND new children are added...
                        const currentSet = new Set(scopedPreviousValue);
                        if (scopedNewValue.every(item => currentSet.has(item))) {
                            // if every item in the newValue exists within the old value
                            // then no dependentField changes are needed
                            continue searchingDiffListeners;
                        }
                    }

                    // need to check if pathSteps is empty but more pathSections can be traversed
                    if (pathSteps.length === 0 && scopedPath.length > 0) {
                        pathSection = scopedPath.shift();
                        // if list then push back so that the pathSection can be forked
                        if (Array.isArray(pathSection)) {
                            diffListeners.push({
                                previousValue: scopedPreviousValue,
                                newValue: scopedNewValue,
                                callback: scopedCallback,
                                path: [pathSection, ...scopedPath],
                                location: scopedLocation,
                            });
                            continue searchingDiffListeners;
                        }
                        // if anything else treat as a string and split by dots
                        pathSteps = `${pathSection}`.split('.');
                    }

                    // if (pathSteps.length && )

                    continue;
                }

                continue searchingDiffListeners;
            }
        }

        // check if value has changed
        if (scopedPreviousValue !== scopedNewValue) {
            scopedCallback({
                ...eventProps,
                previousRoot: previousValue,
                newRoot: newValue,
                previousValue: scopedPreviousValue,
                newValue: scopedNewValue,
                path: scopedLocation,
            });
        }
    }
};

/**
 *
 * @param {StateSetterConfig} config
 * @param {*} initialValue
 * @param  {...PathSection} pathAndNewValue
 * @returns {*} a new object representing the initialValue with update applied
 */
export function setXStateObject(config, initialValue, ...pathAndNewValue) {
    // value is the last item in pathAndNewValue
    const newValue = pathAndNewValue.pop();
    const result = { root: shallowCopy(initialValue) };

    const updates = [{
        path: pathAndNewValue,
        trail: [],
        currentValue: initialValue,
        localParentResult: result,
        localResultKey: 'root',
        localResult: result.root,
    }];
    while (updates.length > 0) {
        const update = updates.shift();
        let {
            path: [pathSection, ...nextPathSections],
            trail,
            currentValue,
            localParentResult,
            localResultKey,
            localResult,
        } = update;

        // if a pathSection is an array then its a list of paths
        if (Array.isArray(pathSection)) {
            pathSection.forEach(
                subPath => updates.push({
                    path: [subPath, ...nextPathSections],
                    trail: [...trail],
                    currentValue,
                    localParentResult,
                    localResultKey,
                    localResult,
                })
            );
            continue;
        }

        // walking the section of the path
        const walk = step => {
            trail.push(step);
            currentValue = currentValue?.[step];
            if (typeof localResult !== 'object' || localResult === null) {
                const newContainer = {};
                localParentResult[localResultKey] = newContainer;
                localResult = newContainer;
            }
            localResult[step] = shallowCopy(localResult?.[step]);
            localParentResult = localResult;
            localResult = localResult[step];
            localResultKey = step;
        };
        if (update.path.length > 0) {
            const steps = `${pathSection}`.split('.');
            steps.forEach(walk);
        }

        // if there are more pathSections then push those updates
        if (nextPathSections.length) {
            updates.push({
                path: nextPathSections,
                trail,
                currentValue,
                localParentResult,
                localResultKey,
                localResult,
            });
            continue;
        }

        // handling native input element changes
        // file, checkbox, text
        let localNewValue = newValue;
        if (localNewValue?._reactName === 'onChange') {
            const { target: { name, value, type, checked, files } } = localNewValue;

            // name supports . paths so lets walk those
            const nameSteps = `${name}`.split('.');
            nameSteps.forEach(walk);

            let file = undefined;
            if (type === 'file') [file] = files || [];
            localNewValue = type === 'checkbox'
                ? checked
                : type === 'file'
                    ? file
                    : value;
        }

        // finalize newValue
        localNewValue = typeof localNewValue === 'function'
            ? localNewValue(currentValue)
            : localNewValue;

        if (config.update &&
            // the its an object updating another object
            typeof localNewValue === 'object' &&
            typeof localParentResult[localResultKey] === 'object' &&
            // new value is not an array
            !Array.isArray(localNewValue) &&
            // new value is not a File
            !(localNewValue instanceof File)
        ) {
            localParentResult[localResultKey] = {
                ...(localParentResult[localResultKey] || {}),
                ...(localNewValue || {}),
            };
        } else {
            localParentResult[localResultKey] = localNewValue;
        }

        if (Array.isArray(config?.diffListeners)) {
            triggerDiffListeners(
                config.diffListeners,
                {
                    rejectState() {
                        result.root = initialValue;
                    },
                    updateState(...args) {
                        result.root = setXStateObject(
                            { update: true, ...config },
                            result.root,
                            ...args
                        );
                    },
                    setState(...args) {
                        result.root = setXStateObject(
                            { update: false, ...config },
                            result.root,
                            ...args
                        );
                    },
                    replaceState(...args) {
                        result.root = setXStateObject(
                            { update: false },
                            result.root,
                            ...args
                        );
                    },
                    uplaceState(...args) {
                        result.root = setXStateObject(
                            { update: true },
                            result.root,
                            ...args
                        )
                    },
                    context: typeof config.diffListenerContext === 'object'
                        ? config.diffListenerContext
                        : {},
                },
                initialValue,
                result.root
            );
        }
    }

    return result.root;
}

// if (Array.isArray(config?.dependentFields)) {
//     const dependentUpdates = config.dependentFields.map(
//         dependentField => ({
//             currentValue,
//             newValue: localNewValue,
//             newValueContainer: localParentResult,
//             updates: dependentField.updates,
//             path: [...dependentField.path],
//             trail: [...trail],
//         })
//     );
//     dependentField: while (dependentUpdates.length) {
//         const dependentUpdate = dependentUpdates.shift();
//         let scopedCurrentValue = dependentUpdate.currentValue;
//         let scopedNewValue = dependentUpdate.newValue;
//         let scopedNewValueContainer = dependentUpdate.newValueContainer;
//         let expectStep = dependentUpdate.trail.shift();
//         let pathSection = dependentUpdate.path.shift();

//         // handle pathSection array
//         if (Array.isArray(pathSection)) {
//             pathSection.forEach(subPath => {
//                 dependentUpdates.push({
//                     currentValue: scopedCurrentValue,
//                     newValue: scopedNewValue,
//                     newValueContainer: scopedNewValueContainer,
//                     updates: dependentUpdate.updates,
//                     path: [subPath, ...dependentUpdate.path],
//                     trail: [expectStep, ...dependentUpdate.trail],
//                 });
//             });
//             continue dependentField;
//         }

//         // handle when pathSection is not an array
//         let pathSteps = `${pathSection}`.split('.');
//         if (dependentUpdate.trail.length > 0) {
//             while (pathSteps.length) {
//                 const pathStep = pathSteps.shift();
//                 if (pathStep === expectStep || pathStep === '*') {
//                     if (dependentUpdate.trail.length > 0) {
//                         // more steps in the known trail
//                         expectStep = dependentUpdate.trail.shift();
//                         continue;
//                     }
//                     // end of known trail, all matched
//                     break;
//                 }

//                 // we have a non-matching trail so skip
//                 continue dependentField;
//             }
//         }
//         while (pathSteps.length) {
//             const pathStep = pathSteps.shift();
//             if (pathStep === '*') {
//                 const keySpace = [...new Set([
//                     ...Object.keys(scopedCurrentValue || {}),
//                     ...Object.keys(scopedNewValue || {})
//                 ])];
//                 dependentUpdates.push({
//                     currentValue: scopedCurrentValue,
//                     newValue: scopedNewValue,
//                     newValueContainer: scopedNewValueContainer,
//                     updates: dependentUpdate.updates,
//                     path: [keySpace, ...pathSteps, ...dependentUpdate.path],
//                     trail: dependentUpdate.trail,
//                 });
//                 continue dependentField;
//             }
//             if ((scopedCurrentValue && pathStep in scopedCurrentValue) ||
//                 (scopedNewValue && pathStep in scopedNewValue)
//             ) {
//                 // step exists keep searching
//                 scopedCurrentValue = scopedCurrentValue?.[pathStep];
//                 scopedNewValueContainer = scopedNewValue;
//                 scopedNewValue = scopedNewValue?.[pathStep];
//                 if (scopedCurrentValue === scopedNewValue) {
//                     // if they are equal at this point then no point in serching
//                     continue dependentField;
//                 } else if (Array.isArray(scopedCurrentValue) && Array.isArray(scopedNewValue)) {
//                     const currentSet = new Set(scopedCurrentValue);
//                     if (scopedNewValue.every(item => currentSet.has(item))) {
//                         // if every item in the newValue exists within the old value
//                         // then no dependentField changes are needed
//                         continue dependentField;
//                     }
//                 }

//                 // need to check if pathSteps is empty but more pathSections can be traversed
//                 if (pathSteps.length === 0 && dependentUpdate.path.length > 0) {
//                     pathSection = dependentUpdate.path.shift();
//                     // if list then push back so that the pathSection can be forked
//                     if (Array.isArray(pathSection)) {
//                         dependentUpdates.push({
//                             currentValue: scopedCurrentValue,
//                             newValue: scopedNewValue,
//                             newValueContainer: scopedNewValueContainer,
//                             updates: dependentUpdate.updates,
//                             path: [pathSection, ...dependentUpdate.path],
//                             trail: dependentUpdate.trail,
//                         });
//                         continue dependentField;
//                     }
//                     // if anything else treat as a string and split by dots
//                     pathSteps = `${pathSection}`.split('.');
//                 }

//                 continue;
//             }

//             // step is missing in both so no changes happened
//             continue dependentField;
//         }

//         // check if value has changed
//         if (scopedCurrentValue !== scopedNewValue) {
//             // perform update in our current location
//             if (scopedNewValue in dependentUpdate.updates) {
//                 Object.assign(scopedNewValueContainer, dependentUpdate.updates[scopedNewValue]);
//             }
//         }
//     }
// }
const DEFAULT_INITIAL_VALUE = null;

export default function useXState({
    stateHookInterface,
    clearValue = DEFAULT_INITIAL_VALUE,
    dependentFields,
}) {
    let state, baseSetState;
    if (Array.isArray(stateHookInterface)) {
        state = stateHookInterface[0];
        baseSetState = stateHookInterface[0];
    } else {
        state = stateHookInterface.state;
        baseSetState = stateHookInterface.setState;
    }

    const setState = useCallback(
        (...pathAndUpdate) => baseSetState(
            initialValue => setXStateObject({ dependentFields }, initialValue, ...pathAndUpdate)
        ),
        [baseSetState, dependentFields]
    );
    const updateState = useCallback(
        (...pathAndUpdate) => baseSetState(
            initialValue => setXStateObject({ dependentFields, update: true }, initialValue, ...pathAndUpdate)
        ),
        [baseSetState, dependentFields]
    );
    const clearState = useCallback(
        () => baseSetState(clearValue), [baseSetState, clearValue]
    );

    return {
        state,
        setState,
        updateState,
        clearState,
    };
}


export function setStateObject(initialValue, ...pathAndNewValue) {
    // remove the new value at the end
    const newValue = pathAndNewValue.pop();
    pathAndNewValue = pathAndNewValue.map(item => `${item}`.split('.')).flat();

    // our recursive functions end state
    if (pathAndNewValue.length === 0) {
        if (newValue?._reactName === 'onChange') {
            const { target: { name, value, type, checked, files } } = newValue;
            let file = undefined;
            if (type === 'file') [file] = files || [];
            return setStateObject(
                initialValue,
                name,
                type === 'checkbox'
                    ? checked
                    : type === 'file'
                        ? file
                        : value
            );
        }
        // we have walked the entire path, return our new value!
        return typeof newValue === 'function' ? newValue(initialValue) : newValue;
    }

    // break down whats left of our path(-AndNewValue)
    // TODO support function firstStep.. i can't think of a reason why this would make sense...
    const [firstStep, ...restOfPath] = pathAndNewValue;
    const firstSteps = Array.isArray(firstStep) ? firstStep : [firstStep];
    const result = Array.isArray(initialValue)
        ? [...initialValue]
        : { ...(typeof initialValue === 'object' && initialValue) };
    firstSteps.forEach(step => {
        let { [step]: stepInitialValue } = initialValue || {};
        result[step] = setStateObject(stepInitialValue, ...restOfPath, newValue);
    });
    return result;
}

export function updateStateObject(initialValue, ...pathAndNewValue) {
    const newValue = pathAndNewValue.pop();
    return setStateObject(initialValue, ...pathAndNewValue, targetObject => {
        const resolvedValue = setStateObject(targetObject, newValue);
        if (typeof targetObject === 'object' && !Array.isArray(targetObject)) {
            return {
                ...targetObject,
                ...resolvedValue,
            };
        }
        return resolvedValue;
    });
}

import { useCallback, useState } from 'react';

export default function useToggle(initialState = false, callback) {
    const [state, setState] = useState(initialState);
    const toggle = useCallback(() => {
        setState(value => {
            callback && callback(!value);
            return !value;
        });
    }, [setState, callback]);
    const toggleOn = useCallback(() => {
        setState(() => {
            callback && callback(true);
            return true;
        });
    }, [setState, callback]);
    const toggleOff = useCallback(() => {
        setState(() => {
            callback && callback(false);
            return false;
        });
    }, [setState, callback]);
    return {
        state,
        toggle,
        toggleOn,
        toggleOff,
        setState,
    };
}

import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Checkboard } from 'react-color/lib/components/common';
import Vector from '../../library/Vector';
import Layer from './Layer';
import { PIXELS_PER_INCH } from './hooks/useMouse';

const styles = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // maxWidth: 'calc(100% + ' + theme.spacing(2) + ' - 2px)',
    height: 54,
    // maxWidth:
    // borderRadius: '4px',
    // borderStyle: 'inset',
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    borderWidth: '1px',
    '& [data-type=layer]': {
        position: 'initial !important',
    }
});

function getLayerBounds(layer) {
    const {
        x, y, width, height, rotation,
    } = layer;
    const bounds = {
        maxX: null,
        minX: null,
        maxY: null,
        minY: null,
    };
    const center = { x: x + width / 2, y: y + height / 2 };
    [
        { x, y },
        { x: x + width, y },
        { x, y: y + height },
        { x: x + width, y: y + height },
    ].forEach(point => {
        const translatedPoint = Vector.from(point)
            .rotateDegAroundPoint(rotation, center);
        Object.assign(bounds, {
            maxX: Math.max(bounds.maxX || translatedPoint.x, translatedPoint.x),
            minX: Math.min(bounds.minX || translatedPoint.x, translatedPoint.x),
            maxY: Math.max(bounds.maxY || translatedPoint.y, translatedPoint.y),
            minY: Math.min(bounds.minY || translatedPoint.y, translatedPoint.y),
        });
    });
    bounds.width = bounds.maxX - bounds.minX;
    bounds.height = bounds.maxY - bounds.minY;
    return bounds;
}

function LayerPreview(props) {
    const { className, layer } = props;
    const [scale, setScale] = useState(0);
    const containerRef = useRef();

    useEffect(() => {
        const {
            offsetWidth: previewWidth,
            offsetHeight: previewHeight,
        } = containerRef.current;

        const {
            width: layerWidthInches,
            height: layerHeightInches,
        } = getLayerBounds(layer);
        const layerWidth = layerWidthInches * PIXELS_PER_INCH;
        const layerHeight = layerHeightInches * PIXELS_PER_INCH;
        const widthRatio = previewWidth / layerWidth;
        const heightRatio = previewHeight / layerHeight;
        setScale(Math.min(widthRatio, heightRatio));
    }, [containerRef, layer, setScale]);

    // we need to downscale to fit the preview window
    const properties = {
        ...layer,
        x: 0,
        y: 0,
        scale,
    };


    return (
        <div className={className} ref={containerRef}>
            <Checkboard />
            {layer && (
                <Layer
                    preview
                    attributes={properties}
                />
            )}
        </div>
    );
}

export default styled(LayerPreview)(styles);

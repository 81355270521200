import { styled } from '@mui/material/styles';
import React from 'react';


const styles = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
    color: theme.palette.primary.contrastText,
    overflow: 'auto',
    height: '100%',
});

function Splash(props) {
    const { children, className } = props;
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default styled(Splash)(styles);

import React from 'react';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const styles = ({ theme }) => ({
    // position: 'relative',
    // height: '4.75in',
    // width: '6.25in',
    // '& .point': {
    // width: ,
    height: 0,
    // backgroundColor: 'green',
    // borderRadius: '50%',
    borderTop: '1px solid red',
    position: 'absolute',
    zIndex: 500,
    // }
});

function DebugVectors(props) {
    const { className, vectors } = props;

    const flattenedVectors = [];
    if (Array.isArray(vectors)) {
        vectors.forEach(vector => flattenedVectors.push({
            distance: vector.distance,
            angle: vector.angle,
            x: vector.displayCenter?.x || 0,
            y: vector.displayCenter?.y || 0,
            color: vector.color
        }))
    } else if (typeof vectors === 'object') {
        Object.keys(vectors).forEach(
            color => vectors[color].forEach(
                vector => flattenedVectors.push({
                    distance: vector.distance,
                    angle: vector.angle,
                    x: vector.displayCenter?.x || 0,
                    y: vector.displayCenter?.y || 0,
                    color,
                })
            )
        );
    }

    return (
        // <div className={'debug-points ' + className} style={style}>
        <>
            {flattenedVectors.map((vector, key) => (
                <div
                    key={key}
                    className={className}
                    style={{
                        top: vector.y + 'in',
                        left: vector.x + 'in',
                        width: (vector.distance) + 'in',
                        // rotate: vector.angle + 'rad',
                        transform: 'rotate(' + vector.angle + 'rad)',
                        transformOrigin: 'top left',
                        borderColor: vector?.color,
                    }}
                />
            ))}
        </>
    );
}

export default styled(DebugVectors)(styles);

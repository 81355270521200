import { setXStateObject } from "hooks/useXState";
import { useCallback, useRef } from "react";
import Matrix from '../../../library/Matrix';
import Vector from '../../../library/Vector';
import { TOOL_TYPE_ADD_TEXT } from "./useCanvasSelection";
import { PIXELS_PER_INCH_RATIO, PRESS_TIMEOUT, grabbieTransformMap } from "./useMouse";

export default function useTouch(config) {
    const {
        addLayer,
        viewport,
        layerSelection,
        defaultPropertiesRef
    } = config;

    const {
        screenRef,
        toolsRef,
        propertiesRef,
        viewportToolsRef,
        updateCanvasLocation,
        updateCanvasRotation,
        updateCanvasZoom,
        viewportMatrix,
        disableAnimations,
        preview,
    } = viewport;

    const {
        hasSelection,
        isItemSelected,
        setSelection,
        toggleItem,
        clearSelection,
        updateSelection,
        setTool,
        tool
    } = layerSelection;


    const stateRef = useRef({});
    const updateState = useCallback((...args) => {
        stateRef.current = setXStateObject({ update: true }, stateRef.current, ...args);
    }, [stateRef]);

    const onTouchStart = useCallback((event) => {
        // event.stopPropagation();
        const {
            touches: eventTouches,
            target: initialTarget,
        } = event;

        const touchDown = [...eventTouches].map(touch => ({
            identifier: touch.identifier,
            clientX: touch.clientX,
            clientY: touch.clientY,
            pageX: touch.pageX,
            pageY: touch.pageY,
            screenX: touch.screenX,
            screenY: touch.screenY,
            // target: touch.target
        }));

        // important to do this after the panel ignore
        let target = initialTarget;
        while (!target?.dataset?.type) {
            target = target.parentNode;
        }

        if (touchDown.length === 1) {
            if (!hasSelection && tool.startsWith('add:')) {
                if (tool === TOOL_TYPE_ADD_TEXT) {
                    updateState({ action: tool, lastTouches: touchDown });
                }
            } else if (target.dataset.type === 'layer' && isItemSelected(target.dataset.key)) {
                updateState({ action: 'touchDownOnSelectedLayer', lastTouches: touchDown });
            } else if (['workspace', 'canvas', 'layer'].includes(target.dataset.type)) {
                updateState({
                    action: 'touchDownOnWorkArea',
                    lastTouches: touchDown,
                    holdTimeout: setTimeout(() => {
                        setSelection(parseInt(target.dataset.key, 10));
                        updateState({ action: 'touchDownOnSelectedLayer', holdTimeout: null });
                    }, PRESS_TIMEOUT),
                });
            } else if (target.dataset.type && target.dataset.type.startsWith('grabbie-')) {
                updateState({ action: target.dataset.type.replace('grabbie-', 'touchDownOnGrabbie:'), lastTouches: touchDown, });
            }
        }
    }, [updateState, hasSelection, tool, isItemSelected, setSelection]);

    const onTouchMove = useCallback((event) => {
        // event.stopPropagation();
        // event.preventDefault();
        const {
            currentTarget,
            touches: eventTouches,
            touches: [
                { clientX: currentTouchX, clientY: currentTouchY },
            ]
            // changedTouches: [
            //     touch1,
            //     touch2,
            // ],
        } = event;

        const touches = [...eventTouches].map(touch => ({
            identifier: touch.identifier,
            clientX: touch.clientX,
            clientY: touch.clientY,
            pageX: touch.pageX,
            pageY: touch.pageY,
            screenX: touch.screenX,
            screenY: touch.screenY,
            // target: touch.target
        }));

        const {
            action,
            lastTouches: [
                { clientX: lastTouchX, clientY: lastTouchY } = {},
                touchDown2,
            ] = [],
            holdTimeout
        } = stateRef.current;
        clearTimeout(holdTimeout);

        const touchMovement = {
            x: currentTouchX - lastTouchX,
            y: currentTouchY - lastTouchY,
        };

        if (touches.length === 1) {
            updateState('lastTouches', [
                { clientX: currentTouchX, clientY: currentTouchY },
            ]);
            if (action?.startsWith('new:')) {
                // TODO
            } else if (action === 'touchDownOnSelectedLayer' || action === 'dragging') {
                updateState('action', 'dragging');
                const inverseViewPort = Matrix.from(viewportMatrix).clearTranslation().inverse();
                const mouseMovement = inverseViewPort.project(Vector.from({ x: touchMovement.x, y: touchMovement.y })).multiply(PIXELS_PER_INCH_RATIO);
                updateSelection(({ x, y }) => ({
                    x: parseFloat(x) + mouseMovement.x,
                    y: parseFloat(y) + mouseMovement.y
                }));
            } else if (action === 'touchDownOnWorkArea' || action === 'panning') {
                updateState('action', 'panning');
                updateCanvasLocation(touchMovement.x, touchMovement.y);
            } else if (action?.startsWith('touchDownOnGrabbie:') || action?.startsWith('grabbing:')) {
                const [, grabbie] = action.split(':');
                const inverseViewPort = Matrix.from(viewportMatrix).clearTranslation().inverse();
                const relativeTouchMovement = inverseViewPort.project(Vector.from({ x: touchMovement.x, y: touchMovement.y })).multiply(PIXELS_PER_INCH_RATIO);

                if (grabbie in grabbieTransformMap) {
                    const { [grabbie]: transform } = grabbieTransformMap;
                    updateSelection((layer) => {
                        layer = {
                            x: parseFloat(layer.x),
                            y: parseFloat(layer.y),
                            width: parseFloat(layer.width),
                            height: parseFloat(layer.height),
                            rotation: parseFloat(layer.rotation), // deg
                        };
                        const mouseMovement = Vector.from(relativeTouchMovement).rotateDeg(-layer.rotation);
                        const scaleAnchor = transform.getScaleAnchor(layer);
                        const scaleGrabby = transform.getScaleGrabby(layer);
                        const centerPoint = {
                            x: layer.x + layer.width / 2,
                            y: layer.y + layer.height / 2,
                        };
                        const scaleAnchorRotated = Vector.from(scaleAnchor).rotateDegAroundPoint(layer.rotation, centerPoint);
                        const scaleGrabbyRotated = Vector.from(scaleGrabby).rotateDegAroundPoint(layer.rotation, centerPoint);
                        const newScaleGrabbyRotated = transform.getNewScaleGrabbyRotated({
                            scaleGrabbyRotated,
                            mouseMovementRotated: relativeTouchMovement,
                            mouseMovement,
                            layer,
                        });
                        const newCenterPoint = {
                            x: (scaleAnchorRotated.x + newScaleGrabbyRotated.x) / 2,
                            y: (scaleAnchorRotated.y + newScaleGrabbyRotated.y) / 2,
                        };
                        const newDimensions = transform.getDimensions(layer, mouseMovement);
                        const newPosition = {
                            x: newCenterPoint.x - newDimensions.width / 2,
                            y: newCenterPoint.y - newDimensions.height / 2,
                        };

                        return {
                            ...newPosition,
                            ...newDimensions
                        }
                    });
                } else if (grabbie === 'spinny') {
                    const screenRect = screenRef.current.getBoundingClientRect();
                    const touchPos = new Vector({
                        x: currentTouchX - screenRect.x,
                        y: currentTouchY - screenRect.y
                    });

                    Matrix.from(viewportMatrix)
                        .inverse()
                        .project(touchPos)
                        .multiply(PIXELS_PER_INCH_RATIO);

                    updateSelection((layer) => {
                        layer = {
                            x: parseFloat(layer.x),
                            y: parseFloat(layer.y),
                            width: parseFloat(layer.width),
                            height: parseFloat(layer.height),
                        };
                        return {
                            rotation: Vector.from(touchPos).sub({
                                x: layer.x + layer.width / 2,
                                y: layer.y + layer.height / 2,
                            }).angleDeg + 180 / 2
                        };
                    });
                } else if (grabbie === 'border-radius') {
                    const screenRect = screenRef.current.getBoundingClientRect();
                    const touchPos = new Vector({
                        x: currentTouchX - screenRect.x,
                        y: currentTouchY - screenRect.y
                    });

                    Matrix.from(viewportMatrix)
                        .inverse()
                        .project(touchPos)
                        .multiply(PIXELS_PER_INCH_RATIO);

                    updateSelection((layer) => {
                        layer = {
                            x: parseFloat(layer.x),
                            y: parseFloat(layer.y),
                            width: parseFloat(layer.width),
                            height: parseFloat(layer.height),
                            rotation: parseFloat(layer.rotation),
                        };
                        const centerPoint = Vector.from({
                            x: layer.x + layer.width / 2,
                            y: layer.y + layer.height / 2,
                        });
                        const m = Vector.from(touchPos).rotateDegAroundPoint(-layer.rotation, centerPoint);
                        const pointZero = Vector.from(layer);
                        const diff = Vector.from(m).sub(pointZero);

                        return {
                            borderRadius: diff.x,
                        }
                    });
                }
                updateState('action', (action) => action.replace('mouseDownOnGrabbie:', 'grabbing:'));
            }
        }
        return false;
    }, [stateRef, updateState, updateCanvasLocation, updateSelection, viewportMatrix, screenRef]);

    return {
        touchEventHandlerProps: {
            onTouchStart,
            onTouchMove
        },
        touchState: stateRef.current,
    }
}

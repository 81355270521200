import { useCallback, useMemo, useState } from 'react';
import useRefObject from '../../../hooks/useRefObject';
import useSelection from '../../../hooks/useSelection';
import useStateObject from '../../../hooks/useStateObject';
import Matrix from '../../../library/Matrix';
import Vector from '../../../library/Vector';
import { getLayerState } from '../Layer';
import { PIXELS_PER_INCH } from './useMouse';

export const TOOL_TYPE_POINTER = 'manipulate';
export const TOOL_TYPE_TEXT = 'text';
export const TOOL_TYPE_ADD_CIRCLE = 'add:circle';
export const TOOL_TYPE_ADD_SQUARE = 'add:square';
export const TOOL_TYPE_ADD_IMAGE = 'add:image';
export const TOOL_TYPE_ADD_QRCODE = 'add:qrcode';
export const TOOL_TYPE_ADD_TEXT = 'add:text';


export const SELECTION_TOOL_MODE_MULTI_TOOL = 'multi-tool';
export const SELECTION_TOOL_MODE_TEXT = 'text';

export default function useCanvasSelection({
    viewport,
    updateLayers,
    layers,
    pageErrors,
    defaultEditorProperties,
    ...forwardArgs
}) {
    const [tool, setTool] = useState(TOOL_TYPE_POINTER);

    const layerSelection = useSelection({
        ...forwardArgs,
        forceMultipleOutput: true,
    });

    const {
        isItemSelected,
        unSelectItem,
        selection,
        selectionRef,
        setSelection,
        clearSelection,
    } = layerSelection;

    // layer errors
    const layerErrors = selection.length === 1 && pageErrors.layers?.[selection[0]]
        ? pageErrors.layers[selection[0]]
        : {};

    const selectedLayers = useMemo(
        () => selection.map(layerIndex => layers[layerIndex]).filter(Boolean),
        [selection, layers]
    );
    const getLayerId = useCallback(layer => layers.indexOf(layer), [layers]);

    const deleteSelection = useCallback(
        () => updateLayers(
            layers => {
                const newLayers = layers.filter(
                    (_, layerId) => !isItemSelected(layerId)
                );
                clearSelection();
                return newLayers;
            }
        ),
        [updateLayers, isItemSelected, clearSelection]
    );

    const deleteLayer = useCallback(
        (layerIdToDelete) => updateLayers(
            layers => {
                unSelectItem(layerIdToDelete);
                return layers.filter(
                    (_, layerId) => layerId !== layerIdToDelete
                );
            }
        ), [updateLayers, unSelectItem]
    );

    // const updateSelection = useCallback(
    //     update => updateLayers(
    //         layers => layers.map(
    //             (layer, layerId) => isItemSelected(layerId)
    //                 ? { ...layer, ...(typeof update === 'function' ? update(layer) : update) }
    //                 : layer
    //         )
    //     ), [updateLayers, isItemSelected]
    // );

    const updateSelection = useCallback(
        (...update) => updateLayers(selectionRef.current, ...update),
        [updateLayers, selectionRef]
    );

    const toggleSelectionVisibility = useCallback(
        () => updateSelection(({ hidden }) => ({ hidden: !hidden })), [updateSelection]
    );

    const toggleSelectionLock = useCallback(
        () => updateSelection(({ locked }) => ({ locked: !locked })), [updateSelection]
    );

    const bringSelectionForward = useCallback(
        () => updateLayers(layers => {
            const selectedIndex = selectionRef.current;
            if (selectedIndex < layers.length - 1) {
                const newIndex = selectedIndex + 1;
                const newLayers = [...layers];
                const itemToBringForward = newLayers[selectedIndex];
                const itemToMoveBackward = newLayers[newIndex];
                newLayers[newIndex] = itemToBringForward;
                newLayers[selectedIndex] = itemToMoveBackward;
                setSelection(newIndex);
                return newLayers;
            }
            return layers;
        }),
        [updateLayers, selectionRef, setSelection]
    );
    const bringSelectionToFront = useCallback(
        () => updateLayers(layers => {
            const selectedIndex = selectionRef.current;
            if (selectedIndex < layers.length - 1) {
                const newLayers = layers.filter((_, index) => index !== selectedIndex);
                newLayers.push(layers[selectedIndex]);
                setSelection(layers.length - 1);
                return newLayers;
            }
            return layers;
        }),
        [updateLayers, selectionRef, setSelection]
    );

    const bringSelectionBackward = useCallback(
        () => updateLayers(layers => {
            const selectedIndex = selectionRef.current;
            if (selectedIndex > 0) {
                const newIndex = selectedIndex - 1;
                const newLayers = [...layers];
                const itemToBringBackward = newLayers[selectedIndex];
                const itemToBringForward = newLayers[newIndex];
                newLayers[newIndex] = itemToBringBackward;
                newLayers[selectedIndex] = itemToBringForward;
                setSelection(newIndex);
                return newLayers;
            }
            return layers;
        }),
        [updateLayers, selectionRef, setSelection]
    );

    const bringSelectionToBack = useCallback(
        () => updateLayers(layers => {
            const selectedIndex = selectionRef.current;
            if (selectedIndex > 0) {
                const newLayers = layers.filter((_, index) => index !== selectedIndex);
                newLayers.unshift(layers[selectedIndex]);
                setSelection(0);
                return newLayers;
            }
            return layers;
        }),
        [updateLayers, selectionRef, setSelection]
    );

    const updateLayerOrder = useCallback(
        (layerLocation, newLocation) => {
            if (layerLocation !== newLocation) {
                updateLayers(layers => {
                    // collapse edge cases
                    newLocation = newLocation < 0
                        ? 0
                        : newLocation >= layers.length
                            ? layers.length - 1
                            : newLocation;
                    if (layerLocation === newLocation) {
                        return layers;
                    }

                    // rebuild new layers array
                    const newLayers = [];
                    for (let i = 0; i < layers.length; i++) {
                        if (i === newLocation) {
                            if (newLocation > layerLocation) {
                                newLayers.push(layers[i]);
                                newLayers.push(layers[layerLocation]);
                            } else {
                                newLayers.push(layers[layerLocation]);
                                newLayers.push(layers[i]);
                            }
                        } else if (i !== layerLocation) {
                            newLayers.push(layers[i]);
                        }
                    }
                    return newLayers;
                });
                setSelection(newLocation);
            }
        },
        [updateLayers, setSelection]
    );

    const { viewportMatrix: currentViewportMatrix } = viewport;
    const { angleDeg: currentViewportAngle, scaleX: currentScale } = currentViewportMatrix.destructure();

    const {
        value: selectionInfo,
        valueRef: selectionInfoRef,
        updateValue: updateSelectionInfo,
    } = useRefObject({});

    // code that resets the
    const selectionId = selection.join(':');
    if (selectionInfo.id !== selectionId) {
        updateSelectionInfo({
            // reset selection offset angle
            selectionOffsetAngle: 0,
            // setting the original viewport angle
            originalViewportAngle: currentViewportAngle,
            originalScale: currentScale,
            // original viewport matrix
            originalViewportMatrix: Matrix.from(currentViewportMatrix),
        });
    }

    const {
        originalViewportMatrix: originalViewportMatrixIN,
        originalViewportAngle,
        originalScale,
    } = selectionInfoRef.current;

    const originalBounds = {
        maxX: null,
        minX: null,
        maxY: null,
        minY: null,
        rotation: 0,
    };

    // Converting viewport matrix to PX
    const originalViewportMatrixPX = Matrix.from(originalViewportMatrixIN).scale(PIXELS_PER_INCH);
    let locked = false;

    selectedLayers.forEach(layer => {
        const layerState = getLayerState(layer, { map: {} });
        const layerId = getLayerId(layer);
        if (isItemSelected(layerId)) {
            locked = locked || layerState.locked;
        }
        if (layerState) {
            // TODO: USE of layerState here is sus....
            let { values: { x, y, width, height, rotation } } = layerState;
            rotation = parseFloat(rotation);
            x = parseFloat(x);
            y = parseFloat(y);
            width = parseFloat(width);
            height = parseFloat(height);
            const layerTransform = Matrix.from(originalViewportMatrixPX);
            if (selection.length > 1) {
                // when selecting multiple layers, we calculate exact corner points
                layerTransform.rotateAroundPointDeg(rotation, Vector.from({
                    x: x + width / 2,
                    y: y + height / 2
                }));
            } else {
                // when selecting a single layer, selection overlay adopts layers orientation
                // here we rotate layer to align at 0rad so bounds = layer size
                layerTransform.rotateAroundPointDeg(-originalViewportAngle, Vector.from({
                    x: x + width / 2,
                    y: y + height / 2
                }));
                originalBounds.rotation = rotation + originalViewportAngle;
            }
            [
                { x, y },
                { x: x + width, y },
                { x, y: y + height },
                { x: x + width, y: y + height },
            ]
                .forEach(point => {
                    const originalPoint = layerTransform.project(point);
                    originalBounds.minX = originalBounds.minX === null || originalPoint.x < originalBounds.minX ? originalPoint.x : originalBounds.minX;
                    originalBounds.minY = originalBounds.minY === null || originalPoint.y < originalBounds.minY ? originalPoint.y : originalBounds.minY;
                    originalBounds.maxX = originalBounds.maxX === null || originalPoint.x > originalBounds.maxX ? originalPoint.x : originalBounds.maxX;
                    originalBounds.maxY = originalBounds.maxY === null || originalPoint.y > originalBounds.maxY ? originalPoint.y : originalBounds.maxY;
                });
        }
    });

    // Creating Transform to convert an original point into a current point
    const originalToCurrentTransform = Matrix
        .from(currentViewportMatrix)
        .scale(PIXELS_PER_INCH)
        .dot(Matrix.from(originalViewportMatrixPX).inverse());

    // calculating selection center
    const selectionCenter = originalToCurrentTransform.project({
        x: originalBounds.minX + (originalBounds.maxX - originalBounds.minX) / 2,
        y: originalBounds.minY + (originalBounds.maxY - originalBounds.minY) / 2,
        center: true,
    });

    const currentSelectionScale = Vector.from({
        x: (originalBounds.maxX - originalBounds.minX),
        y: (originalBounds.maxY - originalBounds.minY),
    }).multiply(1 / originalScale).multiply(currentScale);

    const selectionBoundsStyle = {
        left: (selectionCenter.x - currentSelectionScale.x / 2) - 1 + 'px',
        top: (selectionCenter.y - currentSelectionScale.y / 2) - 1 + 'px',
        width: currentSelectionScale.x + 'px',
        height: currentSelectionScale.y + 'px',
        transform: 'rotate(' + (-originalViewportAngle + currentViewportAngle + originalBounds.rotation) + 'deg)',
    };

    // contxt switching for default properties
    const {
        state: values,
        stateRef: valuesRef,
        updateState: updateDefaultProperties,
    } = useStateObject({
        backgroundColor: 'rgba(50,100,100,0.8)',
        borderColor: '#eeeeee',
        color: '#000000',
        x: 0,
        y: 0,
        width: 1,
        height: 1,
        rotation: 0,
        fontWeight: 'normal',
        fontSize: 30,
        // fontStyle: 'normal',
        fontFamily: 'Roboto',
        text: '',
        textAlign: 'center',
        verticalAlign: 'middle',
        borderRadius: 0,
        borderWidth: 0.1,
        borderStyle: 'solid',
        padding: 0,
        filters: [],
    });
    const updateEffectiveSelection = layerSelection.hasSelection ? updateSelection : updateDefaultProperties;
    const properties = {
        ...values,
    };
    if (selectedLayers.length === 1) {
        Object.assign(properties, selectedLayers[0]);
    } else {
        let union = null;
        selectedLayers.forEach(layer => {
            if (union === null) {
                union = { ...layer };
            } else {
                Object.keys(layer).forEach(attribute => {
                    if (attribute in union && union[attribute] !== layer[attribute]) {
                        delete union[attribute];
                    }
                });
            }
        })
        Object.assign(properties, union);
    }

    return {
        ...layerSelection,
        layerErrors,
        deleteSelection: deleteSelection,
        deleteLayer,
        toggleSelectionVisibility,
        toggleSelectionLock,
        properties,
        defaultPropertiesRef: valuesRef,
        updateSelection: locked ? null : updateEffectiveSelection,
        updateLayers,
        selectedLayers,
        selectionBoundsStyle,
        currentScale,
        getLayerId,
        locked,
        tool,
        setTool,

        bringSelectionForward,
        bringSelectionToFront,
        bringSelectionBackward,
        bringSelectionToBack,
        updateLayerOrder,
    };
}

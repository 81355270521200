import { useCallback, useRef, useState } from 'react';
import useAsyncCallback from './useAsyncCallback';

const DEFAULT_EMPTY_SELECTION = undefined;

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function getDefaultSelection(multiple) {
    return multiple ? [] : DEFAULT_EMPTY_SELECTION;
}


function useSelection({ multiple = false, forceMultipleOutput = false, allOptions, value, onChange, stateHandle } = {}) {
    const internalStateHandle = useState(getDefaultSelection(multiple));
    let [selectionState, setSelectionState] = stateHandle || internalStateHandle;
    const controllerRef = useRef();
    controllerRef.current = {
        value,
        onChange,
    };
    let selection = typeof onChange === 'function' ? value : selectionState;
    const setSelection = useCallback(selection => {
        if (typeof controllerRef.current.onChange === 'function') {
            let newSelection = typeof selection === 'function' ? selection(controllerRef.current.value) : selection;
            controllerRef.current.onChange(newSelection);
        } else {
            setSelectionState(selection);
        }
    }, [setSelectionState, controllerRef]);
    const {
        result: completeSelection,
        callback: getAllOptions,
        loading,
        error,
    } = useAsyncCallback(allOptions);
    const hasSelection = multiple ? selection.length > 0 : selection !== undefined;
    const selectionRef = useRef();
    selectionRef.current = selection;

    const selectItem = useCallback(
        (...items) => setSelection(
            currentSelection => multiple
                ? [...currentSelection, ...items].filter(onlyUnique)
                : items[0]
        ),
        [multiple, setSelection]
    );

    const unSelectItem = useCallback(
        (...items) => setSelection(
            currentSelection => multiple
                ? currentSelection.filter(selectedItem => !items.some(member => `${member}` === `${selectedItem}`))
                : `${currentSelection}` === `${items[0]}` ? DEFAULT_EMPTY_SELECTION : currentSelection
        ),
        [multiple, setSelection]
    );


    const clearSelection = useCallback(
        () => setSelection(getDefaultSelection(multiple)),
        [multiple, setSelection]
    );

    const isItemSelected = useCallback(
        item => {
            return multiple
                ? selectionRef.current.some(member => `${member}` === `${item}`)
                : `${selectionRef.current}` === `${item}`
        },
        [multiple, selectionRef]
    );

    const toggleItem = useCallback(
        (...items) => {
            const selectItems = [];
            const unselectItems = [];
            items.forEach(item => {
                if (isItemSelected(item)) {
                    unselectItems.push(item);
                } else {
                    selectItems.push(item);
                }
            });
            selectItems.length && selectItem(...selectItems);
            unselectItems.length && unSelectItem(...unselectItems);
        },
        [isItemSelected, unSelectItem, selectItem]
    );

    const selectAll = useCallback(
        () => getAllOptions().then(options => setSelection(options)),
        [getAllOptions, setSelection]
    );

    const allSelected = selection === completeSelection;

    if (forceMultipleOutput && !Array.isArray(selection)) {
        selection = selection === null || selection === undefined ? [] : [selection];
    }

    const resultRef = useRef({});
    Object.assign(resultRef.current, {
        selection,
        setSelection,
        getAllOptions,
        allSelected,
        loading,
        error,
        hasSelection,
        selectionRef,
        selectItem,
        selectAll,
        unSelectItem,
        isItemSelected,
        toggleItem,
        clearSelection,
    });
    return resultRef.current;
}

export default useSelection;

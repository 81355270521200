import styled from "@emotion/styled";
import { Fab } from "@mui/material";
import Icon from "components/Icon";

const PreviewFab = styled(Fab)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0, //theme.spacing(1),
    transform: `translate(0px, calc(-50% - ${theme.spacing(2)}))`,
}));

export const PREVIEW_LAYOUT = [
    (props) => {
        return (
            <div style={{ position: 'relative' }}>
                <PreviewFab color="secondary">
                    <Icon name="send" />
                </PreviewFab>
            </div>
        );
    },
    {
        name: 'name',
        variant: 'h5:typography',
    },
    {
        name: 'description',
        rows: 3,
        multiline: true,
    },
    [{
        name: 'dateUpdated',
        cellProps: { xs: 6 },
    }, {
        name: 'dateCreated',
        cellProps: { xs: 6 },
    }]
];

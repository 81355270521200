import axios from 'axios';
import SupaCache from 'library/SupaCache';
import { createContext, useContext } from 'react';


const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );

const camelToSnake = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

class MojipostClient {
    constructor(adapter) {
        this.adapter = adapter;
        this.supaCache = new SupaCache('testing', {
            recordTypeMap: {
                address: { keyPath: 'id', autoIncrement: false },
                postcard: { keyPath: 'id', autoIncrement: false },
                image: { keyPath: 'id', autoIncrement: false },
                'postcard-print': { keyPath: 'id', autoIncrement: false },
                metadata: { keyPath: 'recordType', autoIncrement: false },
            }
        });
        const axiosClient = axios.create({
            adapter,
            withCredentials: true,
        });
        this.axios = axiosClient;
    }
    getConfig() {
        return {
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        }
    }
    _getUserPromise = null;
    _getUserResponse = null;
    getUserAccount = () => {
        // console.log('GET USER ACCOUNT CALLED');
        if (this._getUserPromise === null) {
            this._getUserPromise = this.axios.get('/account/')
                .then(response => response.data)
                .then(this.responseTransform)
                .then(data => {
                    this._getUserResponse = data;
                    return data;
                });
        } else if (this._getUserResponse !== null) {
            // return the physical result instead of the promise for performance
            return this._getUserResponse;
        }
        return this._getUserPromise;
    }
    describeAccount = () => this.axios.options('/account/')
        .then(response => response.data)
        .then(this.responseTransform);
    updateAccount = (update) => {
        if (!(update.image instanceof File)) {
            update = { ...update };
            delete update['image'];
        }
        return this.axios.put('/account/', this.toFormData(this.dataTransform(update)), this.getConfig())
            .then(response => response.data)
            .then(this.responseTransform)
            .catch(error => {
                throw this.responseTransform(error.response.data);
            });
    }
    logout() {
        return this.axios.get('/accounts/logout');
    }

    responseTransform = response => {
        if (Array.isArray(response)) {
            return response.map(item => this.responseTransform(item));
        } else if (response && typeof response === 'object') {
            const transformedResponse = {};
            Object.keys(response).forEach(key => {
                transformedResponse[snakeToCamel(key)] = this.responseTransform(response[key]);
            });
            return transformedResponse;
        }
        return response;
    }

    updateSupaCacheRecord(apiName, record) {
        return this.supaCache.updateRecord(apiName, record);
    }

    loadData(data) {
        // keys are apiNames, values are records or lists of records
        const transformedData = {};
        Object.keys(data).forEach(recordType => {
            transformedData[recordType] = this.responseTransform(data[recordType]);
        });
        return this.supaCache.loadData(transformedData);
    }

    dataTransform = data => {
        if (data instanceof FormData) {
            return data;
        }
        if (Array.isArray(data)) {
            return data.map(item => this.dataTransform(item));
        } else if (data instanceof File) {
            return data;
        } else if (data && typeof data === 'object') {
            const transformedData = {};
            Object.keys(data).forEach(key => {
                transformedData[camelToSnake(key)] = this.dataTransform(data[key]);
            });
            return transformedData;
        }
        return data;
    }

    toFormData(params) {
        const data = new FormData();
        // step 1 get all our structure flattened
        const paramNodes = [{ key: [], params }];
        while (paramNodes.length) {
            const paramNode = paramNodes.pop();
            if (paramNode.params === null || paramNode.params === undefined) { //} || isNaN(paramNode.params)) {
                data.append(paramNode.key.join('.'), paramNode.params);
            } else if (paramNode.params instanceof File ||
                paramNode.params instanceof Blob ||
                typeof paramNode.params !== 'object'
            ) {
                data.append(paramNode.key.join('.'), paramNode.params);
            } else if (Array.isArray(paramNode.params)) {
                throw Error('Unsupported Array type in formData');
            } else {
                Object.keys(paramNode.params).forEach(key => paramNodes.push({
                    key: [...paramNode.key, key],
                    params: paramNode.params[key],
                }));
            }

        }
        // Object.keys(params).forEach(key => data.append(key, params[key]));
        return data;
    }

    describeRecord = (apiName, id) => {
        const metadataId = `${apiName}_${Boolean(id) ? 'instance' : 'list'}`;
        return this.supaCache.getRecord('metadata', metadataId)
            .then(result => {
                if (result === undefined) {
                    return this.axios.options(`/api/${[apiName, id].filter(Boolean).join('/')}/`)
                        .then(response => response.data)
                        .then(this.responseTransform)
                        .then(record => this.updateSupaCacheRecord('metadata', record))
                        .catch(error => {
                            throw this.responseTransform(error.response.data);
                        });
                }
                return result;
            });
    }

    getRecords = (apiName, params) => {
        return this.axios.get(`/api/${apiName}/`, { params })
            .then(response => response.data)
            .then(this.responseTransform)
            .then(data => this.supaCache.updateRecords(apiName, data.results).then(() => data))
            .catch(error => {
                throw this.responseTransform(error.response.data);
            });
    }

    getRecord = (apiName, id) => {
        return this.supaCache.getRecord(apiName, id)
            .then(result => {
                if (result === undefined) {
                    return this.axios.get(`/api/${apiName}/${id}/`)
                        .then(response => response.data)
                        .then(this.responseTransform)
                        .then((record) => this.updateSupaCacheRecord(apiName, record))
                        .catch(error => {
                            const reject = Promise.reject(this.responseTransform(error.response?.data));
                            return reject;
                        });
                }
                return result;
            })
    }

    postRecord = (apiName, data) => this.axios.post(`/api/${apiName}/`, this.dataTransform(data), this.getConfig())
        .then(response => response.data)
        .then(this.responseTransform)
        .then((record) => this.updateSupaCacheRecord(apiName, record))
        .catch(error => {
            throw this.responseTransform(error.response.data);
        });

    patchRecord = (apiName, id, data) => this.axios.patch(`/api/${apiName}/${id}/`, this.dataTransform(data), this.getConfig())
        .then(response => response.data)
        .then(this.responseTransform)
        .then((record) => this.updateSupaCacheRecord(apiName, record))
        .catch(error => {
            throw this.responseTransform(error.response.data);
        });

    deleteRecord = (apiName, id) => this.axios.delete(`/api/${apiName}/${id}/`, this.getConfig())
        .then(response => response.data)
        .then(this.responseTransform)
        .catch(error => {
            throw this.responseTransform(error.response.data);
        });

    describeAddress = (...parts) => this.describeRecord('address', ...parts);
    getAddresses = (params) => this.getRecords('address', params);
    getAddress = (id) => this.getRecord('address', id);
    postAddress = (params) => this.postRecord('address', params);
    patchAddress = (id, data) => this.patchRecord('address', id, data);
    deleteAddress = (id) => this.deleteRecord('address', id);
    addAddressListener = (id, listener) => this.supaCache.addRecordListener('address', id, listener);
    removeAddressListener = (id, listener) => this.supaCache.removeRecordListener('address', id, listener);

    describePostcard = (...parts) => this.describeRecord('postcard', ...parts);
    getPostcards = (params) => this.getRecords('postcard', params);
    getPostcard = (id) => this.getRecord('postcard', id);
    postPostcard = (data) => this.postRecord('postcard', data);
    patchPostcard = (id, data) => this.patchRecord('postcard', id, data);
    addPostcardListener = (id, listener) => this.supaCache.addRecordListener('postcard', id, listener);
    removePostcardListener = (id, listener) => this.supaCache.removeRecordListener('postcard', id, listener);

    describeImage = (...parts) => this.describeRecord('image', ...parts);
    getImages = (params) => {
        const resultPromise = this.getRecords('image', params);
        resultPromise.then(response => {
            response.results.forEach(imageRecord => {
                this.imageCache[imageRecord.id] = imageRecord;
            });
        });
        return resultPromise;
    };
    imageCache = {};
    getImage = (id) => {
        // TODO: SupaCache2.0
        if (!(id in this.imageCache)) {
            this.imageCache[id] = this.getRecord('image', id)
                .then(result => {
                    this.imageCache[id] = result;
                    return result;
                });
        }
        return this.imageCache[id];
    };
    postImage = (data) => this.postRecord('image', this.toFormData(data));
    patchImage = (id, data) => this.patchRecord('image', id, data);
    addImageListener = (id, listener) => this.supaCache.addRecordListener('image', id, listener);
    removeImageListener = (id, listener) => this.supaCache.removeRecordListener('image', id, listener);
    // patchImage = (id, data) => this.patchRecord('image', id, data);
    // deleteImage = (id) => this.deleteRecord('image', id);

    getPostcardPrints = (params) => this.getRecords('postcard-print', params);
    getPostcardPrint = (id) => this.getRecord('postcard-print', id);
}
const client = new MojipostClient();
client.loadData(window?.MP_DATA_EMBED || {});
window.client = client;


const MojipostClientContext = createContext(client);
const MojipostClientProvider = MojipostClientContext.Provider;

/**
 *
 * @returns {MojipostClient}
 */
function useMojipostClient() {
    const mojipostClient = useContext(MojipostClientContext);
    return mojipostClient;
}

export default MojipostClient;
export {
    MojipostClientProvider, useMojipostClient
};

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';
import { usePartialFontLoader } from './PostcardEditor/hooks/useFontManager';

const styles = ({ theme }) => ({
    fontSize: '25px'
});

const FontOption = function FontOption(props) {
    const { className, text, fontFamily, fontWeight } = props;

    const { loading } = usePartialFontLoader(fontFamily, text);

    return (
        <Box
            className={className}
            sx={{
                fontFamily: JSON.stringify(fontFamily),
                fontWeight,
            }}
        >
            {text} {loading && '...'}
        </Box>
    );
}

export default styled(FontOption)(styles);

import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import AccountDetail from 'components/AccountDetail';
import MobileDrawer from 'components/MobileDrawer';
import { PostcardPlate } from 'components/Plates';
import React, { useCallback } from 'react';
import { Navigate, Route, Routes, useMatch, useNavigate, useSearchParams } from "react-router-dom";
import AppFrame from "./components/AppFrame";
import AppMenu from "./components/AppMenu";
import AppPreviewPanel from './components/AppPreviewPanel';
import Authenticated from "./components/Authenticated";
import Map from './components/Map';
import Marker from './components/Marker';
import PersistentSetManager from "./components/PersistentSetManager";
import PostCardEditor from './components/PostcardEditor/PostCardEditor';
import PostcardPrintDetail from './components/PostcardPrintDetail';
import UserMenu from './components/UserMenu';
import { useMojipostClient } from './connectors/MojipostClient';
import { PREVIEW_LAYOUT as CONTACT_PREVIEW_LAYOUT } from './layouts/ContactLayouts';
import { PREVIEW_LAYOUT as POSTCARD_PREVIEW_LAYOUT } from './layouts/PostcardLayouts';

const POSTCARD_IMAGE_RATIO = 169 / 235;

function App() {
    const navigate = useNavigate();
    const client = useMojipostClient();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const editPostcard = record => navigate(`/postcards/${record.id}/edit`);
    const [searchParams, setSearchParams] = useSearchParams();
    const setSelection = useCallback(value => {
        if (value === undefined) {
            value = '';
        } else if (Array.isArray(value)) {
            value = value.join(',');
        }
        setSearchParams(new URLSearchParams({ value }));
    }, [setSearchParams]);
    const params = Object.fromEntries(searchParams);
    const multiple = false; //useMatch('addresses');
    if (multiple) {
        params.value = (params.value && params.value.split(',').filter(Boolean)) || [];
    } else if (params.value === '') {
        params.value = undefined;
    }
    const editPostcard = useCallback(
        (id) => {
            navigate(`/postcards/${id}/edit`, {
                replace: false,
            })
        }, [navigate]);
    const hasSelection = Boolean(
        Array.isArray(params.value)
            ? params.value.length
            : params.value
    );
    const isEditPostcardPage = useMatch('postcards/:id/edit');
    const disableLeftChildren = isEditPostcardPage;

    const previewPanel = hasSelection && (
        <Routes>
            <Route path="postcards" element={(
                <AppPreviewPanel
                    id={params.value}
                    onGet={client.getPostcard}
                    onUpdate={client.patchPostcard}
                    onDescribe={client.describePostcard}
                    addListener={client.addPostcardListener}
                    removeListener={client.removePostcardListener}
                    imageMap={postcard => ([{
                        key: 'front',
                        title: 'Front',
                        src: postcard?.preview?.front?.large,
                        sx: {
                            width: '100%',
                            height: '200px',
                        }
                    }, {
                        key: 'back',
                        title: 'Back',
                        src: postcard?.preview?.back?.large,
                        sx: {
                            width: '100%',
                            height: '200px',
                        }
                    }])}
                    layout={POSTCARD_PREVIEW_LAYOUT}
                />
            )} />
            <Route path="contacts" element={(
                <AppPreviewPanel
                    id={params.value}
                    onGet={client.getAddress}
                    onUpdate={client.patchAddress}
                    onDescribe={client.describeAddress}
                    addListener={client.addAddressListener}
                    removeListener={client.removeAddressListener}
                    layout={CONTACT_PREVIEW_LAYOUT}
                    FieldProps={{
                        variant: "subtitle:typography"
                    }}
                    onSend={() => { }}
                    previewChildren={({ initialValue }) => (
                        <Map style={{ width: '100%', height: '300px' }}>
                            <Marker center address={initialValue} />
                        </Map>
                    )}
                />
            )} />
            <Route path="prints" element={(
                <AppPreviewPanel
                    id={params.value}
                    onGet={client.getPostcardPrint}
                    Fields={PostcardPrintDetail}
                    imageMap={postcard => ([{
                        key: 'front',
                        title: 'Front',
                        src: postcard?.preview?.front?.large,
                        sx: {
                            width: '100%',
                            height: '200px',
                        }
                    }, {
                        key: 'back',
                        title: 'Back',
                        src: postcard?.preview?.back?.large,
                        sx: {
                            width: '100%',
                            height: '200px',
                        }
                    }])}
                />
            )} />
        </Routes>
    );

    return (
        <Authenticated>
            <AppFrame
                appName="MojiPost"
                rightChildren={!mobile && previewPanel}
                toolbarRight={(
                    <UserMenu />
                )}
                disableLeftChildren={disableLeftChildren}
                leftChildren={(
                    <AppMenu />
                )}>
                <Routes>
                    <Route path="/">
                        <Route index element={<Navigate to="/postcards" />} />
                        <Route path="postcards/:id/edit" element={(
                            <PostCardEditor />
                        )} />
                        <Route path="/postcards/new" element={<Navigate replace to="/postcards/new/edit" />} />
                        <Route path="/postcards" element={(
                            <PersistentSetManager
                                key="Postcards"
                                title="Postcards"
                                onGet={client.getPostcards}
                                onEdit={editPostcard}
                                onChange={setSelection}
                                value={params.value}
                                cardGridPropertyMapper={record => ({
                                    title: record.name,
                                    image: record.preview.back.medium,
                                    imageRatio: POSTCARD_IMAGE_RATIO
                                })} />
                        )} />
                        <Route path="/contacts" element={(
                            <PersistentSetManager
                                key="Contacts"
                                title="Contacts"
                                onChange={setSelection}
                                value={params.value}
                                onGet={client.getAddresses}
                                onDelete={client.deleteAddress}
                                cardGridPropertyMapper={record => ({
                                    title: record.name,
                                    subTitle: (
                                        <>
                                            {record.addressLine1} <br />
                                            {record.city} {record.state}, {record.zip}
                                        </>
                                    )
                                })}
                            />
                        )} />
                        <Route path="/prints" element={(
                            <PersistentSetManager
                                key="Prints"
                                title="Prints"
                                onGet={client.getPostcardPrints}
                                onChange={setSelection}
                                value={params.value}
                                cardGridPropertyMapper={record => ({
                                    title: record.finalToAddress.name,
                                    image: record.preview.front.medium,
                                    imageRatio: POSTCARD_IMAGE_RATIO
                                })}
                            />
                        )} />
                        <Route path="/settings/account" element={(
                            <AccountDetail />
                        )} />
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                {mobile && hasSelection && (
                    <MobileDrawer
                        plateChildren={(
                            <Routes>
                                <Route path="/postcards" element={(
                                    <PostcardPlate
                                        recordId={params.value}
                                        onGet={client.getPostcard}
                                        onDescribe={client.describePostcard}
                                        addListener={client.addPostcardListener}
                                        removeListener={client.removePostcardListener}
                                    />
                                )}
                                />
                            </Routes>
                        )}
                    >
                        {previewPanel}
                    </MobileDrawer>
                )}
            </AppFrame>
        </Authenticated>
    );
}

export default App;

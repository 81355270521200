import styled from "@emotion/styled";
import { Button, Card, IconButton, Slider, Tooltip, Typography } from "@mui/material";
import Icon from "components/Icon";
import useThrottle from "hooks/useThrottle";
import { forwardRef, useMemo } from "react";
import { MAX_ZOOM, MIN_ZOOM } from "./hooks/useViewport";

const styles = ({ theme }) => ({
    // position: 'absolute',
    // transform: 'translate(-50%, 0px)',
    // marginLeft: '50%',
    bottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: '50px',
    overflow: 'initial',
    display: 'flex',
    alignItems: 'center',
    '& .slider': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '150px',
    },
    '& .zoom': {
        minWidth: '32px',
    },
    "& .rotateButton": {
        borderRadius: '100px',
        margin: theme.spacing(1)
    }
});

function ViewportToolbar(props, ref) {
    const {
        className,
        viewport,
        updatePage,
        describePage,
        page,
    } = props;
    const {
        // viewportMatrix,
        preview,
        togglePreview,
        guides,
        toggleGuides,
        fitCanvasViewport,
        resetViewport,
    } = viewport;
    const onChangeZoom = event => viewport.updateCanvasZoom(event.target.value);
    const { callback: onChangeZoomThrottled } = useThrottle(onChangeZoom, { throttle: 100 })
    const guidesIcon = guides ? 'guidesOn' : 'guidesOff';
    const guidesTitle = guides ? 'Remove Guides' : 'Display Guides';
    const previewIcon = preview ? 'previewModeOn' : 'previewModeOff';
    const previewTitle = preview ? 'Exit Preview Mode' : 'Enter Preview Mode';
    const orientationIcon = page.orientation?.startsWith('portrait') ? 'portraitView' : 'landscapeView';
    const orientationChoiceMap = useMemo(() => {
        const { choices } = describePage('orientation');
        const map = {};
        choices?.forEach(choice => map[choice.value] = choice.label);
        return map;
    }, [describePage]);
    const toggleOrientation = () => updatePage('orientation', currentOrientation => {
        const orientationDescription = describePage('orientation');
        let currentChoiceFound = false;
        let newChoice = orientationDescription.choices.find(choice => {
            if (choice.value === currentOrientation) {
                currentChoiceFound = true;
                return false;
            }
            return currentChoiceFound;
        });
        if (!newChoice) {
            newChoice = orientationDescription.choices[0];
        }
        return newChoice.value;
    });
    return (
        <Card className={className} ref={ref}>
            <Typography className="label" variant="caption">Zoom</Typography>
            <Slider
                size="small"
                className="slider"
                value={viewport.zoom}
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                valueLabelDisplay="auto"
                valueLabelFormat={value => `${Math.floor(100 * value)}%`}
                onChange={onChangeZoomThrottled}
                step={0.05}
            />
            <Typography className="zoom" variant="caption">{`${Math.floor(100 * viewport.zoom)}%`}</Typography>
            <Tooltip
                title={guidesTitle}
                placement="top"
                arrow
            >
                <IconButton
                    onClick={toggleGuides}
                >
                    <Icon name={guidesIcon} />
                </IconButton>
            </Tooltip>
            <Tooltip
                title="Fit Canvas to Viewport"
                placement="top"
                arrow
            >
                <IconButton
                    onClick={fitCanvasViewport}
                >
                    <Icon name="fitCanvasToViewport" />
                </IconButton>
            </Tooltip>
            <Tooltip
                title={previewTitle}
                placement="top"
                arrow
            >
                <IconButton
                    onClick={togglePreview}
                >
                    <Icon name={previewIcon} />
                </IconButton>
            </Tooltip>
            <Tooltip
                title="Reset Viewport"
                placement="top"
                arrow
            >
                <IconButton
                    onClick={resetViewport}
                >
                    <Icon name="resetViewport" />
                </IconButton>
            </Tooltip>
            <Tooltip
                title="Change Canvas Orientation"
                placement="top"
                arrow
            >
                <Button
                    className="rotateButton"
                    variant="outlined"
                    startIcon={<Icon name={orientationIcon} />}
                    size="small"
                    onClick={toggleOrientation}>
                    {orientationChoiceMap?.[page.orientation]}
                </Button>
            </Tooltip>
        </Card>
    )
}

export default styled(forwardRef(ViewportToolbar))(styles);

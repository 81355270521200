import MyAccountIcon from '@mui/icons-material/AccountCircleRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import SignOutIcon from '@mui/icons-material/LogoutRounded';
import PaymentMethodsIcon from '@mui/icons-material/PaymentRounded';
import OrderHistoryIcon from '@mui/icons-material/ReceiptLongRounded';
import { Button, Card, Divider, Fade, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Modal, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserTag from './UserTag';


const styles = ({ theme }) => ({
    padding: theme.spacing(2),
    display: 'block',
    '& .container': {
        position: 'relative',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 400,
            float: 'right',
        },

        // list Item button rounded right
        '& .MuiListItem-button': {
            marginRight: theme.spacing(1),
            borderRadius: '0px 25px 25px 0px',
            transition: theme.transitions.create('all'),
            boxShadow: theme.shadows[0],
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(1),
        }
    },
    '& > *:focus': {
        outlineWidth: 0,
    },
    '& .footMenu': {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1)
    },
    "& .header": {
        boxShadow: theme.shadows[0],
        backgroundColor: 'initial',
        padding: theme.spacing(.5),
    },
    '& .headerToolbar': {
        flexDirection: 'row-reverse',
        minHeight: 'initial',
    },
    '& .closeButton': {
        position: 'absolute',
        top: theme.spacing(.5),
        right: theme.spacing(.5),
    },
});


function AccountPopover({ className, open, onClose }) {
    const location = useLocation();
    const itemProps = path => {
        return {
            selected: location.pathname.indexOf(path) >= 0,
            component: Link,
            to: path,
            onClick: onClose
        };
    }
    return (
        <Modal
            closeAfterTransition
            className={className}
            open={open}
            onClose={onClose}
            slots={{
                // Backdrop: Backdrop
            }}
            slotProps={{
                backdrop: {
                    timeout: 750,
                }
            }}
        >
            <Fade in={open}>
                <div>
                    <Slide in={open} direction="down">
                        <Card className="container">
                            <UserTag avatar profile firstName lastName avatarSize={200} />
                            <IconButton className="closeButton" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                            <Divider />
                            <List disablePadding>
                                <ListItemButton {...itemProps('/settings/account')}>
                                    <ListItemIcon>
                                        <MyAccountIcon />
                                    </ListItemIcon>
                                    <ListItemText>My Account</ListItemText>
                                </ListItemButton>
                                <ListItemButton {...itemProps('/settings/payment-methods')}>
                                    <ListItemIcon>
                                        <PaymentMethodsIcon />
                                    </ListItemIcon>
                                    <ListItemText>Payment Methods</ListItemText>
                                </ListItemButton>
                                <ListItemButton {...itemProps('/settings/order-history')}>
                                    <ListItemIcon>
                                        <OrderHistoryIcon />
                                    </ListItemIcon>
                                    <ListItemText>Order History</ListItemText>
                                </ListItemButton>
                                <ListItemButton {...itemProps('/logout')}>
                                    <ListItemIcon>
                                        <SignOutIcon />
                                    </ListItemIcon>
                                    <ListItemText>Sign Out</ListItemText>
                                </ListItemButton>
                            </List>
                            <Divider />
                            <div className="footMenu">
                                <Button
                                    size="small"
                                    component={Link}
                                    to="/settings/privacy-policy"
                                    onClick={onClose}>
                                    Privacy Policy
                                </Button>
                                <Button
                                    size="small"
                                    component={Link}
                                    to="/settings/terms-of-service"
                                    onClick={onClose}>
                                    Terms of Service
                                </Button>
                            </div>
                        </Card>
                    </Slide>
                </div>
            </Fade>
        </Modal>
    );
}

export default styled(AccountPopover)(styles);

import { useMojipostClient } from '../connectors/MojipostClient';
import useAsyncCallback from "./useAsyncCallback";
export default function useUserAccount() {
    const client = useMojipostClient();
    const {
        result, error, loading
    } = useAsyncCallback(client.getUserAccount, { autoCall: true });

    // console.log('PROVIDING', result, 'as account');
    return {
        account: result || {},
        error,
        loading
    };
}

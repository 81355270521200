import { useTheme } from '@emotion/react';
import { Box, IconButton, useMediaQuery, Zoom } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import clsx from 'clsx';
import useToggle from 'hooks/useToggle';
import { forwardRef, useRef } from 'react';
import Icon from './Icon';

const SearchContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    // borderRadius: theme.shape.borderRadius,
    borderRadius: '50px',
    backgroundColor: theme.palette.mode === 'light'
        ? alpha(theme.palette.common.black, 0.15)
        : alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: theme.palette.mode === 'light'
            ? alpha(theme.palette.common.black, 0.25)
            : alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0px',
    left: '0px',
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0px',
    right: '0px',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    '&.clearable .MuiInputBase-input': {
        paddingRight: `calc(1em + ${theme.spacing(4)})`,
    }
}));
const MobileSearchContainer = styled(
    forwardRef(
        ({ className, open, ...props }, ref) => (
            <Box className={clsx(className, { open })} {...props} ref={ref} />
        )
    )
)(({ theme }) => ({
    display: 'flex',
    '&.open': {
        transition: theme.transitions.create('all'),
        ...theme.mixins.toolbar,
        position: 'absolute',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        top: 0,
        right: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        height: '100%',
        alignItems: 'center',
        '.closeButton': {
            width: 40,
            height: 40,
            margin: theme.spacing(1)
        }
    },
    '& .closeButton': {
        // color: 'inherit',
        transition: theme.transitions.create('all'),
        width: 0,
        height: 0,
        overflow: 'hidden',
        display: 'block',
        padding: 0,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '& .searchIcon': {
        color: 'inherit'
    }
}));

const MobileSearchInput = styled(
    forwardRef(
        ({ className, open, ...props }, ref) => (
            <InputBase className={clsx(className, { open })} {...props} ref={ref} />
        )
    )
)(({ theme }) => ({
    width: 0,
    color: 'inherit',
    fontSize: '24px',
    '&.open': {
        width: '100%',
    }
}));

function Search(props, ref) {
    const {
        style,
        ...forwardProps
    } = props;
    const hasValue = Boolean(props.value);
    const inputClass = clsx(props.className, {
        clearable: hasValue,
    });
    const clearInput = () => props.onChange({
        target: {
            name: props.name,
            value: '',
        }
    });
    const { state: mobileSearchOpen, toggleOn: openMobileSearch, toggleOff: closeMobileSearch } = useToggle(false);
    const mobileSearchInputRef = useRef();
    const toggleMobileSearchOpen = () => {
        openMobileSearch();
        mobileSearchInputRef.current.focus();
    };
    const toggleMobileSearchClose = () => {
        closeMobileSearch();
        clearInput();
    };
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    return mobile
        ? (
            <MobileSearchContainer open={mobileSearchOpen} ref={ref} style={style}>
                <IconButton className="searchIcon" onClick={toggleMobileSearchOpen}>
                    <Icon name="search" />
                </IconButton>
                <MobileSearchInput
                    open={mobileSearchOpen}
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search', ref: mobileSearchInputRef }}
                    {...forwardProps}
                />
                <IconButton className="closeButton" onClick={toggleMobileSearchClose}>
                    <Icon name="close" />
                </IconButton>
            </MobileSearchContainer>
        )
        : (
            <SearchContainer ref={ref} style={style}>
                <SearchIconWrapper>
                    <Icon name="search" />
                </SearchIconWrapper >
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    {...forwardProps}
                    className={inputClass}
                />
                <Zoom in={hasValue}>
                    <CloseIconButton size="small" onClick={clearInput}>
                        <Icon name="clear" />
                    </CloseIconButton>
                </Zoom>
            </SearchContainer >
        );
}

export default forwardRef(Search);

import { useEffect, useState } from 'react';

const scriptLoader = {
    scripts: {},
    isScriptLoaded(script) {
        return Boolean(this.getScriptLoadState(script));
    },
    getScriptLoadState(script) {
        const { [script]: scriptLoadState = false } = this.scripts;
        return scriptLoadState;
    },
    loadScript(script, callback) {
        const loadState = this.getScriptLoadState(script);
        if (loadState instanceof Promise) {
            loadState.then(callback);
        } else {
            this.scripts[script] = new Promise((resolve, reject) => {
                const scriptTag = document.createElement('script');
                scriptTag.src = script;
                scriptTag.setAttribute('async', true);
                scriptTag.onload = (...args) => {
                    console.log(args);
                    this.scripts[script] = true;
                    callback()
                    resolve();
                };
                scriptTag.onerror = () => {
                    // TODO launch some kind of error... not just forever load or something
                    reject();
                };
                document.body.appendChild(scriptTag);
            });
        }
    }
}
export default function useScript(script, readyToLoad = true) {
    // getting if the script is loaded
    const scriptLoadState = Boolean(scriptLoader.isScriptLoaded());
    // possible values for scriptLoadState: (true, false, Promise)
    const [loaded, setLoaded] = useState(scriptLoadState);

    useEffect(() => {
        if (readyToLoad) {
            scriptLoader.loadScript(script, () => setLoaded(true));
        }
    }, [script, readyToLoad]);

    return loaded;
}

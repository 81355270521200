import DetailLayout from 'components/DetailLayout';
import { MODE_WRITE } from 'components/Field';
import React from 'react';


const POSITION_LAYOUT = [
    ['x', 'y'],
    ['width', 'height'],
    'rotation'
];

export const POSITION_FIELDS = POSITION_LAYOUT.flat();

export default function PositioningProperties(props) {
    const { className, describe, disabled, properties, layerErrors, onChange } = props;
    return (
        <DetailLayout
            className={className}
            layout={POSITION_LAYOUT}
            value={properties}
            error={layerErrors}
            onChange={onChange}
            disabled={disabled}
            describe={describe}
            xs={6}
            FieldProps={{
                variant: 'filled',
                size: 'small',
                mode: MODE_WRITE,
            }}
        />
    );
}

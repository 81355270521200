import { useGETState } from 'hooks/useQueryParams';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { useMojipostClient } from '../../../connectors/MojipostClient';
import usePersistentStateObject from '../../../hooks/usePersistentStateObject';
import useKeyboard from '../hooks/useKeyboard';
import useMouse, { PIXELS_PER_INCH } from '../hooks/useMouse';
import useViewport from '../hooks/useViewport';
import useCanvasSelection from './useCanvasSelection';
import { usePostcardFontLoader } from './useFontManager';
import useTouch from './useTouch';

const imageRegistry = {};
const EMPTY_LAYERS = [];


// const blockSaveRules = [
//     {
//         path: [['front', 'back'], 'layers.*.backgroundImage'],
//         callback({
//             path,
//             blockSave
//         }) {

//         }
//     }
// ]

const diffRules = [
    {
        // this listener prevents the
        path: [['front', 'back'], 'layers.*'],
        callback({
            newValue: layer,
            rejectState,
        }) {
            const collapsedBorders = layer.borderWidth * 2;
            if (layer.height < collapsedBorders) {
                rejectState();
            }
            if (layer.width < collapsedBorders) {
                rejectState();
            }
        }
    },
    // {
    //     path: [['front', 'back'], 'layers.*.backgroundImage'],
    //     callback({
    //         path,
    //         newValue,
    //         rejectState
    //     }) {

    //     }

    // }
];

export default function usePostcardEditor(id) {
    const client = useMojipostClient();
    const navigate = useNavigate();
    const createPostCard = useCallback(
        data => client.postPostcard(data).then(
            data => {
                navigate(`/postcards/${data.id}/edit`, { replace: true });
                return data;
            }
        ),
        [navigate, client]
    );
    const {
        state,
        updateState,
        loadState,
        describe: describePostcard,
        fieldErrors,
    } = usePersistentStateObject({
        diffListeners: diffRules,
        id,
        autoSave: true,
        autoSaveReconcile: ({ savedState, currentState }) => ({
            ...currentState,
            preview: savedState.preview
        }),
        onGet: client.getPostcard,
        onUpdate: client.patchPostcard,
        onCreate: createPostCard,
        onDescribe: client.describePostcard,
        defaultFormState: {
            name: "New Postcard",
            description: "",
            front: {
                orientation: 'landscape',
                layers: []
            },
            back: {
                orientation: 'landscape',
                layers: []
            },
        }
    });

    const {
        state: { page: currentPageIdentifier = 'back' },
        replaceState: replaceGETParams
    } = useGETState();
    const setCurrentPageIdentifier = useCallback(identifier => replaceGETParams('page', identifier), [replaceGETParams]);
    // const [currentPageIdentifier, setCurrentPageIdentifier] = useState('back');

    const updatePage = useCallback(
        (...update) => updateState(currentPageIdentifier, ...update),
        [updateState, currentPageIdentifier]
    );
    const updateLayers = useCallback(
        (...updates) => updatePage('layers', ...updates),
        [updatePage]
    );

    const page = state[currentPageIdentifier] || {};
    const pageErrors = fieldErrors?.[currentPageIdentifier] || {};
    const { layers = [] } = page;

    const viewport = useViewport({ orientation: page.orientation });
    const layerSelection = useCanvasSelection({
        viewport,
        updateLayers,
        layers,
        pageErrors,
        multiple: false
    });
    const { defaultPropertiesRef, selectionRef, clearSelection } = layerSelection;

    const focusFront = useCallback(() => {
        clearSelection();
        replaceGETParams('page', 'front');
    }, [replaceGETParams, clearSelection]);
    const focusBack = useCallback(() => {
        clearSelection();
        replaceGETParams('page', 'back');
    }, [replaceGETParams, clearSelection]);


    const describePage = useCallback(
        (...path) => describePostcard(currentPageIdentifier, ...path),
        [describePostcard, currentPageIdentifier]
    )
    const describeLayer = useCallback(
        (...path) => describePage('layers', selectionRef.current, ...path),
        [describePage, selectionRef]
    );

    const addLayer = useCallback(
        (...layersToAdd) => {
            let newIndexes = [];
            updateLayers(
                layers => {
                    const labelSet = new Set(layers.map(layer => layer.label));
                    let label, labelNumber = layers.length;
                    do label = `Layer ${++labelNumber}`;
                    while (labelSet.has(label));
                    for (let i = layers.length; i < layers.length + layersToAdd.length; i++) newIndexes.push(i);
                    return [
                        ...layers,
                        ...(layersToAdd.length
                            ? layersToAdd
                            : [{}]
                        ).map(layer => ({
                            ...defaultPropertiesRef.current,
                            ...layer,
                            label,
                        }))
                    ];
                }
            );
            return newIndexes;
        },
        [updateLayers, defaultPropertiesRef]
    );

    const keyboard = useKeyboard({ viewport, layerSelection });
    const {
        mouseEventHandlerProps,
        mouseState,
        debugPoints,
        debugVectors,
        previewProperties
    } = useMouse({
        addLayer,
        viewport,
        layerSelection,
        defaultPropertiesRef,
        debug: keyboard.debugMode
    });
    const {
        touchEventHandlerProps,
        touchState,
    } = useTouch({
        addLayer,
        viewport,
        layerSelection,
        defaultPropertiesRef,
    });
    const fonts = usePostcardFontLoader(state);

    const dropzone = useDropzone({
        noClick: true,
        accept: {
            'image/*': [],
        },
        onDrop: acceptedFiles => {
            const dateString = new Date().toLocaleString();
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const src = reader.result;
                    const image = new Image();
                    image.onload = () => {
                        const layerId = addLayer({
                            backgroundImage: src,
                            width: image.width / PIXELS_PER_INCH,
                            height: image.height / PIXELS_PER_INCH,
                            borderWidth: 0,
                            backgroundColor: 'rgba(0,0,0,0)',
                        });
                        client.postImage({ file, name: `Image (${dateString})` })
                            .then((result) => {
                                const imageLoader = new Image();
                                imageLoader.onload = function () {
                                    updateLayers(layerId, {
                                        backgroundImage: result.id,
                                    });
                                };
                                imageLoader.src = result.file;
                            });
                    };
                    image.src = src;
                };
                reader.readAsDataURL(file);
                // can we determin the width and height at this point?
                // TODO: add error handling
            });
        }
    });


    return {
        id,
        state,
        loadState,
        fieldErrors,
        pageErrors,

        mouseState,
        mouseEventHandlerProps,
        touchState,
        touchEventHandlerProps,
        keyboard,
        dropzone,

        currentPageIdentifier,
        setCurrentPageIdentifier,
        focusFront,
        focusBack,

        page,
        updatePage,
        describePage,

        layers,
        updateLayers,
        describeLayer,
        addLayer,
        layerSelection,

        viewport,
        fonts,
        debugPoints,
        debugVectors,
        previewProperties
    };
}

import { qrcode as qr } from '@akamfoad/qr';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, styled, useMediaQuery, useTheme } from '@mui/material';
import Icon from 'components/Icon';
import useImageLoader from 'hooks/useImageSource/useImageSource';
import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { useMojipostClient } from '../connectors/MojipostClient';
import usePersistentStateObject, { POST_SAVE_BAHAVIOR_CLEAR } from '../hooks/usePersistentStateObject';
import DetailLayout from './DetailLayout';
import { MODE_WRITE } from './Field';
import { PIXELS_PER_INCH } from './PostcardEditor/hooks/useMouse';


const QRCODE_LAYOUT = [
    {
        name: 'name',
        label: 'Url'
    },
    'description'
];

const styles = ({ theme }) => ({
    '& .preview': {
        backgroundColor: 'black',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundOrigin: 'content-box',
        padding: theme.spacing(1),
        backgroundRepeat: 'no-repeat',
        minWidth: '5in',
        maxWidth: '100%',
        minHeight: '5in',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    '& .contentContainer': {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    }
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function QRCodeDialog(props) {
    const {
        title,
        onClose,
        onImage,
        ...forwardProps
    } = props;

    const client = useMojipostClient();

    const imageRef = useRef();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const onSave = useCallback((image) => {
        onImage && onImage(image, imageRef.current);
        onClose && onClose();
    }, [onImage, onClose]);

    const {
        state,
        loading,
        updateState,
        setState,
        isDirty,
        resetState,
        save,
        saving,
        describe,
        fieldErrors,
        resetStatePath,
        cancel,
    } = usePersistentStateObject({
        onSave,
        onCreate: client.postImage,
        onDescribe: client.describeImage,
        postSaveBehavior: POST_SAVE_BAHAVIOR_CLEAR
    });

    const cancelDialog = () => {
        cancel();
        onClose && onClose();
    };

    const disableForm = loading || saving;

    const { name: qrCodeTarget } = state || {};

    useEffect(() => {
        if (qrCodeTarget) {
            const qrcode = qr(qrCodeTarget);
            const width = Math.floor(2.5 * PIXELS_PER_INCH);
            const height = Math.floor(2.5 * PIXELS_PER_INCH);
            // const margin = 8;

            var canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            var ctx = canvas.getContext('2d');

            // add the white background
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, width, height);


            var cells = qrcode.modules;

            var tileW = width / cells.length;
            var tileH = height / cells.length;

            for (var r = 0; r < cells.length; ++r) {
                var row = cells[r];
                for (var c = 0; c < row.length; ++c) {

                    ctx.fillStyle = row[c] ? '#fff' : '#000';
                    // TODO: this math looks sus....
                    let x = Math.round(c * tileW);
                    let y = Math.round(r * tileH);
                    var w = Math.ceil((c + 1) * tileW) - Math.floor(c * tileW);
                    var h = Math.ceil((r + 1) * tileH) - Math.floor(r * tileH);
                    ctx.beginPath();
                    ctx.roundRect(x, y, w, h, [0]);
                    ctx.fill();
                    // ctx.fillRect(x, , w, h);
                }
            }
            canvas.toBlob(
                (blob) => setState('file', new File(
                    [blob], "QRCode.jpg", { type: "image/jpeg" }
                )),
                "image/jpeg",
                0.75,
            );
        }
    }, [qrCodeTarget, setState]);

    const { url, image, loading: imageLoading, error } = useImageLoader(state.file);
    imageRef.current = image;
    return (
        <Dialog
            maxWidth="lg"
            fullScreen={mobile}
            TransitionComponent={Transition}
            {...forwardProps}
            onClose={cancelDialog}
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                <Icon name="addQRCode" />
                {title}
            </DialogTitle>
            <Grid container className="contentContainer">
                <Grid item sm={7} className="preview" sx={({ backgroundImage: `url(${url})` })}>

                </Grid>
                <Grid item sm={5}>
                    <DialogContent sx={{ overflow: 'visible' }}>
                        {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
                        <DetailLayout
                            layout={QRCODE_LAYOUT}
                            value={state}
                            onChange={updateState}
                            isDirty={isDirty}
                            onUndo={resetStatePath}
                            describe={describe}
                            disabled={disableForm}
                            error={fieldErrors}
                            FieldProps={{
                                mode: MODE_WRITE,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={disableForm}
                            color="primary"
                            variant="outlined"
                            onClick={cancelDialog}
                        >Cancel</Button>
                        <div style={{ flexGrow: 1 }} />
                        <Button
                            disabled={disableForm}
                            color="primary"
                            variant="contained"
                            onClick={save}
                        >Save</Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default styled(QRCodeDialog)(styles);

import {
    AccordionActions,
    AccordionDetails,
    Alert,
    Box, IconButton, List, ListItem, ListItemButton,
    Skeleton,
    Tab, Tabs,
    Tooltip
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Field from "components/Field";
import Icon from "components/Icon";
import { MODE_UPDATE } from "hooks/usePersistentStateObject";
import { useCallback, useEffect, useRef } from "react";


const styles = ({ theme }) => ({
    maxHeight: 300,
    overflow: 'auto',
    scrollbarColor: `${theme.palette.primary.light} ${theme.palette.divider}`,
    '& .type': {
        maxWidth: 100,
        marginRight: theme.spacing(1)
    },
    '&.topShadow': {
        background: 'linear-gradient(0deg, rgba(0,0,0,0) 96%, rgba(0,0,0,0.10) 99%, rgba(0,0,0,0.22) 100%)',
    },
    '&.bottomShadow': {
        background: 'linear-gradient(180deg, rgba(0,0,0,0) 96%, rgba(0,0,0,0.10) 99%, rgba(0,0,0,0.22) 100%)',
    },
    '&.topShadow.bottomShadow': {
        background: 'linear-gradient(180deg, rgba(0,0,0,0) 96%, rgba(0,0,0,0.10) 99%, rgba(0,0,0,0.22) 100%), linear-gradient(0deg, rgba(0,0,0,0) 96%, rgba(0,0,0,0.10) 99%, rgba(0,0,0,0.22) 100%)',
    }
});

function FilterProperties(props) {
    const {
        className,
        properties,
        describe,
        onChange,
        layerSelection,
        disabled
    } = props;
    const {
        filters = [],
    } = properties;
    const { hasSelection } = layerSelection;

    const addFilter = (_, type) => onChange(layer => ({
        filters: [...(layer.filters || []), { type }]
    }));

    const deleteFilter = filter => onChange(layer => ({
        filters: layer.filters.filter(item => item !== filter),
    }));


    const filterTypeDescription = describe('filters', 'new', 'type');
    const filterMap = {};
    filterTypeDescription?.choices?.forEach(item => filterMap[item.value] = item.label);

    const disableNewFilters = Boolean(disabled || !hasSelection);
    const displayfilters = Boolean(hasSelection);
    const encourageFilters = Boolean(displayfilters && filters.length === 0);

    // logic for scrolling to the bottom of the filters when a new filter is added
    const scrollContainerRef = useRef();
    const filterCountRef = useRef(filters.length);
    const filterCount = filters.length;
    useEffect(() => {
        if (filterCountRef.current !== filterCount) {
            if (filterCountRef.current < filterCount) {
                scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
            }
            filterCountRef.current = filterCount;
        }

    }, [scrollContainerRef, filterCountRef, filterCount]);

    const classes = [className];
    if (scrollContainerRef.current?.scrollTop > 0) {
        classes.push('topShadow');
    }
    if (
        scrollContainerRef.current &&
        scrollContainerRef.current?.scrollTop !== scrollContainerRef.current?.scrollHeight - scrollContainerRef.current?.offsetHeight
    ) {
        classes.push('bottomShadow');
    }
    const handleScroll = useCallback((event) => {
        if (scrollContainerRef.current.scrollTop > 0) {
            scrollContainerRef.current.classList.add('topShadow');
        } else {
            scrollContainerRef.current.classList.remove('topShadow');
        }
        if (scrollContainerRef.current.scrollTop !== scrollContainerRef.current.scrollHeight - scrollContainerRef.current.offsetHeight) {
            scrollContainerRef.current.classList.add('bottomShadow');
        } else {
            scrollContainerRef.current.classList.remove('bottomShadow');
        }
    }, [scrollContainerRef]);

    return (
        <>
            {displayfilters ? (
                <List className={classes.join(' ')} ref={scrollContainerRef} onScroll={handleScroll}>
                    {filters.map((filter, idx) => {
                        const filterDescription = describe('filters', idx);
                        if (!filterDescription) {
                            return (
                                <ListItemButton key={idx}>
                                    <Box sx={{ m: 1 }}>
                                        <Skeleton variant="circular" width={30} height={30} />
                                    </Box>
                                    <Skeleton variant="rounded" width="100%" height={30} sx={{ mr: 4 }} />
                                </ListItemButton>
                            )
                        }
                        return (
                            <ListItem
                                disablePadding
                                key={idx}
                            >
                                <ListItemButton disableRipple>
                                    <Tooltip arrow title={filterMap[filter.type]}>
                                        <Icon name={filter.type} />
                                    </Tooltip>
                                    <Field
                                        describe={describe}
                                        description={filterDescription.children.value}
                                        disabled={disabled}
                                        name={`filters.${idx}.value`}
                                        value={filter.value}
                                        variant="standard"
                                        mode={MODE_UPDATE}
                                        onChange={onChange}
                                        margin="none"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        sx={{ flexGrow: 1, mr: 1, ml: 2 }}
                                    />
                                    <IconButton
                                        disabled={disabled}
                                        onClick={() => deleteFilter(filter)}
                                    >
                                        <Tooltip title="Delete Filter" arrow>
                                            <Icon name="delete" />
                                        </Tooltip>
                                    </IconButton>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List >
            ) : (
                <AccordionDetails>
                    <Alert severity="info">Make a selection to manage filters.</Alert>
                </AccordionDetails>
            )}
            {encourageFilters && (
                <AccordionDetails>
                    <Alert severity="success">Try clicking the buttons below to add filters to your layer.</Alert>
                </AccordionDetails>
            )}
            <AccordionActions disableSpacing sx={{ p: 0 }}>
                <Tabs
                    visibleScrollbar
                    value='hidden'
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={addFilter}
                // aria-label="icon tabs example"
                // disablePadding
                >
                    {/* Small hack  */}
                    <Tab sx={{ width: '1px', padding: 0, minWidth: '1px' }} value="hidden" />
                    {filterTypeDescription?.choices?.map(choice => (
                        <Tab
                            disabled={disableNewFilters}
                            key={choice.value}
                            icon={<Icon name={choice.value} />}
                            aria-label={choice.label}
                            label={choice.label}
                            value={choice.value}
                        />
                    ))}
                </Tabs>
            </AccordionActions>
        </>
    );
}

export default styled(FilterProperties)(styles);

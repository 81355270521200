import { useEffect, useRef, useState } from "react";
const normalizeHtml = (str) => {
    return str && str.replace(/&nbsp;|\u202F|\u00A0/g, ' ').replace(/<br \/>/g, '<br>')
}
export default function ContentEditable({
    html,
    disabled = false,
    className = '',
    style = { height: 200 },
    innerRef,
    onChange,
    ...forwardProps
}) {
    const [content, setContent] = useState(html);
    const contentRef = useRef(null);
    const ref = typeof innerRef === 'string'
        ? undefined
        : innerRef || contentRef;

    useEffect(() => {
        const currentHtml = ref && ref?.current?.innerHTML
        if (normalizeHtml(html) !== normalizeHtml(currentHtml || '')) {
            setContent(html)
        }
    }, [ref, html]);

    const emitChange = event => {
        const newHtml = event.currentTarget.innerHTML
        if (html !== newHtml) {
            onChange(newHtml)
        }
    };

    return (
        <div
            className={className}
            style={style}
            ref={ref}
            onInput={emitChange}
            onBlur={emitChange}
            onKeyUp={emitChange}
            onKeyDown={emitChange}
            contentEditable={!disabled}
            dangerouslySetInnerHTML={{ __html: content }}
            {...forwardProps}
        />
    )
}

import { CardContent, Fade } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import usePersistentStateObject from '../hooks/usePersistentStateObject';
import DetailLayout from './DetailLayout';
import Image from './Image';

const styles = ({ theme }) => ({
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    flexDirection: 'column',
    flexGrow: 1,
    '& .error': {
        margin: theme.spacing(1),
    },
    '& .preview-image': {
        // padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
        }
    },
    '& .preview-image > *': {
        boxShadow: theme.shadows[1],
    },
    '& .preview-image > *:not(:last-child)': {
        borderBottom: '1px solid ' + theme.palette.divider,
        // marginBottom: theme.spacing(1),
        // [theme.breakpoints.down('sm')]: {
        //     marginBottom: theme.spacing(0),
        //     marginRight: theme.spacing(1),
        // }
    },
});

function AppPreviewPanel(props) {
    const {
        className,
        id,
        onGet,
        onDirtyChange,
        onUpdate,
        onDescribe,
        imageMap,
        previewChildren,
        layout,
        addListener,
        removeListener,
        FieldProps
    } = props;
    const viewId = id;
    const {
        initialValue,
        state,
        loading,
        loadError,
        fieldErrors,
        updateState,
        isDirty,
        resetStatePath,
        save,
        saving,
        reload,
        cancel,
        describe
    } = usePersistentStateObject({
        id: viewId,
        onGet,
        onUpdate,
        onDescribe,
        addListener,
        removeListener
    });
    const controls = {
        initialValue,
        state,
        fieldErrors,
        loading,
        // new props
        value: state,
        onChange: updateState,
        isDirty,
        describe,
        // description,
        disabled: saving,
        error: fieldErrors,
        FieldProps: {
            // margin: 'dense',
            ...FieldProps,
        },
        onUndo: resetStatePath,
    };
    const images = imageMap ? imageMap(state) : [];
    const previews = typeof previewChildren === 'function'
        ? previewChildren(controls)
        : previewChildren;
    const onDownloadPDF = undefined;

    // support for dirty events =)
    const recordIsDirty = isDirty();
    useEffect(() => {
        onDirtyChange && onDirtyChange(recordIsDirty);
    }, [recordIsDirty, onDirtyChange]);


    if (loadError) {
        return (
            <div className={className}>
                <Alert
                    className="error"
                    variant="filled"
                    severity="error"
                    action={(
                        <Button color="inherit" size="small" onClick={reload}>
                            Retry
                        </Button>
                    )}
                >
                    Failed to load record for preview.
                </Alert>
            </div>
        );
    }

    return (
        <form className={className} autoComplete="off">
            {/* what the heck is this thing here for? */}
            <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
            {/* <Toolbar sx={{ justifyContent: 'space-between' }}>
                <IconButton>
                    <Icon name="delete" />
                </IconButton>
                <IconButton>
                    <Icon name="downloadPDF" />
                </IconButton>
                <IconButton>
                    <Icon name="edit" />
                </IconButton>
            </Toolbar> */}
            {/* <Divider /> */}
            {previews}
            {images.length > 0 && (
                <div className="preview-image">
                    {images.map(image => (
                        <Image key={image.key} {...image} />
                    ))}
                </div>
            )}
            {/* <CardHeader
                title={state.name}
                titleTypographyProps={{
                    component: TypographyInput,
                    value: state.name,
                    name: 'name',
                    onChange: updateState,
                    error: Boolean(error),
                    helperText: error && error.name,
                    placeholder: 'Name',
                    dirty: isDirty('name'),
                    disabled: saving,
                }}
                subheader={state.description}
                subheaderTypographyProps={{
                    component: TypographyInput,
                    value: state.description,
                    name: 'description',
                    onChange: updateState,
                    error: Boolean(error),
                    dirty: isDirty('description'),
                    placeholder: 'Add Description',
                    multiline: true,
                    rows: 5,
                    disabled: saving,
                }} /> */}
            {/* <pre style={{ overflow: 'auto', flexGrow: 1 }}>{JSON.stringify({
                nameDirty: isDirty('name')
            }, null, 4)}</pre> */}
            {/* <Toolbar sx={{ justifyContent: 'space-between' }}>
                {onDelete && (
                    <IconButton onClick={onDelete}>
                        <DeleteIcon />
                    </IconButton>
                )}
                {onDownloadPDF && (
                    <IconButton onClick={onDownloadPDF}>
                        <DownloadPDFIcon />
                    </IconButton>
                )}
                {onEdit && (
                    <IconButton onEdit={onEdit}>
                        <EditIcon />
                    </IconButton>
                )}
                {onSend && (
                    <IconButton onClick={onSend}>
                        <SendIcon />
                    </IconButton>
                )}
            </Toolbar> */}
            {/* <Divider /> */}
            {/* {Fields && (
                <CardContent component="div">
                    <Fields {...controls} />
                    <DetailLayout {...controls} />
                </CardContent>
            )} */}
            {layout && (
                <CardContent component="div">
                    <DetailLayout
                        loading={loading}
                        layout={layout}
                        {...controls}
                    />
                </CardContent>
            )}

            <Fade in={recordIsDirty} unmountOnExit>
                <CardActions>
                    <Button disabled={saving} onClick={cancel}>Cancel</Button>
                    <div style={{ flexGrow: 1 }} />
                    <Button disabled={saving} variant="contained" onClick={save}>Save</Button>
                </CardActions>
            </Fade>
            <div style={{ flexGrow: 1 }} />
        </form>
    );
}

export default styled(AppPreviewPanel)(styles);

import Typography from '@mui/material/Typography';
import React from 'react';
import Date from './Date';

export default function PostcardPrintDetail(props) {
    const { state, error, onChange, isDirty, disabled } = props;

    const hasDeliveryDate = Boolean(state.dateDelivered);
    const hasEstimatedDelivery = Boolean(state.dateEsitmatedDelivery);
    const hasMailedDate = Boolean(state.dateMailed);


    return (
        <>
            <Typography variant="body2">
                <b>Date Created: </b>
                <Date value={state.dateCreated} />
            </Typography>
            <Typography variant="body2">
                <b>Date Updated: </b>
                <Date value={state.dateUpdated} />
            </Typography>
            {hasMailedDate && (
                <Typography variant="body2">
                    <b>Date Mailed: </b>
                    <Date value={state.dateMailed} />
                </Typography>
            )}
            {hasDeliveryDate && (
                <Typography variant="body2">
                    <b>Date Delivered: </b>
                    <Date value={state.dateDelivered} />
                </Typography>
            )}
            {hasEstimatedDelivery && (
                <Typography variant="body2">
                    <b>Estimated Delivery: </b>
                    <Date value={state.dateEstimatedDelivery} />
                </Typography>
            )}

        </>
    );
}

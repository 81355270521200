import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import React from 'react';

const styles = ({ theme, iconSize = 50 }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '0px',
    '& .MuiAlert-icon': {
        padding: '0px',
        margin: '0px',
    },
    '& .MuiAlert-icon svg': {
        width: iconSize,
        height: iconSize,
    }
});

function ErrorSplash(props) {
    const { className, onClick, children, ...forwardProps } = props;

    return (
        <Alert
            severity="error"
            variant="filled"
            className={className}
            onClick={onClick}
            {...forwardProps}>
            {children}
        </Alert>
    );
}

export default styled(ErrorSplash)(styles);

import React from 'react';

export default function RenderDate({ value, local = 'en-US' }) {
    // INTENTION: implement different variants that change formatting
    const date = new Date(value);
    return (
        <span title={date.toLocaleTimeString(local)}>
            {date.toLocaleDateString(local, { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' })}
        </span>
    );
};

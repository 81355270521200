import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { Link } from 'react-router-dom';

export const DEFAULT_GLOBAL_SEARCH_KEY = 'search';

const AppContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    background: theme.palette.background.default,
    '*': {
        scrollbarColor: `${theme.palette.primary.light} ${theme.palette.divider}`,
    },
    '[role=presentation]': {

    }
}));

const AppContent = styled('div')({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
});

const AppContentLeft = styled(AppContent)(({ theme }) => ({
    // borderRight: '1px solid ' + theme.palette.divider,
    flexGrow: 'initial',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    }
}));

const AppContentRight = styled(AppContent)(({ theme }) => ({
    borderLeft: '1px solid ' + theme.palette.divider,
    flexGrow: 'initial',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'inset ' + theme.shadows[2].replaceAll('),', '),inset '),
    transition: theme.transitions.create('all'),
    width: '0px',
    minWidth: '0px',
    maxWidth: '0px',
}));

const AppSlots = styled('div')(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
}));

const AppName = styled(Link)(({ theme, ...others }) => ({
    transition: theme.transitions.create('all'),
    textAlign: 'center',
    ...theme.typography.h6,
    textDecoration: 'none',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '&:visited': {
        color: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        width: 'initial !important',
        minWidth: 'initial !important',
        // flexGrow: 1,
    }
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    zIndex: theme.zIndex.appBar,
}));

const AppToolbar = styled(props => (
    <Toolbar disableGutters {...props} />
))(({ theme }) => ({
    paddingRight: theme.spacing(2)
}));


function AppFrame(props) {
    const {
        className = '',
        children,
        leftChildren,
        rightChildren,
        drawerToolbar,
        disableLeftChildren = false,
        disableRightChildren = false,
        disableAppName = false,
        appName = 'Application',
        sideBarWidth = 300,
        toolbarLeft,
        toolbarRight,
        // bannerChildren,
        // footerChildren,
        // globalSearchKey = DEFAULT_GLOBAL_SEARCH_KEY,
        ...forwardProps
    } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const calculatedSideBarWidth = rightChildren ? sideBarWidth : 0;
    const renderLeftChildren = !disableLeftChildren && leftChildren;
    const renderRightChildren = !disableRightChildren && rightChildren && !mobile;
    const renderAppName = !disableAppName;
    const renderRightMobileChildren = !disableRightChildren && rightChildren && mobile;



    // const loading = useLoaderState();
    // const { search, setSearch, objectTypes } = useSearch(globalSearchKey);


    // code for computing app bar height


    // // setting up styless
    // const classes = {}; //useStyles({ appBarHeight });
    // const rootClass = clsx(classes.root, className, {
    //     [classes.blur]: blur
    // });

    // return (
    //     <AppContainer className={className} {...forwardProps}>
    //         <AppBar ref={appBarRef}>
    //             <Toolbar disableGutters>
    //                 <Box sx={{ flexGrow: 1 }} />
    //             </Toolbar>
    //         </AppBar>
    //         <Box sx={{ mb: appBarHeight + 'px' }} />
    //         <AppSlots>
    //             <AppContent>
    //                 {children}
    //             </AppContent>
    //         </AppSlots>
    //     </AppContainer>
    // );

    return (
        <AppContainer className={className} {...forwardProps}>
            <AppBar position="static" enableColorOnDark>
                <AppToolbar>
                    {renderAppName && (
                        <AppName to="/" sx={{ width: sideBarWidth, minWidth: sideBarWidth }}>{appName}</AppName>
                    )}
                    {toolbarLeft}
                    {/* {toolbarLeft} */}
                    <Box sx={{ flexGrow: 1 }} />
                    {/* <Search
                        name={globalSearchKey}
                        value={search}
                        placeholder={`Search ${objectTypes.join(', ')}...`}
                        onChange={setSearch}
                    />*/}
                    {toolbarRight}
                </AppToolbar>
                {/* {loading && <LinearProgress />} */}
            </AppBar>
            {/* <Box sx={{ mb: appBarHeight + 'px' }} /> */}
            {/* {bannerChildren} */}
            <AppSlots>
                {renderLeftChildren && (
                    <AppContentLeft sx={{ width: sideBarWidth, minWidth: sideBarWidth, maxWidth: sideBarWidth }}>
                        {leftChildren}
                    </AppContentLeft>
                )}
                <AppContent>
                    {children}
                </AppContent>
                {renderRightChildren && (
                    <AppContentRight sx={{ width: calculatedSideBarWidth, minWidth: calculatedSideBarWidth, maxWidth: calculatedSideBarWidth }}>
                        {rightChildren}
                    </AppContentRight>
                )}

            </AppSlots>
            {/* {footerChildren} */}
        </AppContainer>
    );
}

export default AppFrame;

export default class Matrix {
    static from(matrix) {
        return new Matrix(matrix);
    }
    constructor(from) {
        from ? this.set(from) : this.reset();
    }
    reset() {
        return this.set({ a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 });
    }
    clear() {
        return this.set({ a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 });
    }
    set({
        a = this.a,
        b = this.b,
        c = this.c,
        d = this.d,
        e = this.e,
        f = this.f,
    }) {
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.e = e;
        this.f = f;
        return this;
    }
    dot(that) {
        /*
        [a1 c1 e1]   [a2 c2 e2]   [a1a2+c1b2 a1c2+c1d2 a1e2+c1f2+e1]
        [b1 d1 f1] x [b2 d2 f2] = [b1a2+d1b2 b1c2+d1d2 b1e2+d1f2+f1]
        [ 0  0  1]   [ 0  0  1]   [        0         0            1]
        */
        const {
            a: a1,
            b: b1,
            c: c1,
            d: d1,
            e: e1,
            f: f1,
        } = this;
        const {
            a: a2,
            b: b2,
            c: c2,
            d: d2,
            e: e2,
            f: f2,
        } = that;
        return this.set({
            a: a1 * a2 + c1 * b2,
            b: b1 * a2 + d1 * b2,
            c: a1 * c2 + c1 * d2,
            d: b1 * c2 + d1 * d2,
            e: a1 * e2 + c1 * f2 + e1,
            f: b1 * e2 + d1 * f2 + f1,
        });
    }
    translate(x, y) {
        return this.dot({ a: 1, b: 0, c: 0, d: 1, e: x, f: y });
    }
    clearTranslation() {
        // not tested
        return this.set({ e: 0, f: 0 });
    }
    scale(s) {
        return this.dot({ a: s, b: 0, c: 0, d: s, e: 0, f: 0 });
    }
    scaleAroundPoint(s, point) {
        return this.translate(point.x, point.y)
            .scale(s)
            .translate(-point.x, -point.y);
    }
    scaleNonUniform(x, y) {
        return this.dot({ a: x, b: 0, c: 0, d: y, e: 0, f: 0 });
    }
    scaleX(x) {
        return this.dot({ a: x, b: 0, c: 0, d: 1, e: 0, f: 0 });
    }
    scaleY(y) {
        return this.dot({ a: 1, b: 0, c: 0, d: y, e: 0, f: 0 });
    }
    rotate(a) {
        return this.dot({ a: Math.cos(a), b: Math.sin(a), c: -Math.sin(a), d: Math.cos(a), e: 0, f: 0 });
    }
    rotateAroundPoint(a, point) {
        return this.translate(point.x, point.y)
            .rotate(a)
            .translate(-point.x, -point.y);
    }
    rotateDeg(deg) {
        return this.rotate(deg * Math.PI / 180);
    }
    rotateAroundPointDeg(deg, point) {
        return this.translate(point.x, point.y)
            .rotateDeg(deg)
            .translate(-point.x, -point.y);
    }
    // skewX(a) {
    //     return this.dot({ a: 1, b: 0, c: Math.tan(a), d: 1, e: 0, f: 0 });
    // }
    // skewXDeg(deg) {
    //     return this.skewX(deg * Math.PI / 180);
    // }
    // skewY(a) {
    //     return this.dot({ a: 1, b: Math.tan(a), c: 0, d: 1, e: 0, f: 0 });
    // }
    // skewYDeg(deg) {
    //     return this.skewY(deg * Math.Pi / 180);
    // }
    inverse() {
        /**
         * [a c e]-1   [ d -c cf-ed]
         * [b d f]   = [-b  a eb-af]
         * [0 0 1]     [ 0  0 ad-cb]
         */
        const determinant = this.determinant();
        return this.set({
            a: this.d / determinant,
            b: -this.b / determinant,
            c: -this.c / determinant,
            d: this.a / determinant,
            e: (this.c * this.f - this.d * this.e) / determinant,
            f: (this.b * this.e - this.a * this.f) / determinant,
        });
    }
    determinant() {
        return this.a * this.d - this.c * this.b;
    }
    get css() {
        /*
        a c e
        b d f
        */
        return { transform: `matrix(${this.a}, ${this.b}, ${this.c}, ${this.d}, ${this.e}, ${this.f})` };
    }
    project = (point) => {
        const x = point.x;
        const y = point.y;
        point.x = this.a * x + this.c * y + this.e;
        point.y = this.b * x + this.d * y + this.f;
        return point;
    }
    log(name) {
        const { a, b, c, d, e, f } = this;
        console.log({ name, a, b, c, d, e, f });
        return this;
    }

    destructure() { //flipX = false, flipY = false) {
        // Remove flip from the matrix first - flip could be incorrectly interpreted as
        // rotations (e.g. flipX + flipY = rotate by 180 degrees).
        // Note flipX is a vertical flip, and flipY is a horizontal flip.
        // if (flipX) {
        //     if (flipY) {
        //         // matrix = compose(matrix, scale(-1, -1))
        //     } else {
        //         // matrix = compose(matrix, scale(1, -1))
        //     }
        // } else if (flipY) {
        //     // matrix = compose(matrix, scale(-1, 1))
        // }

        const { a, b, c, d, e, f } = this;
        let scaleX, scaleY, rotation;

        if (a !== 0 || c !== 0) {
            const hypotAc = Math.hypot(a, c)
            scaleX = hypotAc
            scaleY = (a * d - b * c) / hypotAc
            const acos = Math.acos(a / hypotAc)
            rotation = c > 0 ? -acos : acos
        } else if (b !== 0 || d !== 0) {
            const hypotBd = Math.hypot(b, d)
            scaleX = (a * d - b * c) / hypotBd
            scaleY = hypotBd
            const acos = Math.acos(b / hypotBd)
            rotation = Math.PI / 2 + (d > 0 ? -acos : acos)
        } else {
            scaleX = 0
            scaleY = 0
            rotation = 0
        }

        // put the flip factors back
        // if (flipY) {
        //     scaleX = -scaleX
        // }

        // if (flipX) {
        //     scaleY = -scaleY
        // }

        return {
            x: e, y: f,
            scaleX, scaleY,
            angle: rotation,
            angleDeg: rotation * 180 / Math.PI,
        }
    }
}

import styled from "@emotion/styled";
import { SwipeableDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import useToggle from "hooks/useToggle";

const SwipableContent = styled.div(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

function MobileDrawer(props) {
    const {
        plateHeight = 56,
        contentHeight = '66%',
        children,
        plateChildren,
        ...forwardProps
    } = props;

    const {
        state: open,
        toggleOn: toggleOpen,
        toggleOff: toggleClosed,
    } = useToggle();

    return (
        <SwipeableDrawer
            elevation={16}
            anchor="bottom"
            swipeAreaWidth={plateHeight}
            open={open}
            onClose={toggleClosed}
            onOpen={toggleOpen}
            // disableSwipeToOpen={false}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                '& > .MuiPaper-root': {
                    height: `calc(${contentHeight} - ${plateHeight}px)`,
                }
            }}
            {...forwardProps}
        >
            <SwipableContent className="plate" style={{
                top: -plateHeight,
                minHeight: plateHeight,
            }}>
                <Puller />
                {plateChildren}
            </SwipableContent>
            <SwipableContent className="content">
                {children}
            </SwipableContent>
        </SwipeableDrawer>
    );
}

export default styled(MobileDrawer)(({ theme }) => ({
    // backgroundColor: theme.palette.background.paper,
    '& .plate': {
        position: 'absolute',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        visibility: 'visible',
        right: 0,
        left: 0,
    },
    '&  .content': {
        flexGrow: 1,
        overflow: 'auto',
    },
    '& > .MuiPaper-root': {
        overflow: 'visible',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import { useMojipostClient } from 'connectors/MojipostClient';
import React from 'react';
import useAsyncCallback from '../hooks/useAsyncCallback';
import useUserAccount from '../hooks/useUserAccount';
import UserTag from './UserTag';



function Logout() {
    const client = useMojipostClient();
    const {
        account: {
            user: {
                firstName = '...',
                lastName = ''
            } = {}
        } = {}
    } = useUserAccount();

    const {
        loading,
        callback: logout
    } = useAsyncCallback(() => {
        return client.logout()
            .then(() => window.location.href = '/');
    });

    return (
        <Card>
            <CardHeader
                avatar={<UserTag avatar avatarSize={100} />}
                title={`Currently Signed in as ${firstName} ${lastName}`}
                subheader="Are you sure you want to Logout?" />
            <CardActions>
                <Button
                    disabled={loading}
                    variant="default"
                    onClick={() => window.history.back()}
                >Cancel</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    disabled={loading}
                    variant="contained"
                    onClick={logout}
                >Logout</Button>
            </CardActions>
        </Card>
    );
}

export default Logout;

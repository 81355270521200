import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Checkboard } from 'react-color/lib/components/common';

function extractRGBAValue(value) {
    const [
        r = 0,
        g = 0,
        b = 0,
        a = 1
    ] = value
        .replace('rgba(', '')
        .replace('rgb(', '')
        .replace(')', '')
        .split(/\s*?,\s*/);
    return { r, g, b, a };
}
function extractRGBADisplay(value) {
    const [
        r = 0,
        g = 0,
        b = 0,
        a = 1
    ] = value
        .replace('rgba(', '')
        .replace('rgb(', '')
        .replace(')', '')
        .split(/\s*?,\s*/);
    return a === 1
        ? `rgb(${r}, ${g}, ${b})`
        : `rgba(${r}, ${g}, ${b}, ${a})`;
}

function extractHSLAValue(value) {
    const [
        h = 0,
        s = 0,
        l = 0,
        a = 1
    ] = value
        .replace('hsla(', '')
        .replace('hsl(', '')
        .replace(')', '')
        .split(/\s*?,\s*/);
    return { h, s, l, a };
}

function extractHSLADisplay(value) {
    const [
        h = 0,
        s = 0,
        l = 0,
        a = 1
    ] = value
        .replace('hsla(', '')
        .replace('hsl(', '')
        .replace(')', '')
        .split(/\s*?,\s*/);
    return a === 1
        ? `hsl(${h}, ${s}, ${l})`
        : `hsla(${h}, ${s}, ${l}, ${a})`;
}

function rnd(value) {
    return Math.round(value, 3);
}

function ColorToggle(props) {
    const {
        className, value, size = 22, name, onChange, tooltip, disabled, children,
        anchorOrigin: anchorOriginOverride = {},
        transformOrigin: transformOriginOverride = {},
    } = props;
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'color-poper' : undefined;

    const handleClick = disabled
        ? null
        : (event) => {
            setAnchorEl(event.currentTarget);
            setOpen((previousOpen) => !previousOpen);
        };
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };
    const handleColorChange = (color) => {
        let value = color.hex;
        if (color.rgb && color.rgb.a !== 1) {
            value = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        }
        //     if (color.rgb.a !== 1) {
        //         value = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        //     } else {
        //         value = `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`;
        //     }
        // } else if (color.source === 'hsv') {
        //     if (color.hsl.a !== 1) {
        //         value = `hsla(${rnd(color.hsl.h)}, ${rnd(color.hsl.s * 100)}%, ${rnd(color.hsl.l * 100)}%, ${rnd(color.hsl.a)})`;
        //     } else {
        //         value = `hsl(${rnd(color.hsl.h)}, ${rnd(color.hsl.s * 100)}%, ${rnd(color.hsl.l * 100)}%)`;
        //     }
        // }

        onChange({
            [name]: value
        });
    };

    const color = value?.startsWith('#')
        ? { value, display: value }
        : value?.startsWith('rgb')
            ? { value: extractRGBAValue(value), display: extractRGBADisplay(value) }
            : value?.startsWith('hsl')
                ? { value: extractHSLAValue(value), display: extractHSLADisplay(value) }
                : { value: '#000000', display: '#000000' };

    return (
        <>
            <Box
                onClick={handleClick}
                aria-describedby={id}
                type="button"
                className={className}
                sx={{
                    position: 'relative',
                    // backgroundColor: theme.palette.getContrastText(value),
                    borderRadius: '4px',
                    overflow: 'hidden',
                    backgroundColor: 'white',
                    border: '2px solid ' + theme.palette.divider,
                    filter: disabled ? 'grayscale(1) opacity(.5)' : 'none',

                }}
            >
                <Checkboard />
                <Tooltip title={tooltip} placement="right" arrow>
                    <Box sx={{
                        position: 'relative',
                        backgroundColor: value,
                        width: size,
                        height: size,
                        padding: '2px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // color: 'black',
                        color: theme.palette.getContrastText(color.display),
                    }}>
                        {children}
                    </Box>
                </Tooltip>
            </Box>
            <Popover
                id={id}
                placement="right-start"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    ...anchorOriginOverride
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    ...transformOriginOverride
                }}

            >
                <ChromePicker
                    color={color.value}
                    onChange={handleColorChange}
                >Hello world</ChromePicker>
            </Popover>
        </>
    );
}

export default ColorToggle;

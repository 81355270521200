import {
    AddBoxOutlined,
    AddCircleOutline,
    AddPhotoAlternateOutlined,
    AddRounded,
    ArchitectureRounded,
    BlurOnRounded,
    BorderColorRounded,
    BorderStyleRounded,
    Brightness7Rounded,
    CenterFocusStrongRounded,
    CheckBoxOutlineBlankRounded,
    ClearRounded,
    CloseRounded,
    ContrastRounded,
    CropLandscapeRounded,
    CropPortraitRounded,
    DeleteRounded,
    EastRounded,
    EditRounded,
    ExposureRounded,
    FilterBAndWRounded,
    FilterVintage,
    FirstPageRounded,
    FontDownloadRounded,
    FormatAlignCenterRounded,
    FormatAlignLeftRounded,
    FormatAlignRightRounded,
    FormatBoldRounded,
    FormatColorFillRounded,
    FormatColorTextRounded,
    FormatShapesRounded,
    FormatSizeRounded,
    ImageRounded,
    InfoRounded,
    InvertColorsRounded,
    KeyboardArrowDownRounded,
    KeyboardArrowUpRounded,
    LayersRounded,
    LineStyleRounded,
    LineWeightRounded,
    LockOpenRounded,
    LockRounded,
    OpacityRounded,
    PaddingRounded,
    PanToolAltRounded,
    PhotoFilterRounded,
    PictureInPictureAltOutlined,
    PictureInPictureAltRounded,
    PreviewOutlined,
    PreviewRounded,
    QrCode2Rounded,
    RestartAltRounded,
    RoundedCornerRounded,
    SearchRounded,
    SendRounded,
    SettingsOverscanRounded,
    SouthRounded,
    SwapHorizRounded,
    SwapVertRounded,
    TextFieldsRounded,
    ThreeSixtyRounded,
    TitleRounded,
    UploadFileRounded,
    VerticalAlignBottomRounded,
    VerticalAlignCenterRounded,
    VerticalAlignTopRounded,
    VisibilityOffRounded,
    VisibilityRounded,
    WallpaperRounded
} from "@mui/icons-material";
import { forwardRef } from "react";


const iconMap = {
    locked: LockRounded,
    unlocked: LockOpenRounded,

    hidden: VisibilityOffRounded,
    visible: VisibilityRounded,

    positionX: EastRounded,
    positionY: SouthRounded,
    width: SwapHorizRounded,
    height: SwapVertRounded,
    text: TextFieldsRounded,
    padding: PaddingRounded,
    rotation: ArchitectureRounded,

    fillColor: FormatColorFillRounded,
    image: ImageRounded,

    borderColor: BorderColorRounded,
    borderRadius: RoundedCornerRounded,
    borderStyle: LineStyleRounded,
    borderWidth: LineWeightRounded,

    textAlignLeft: FormatAlignLeftRounded,
    textAlignCenter: FormatAlignCenterRounded,
    textAlignRight: FormatAlignRightRounded,

    verticalAlignTop: VerticalAlignTopRounded,
    verticalAlignMiddle: VerticalAlignCenterRounded,
    verticalAlignBottom: VerticalAlignBottomRounded,

    fontSize: FormatSizeRounded,
    fontWeight: FormatBoldRounded,
    fontFamily: FontDownloadRounded,
    textColor: FormatColorTextRounded,

    info: InfoRounded,
    edit: EditRounded,
    undo: RestartAltRounded,
    uploadImage: UploadFileRounded,
    notFound: CheckBoxOutlineBlankRounded,

    layerProperties: LayersRounded,
    positionProperties: FormatShapesRounded,
    fontProperties: FontDownloadRounded,
    borderProperties: BorderStyleRounded,
    filterProperties: PhotoFilterRounded,
    backgroundProperties: WallpaperRounded,
    delete: DeleteRounded,

    addQRCode: QrCode2Rounded,
    addImage: AddPhotoAlternateOutlined,
    addSquare: AddBoxOutlined,
    addCircle: AddCircleOutline,
    addText: TitleRounded,
    panTool: PanToolAltRounded,
    addFilter: AddRounded,

    //viewport
    guidesOn: PictureInPictureAltRounded,
    guidesOff: PictureInPictureAltOutlined,
    fitCanvasToViewport: SettingsOverscanRounded,
    previewModeOn: PreviewRounded,
    previewModeOff: PreviewOutlined,
    resetViewport: CenterFocusStrongRounded,
    portraitView: CropPortraitRounded,
    landscapeView: CropLandscapeRounded,

    // filters
    blur: BlurOnRounded,
    contrast: ContrastRounded,
    brightness: Brightness7Rounded,
    grayscale: FilterBAndWRounded,
    invert: InvertColorsRounded,
    opacity: OpacityRounded,
    sepia: FilterVintage,
    saturate: ExposureRounded,
    huerotate: ThreeSixtyRounded,
    bringForward: KeyboardArrowUpRounded,
    bringBackward: KeyboardArrowDownRounded,
    bringToFront: FirstPageRounded,
    bringToBack: FirstPageRounded,

    // other
    search: SearchRounded,
    clear: ClearRounded,
    close: CloseRounded,
    send: SendRounded,
};

const styling = {
    bringToFront: {
        rotate: '90deg',
    },
    bringToBack: {
        rotate: '-90deg',
    }
};

function Icon(props, ref) {
    const { name, style, ...forwardProps } = props;
    const Component = iconMap[name] || iconMap.notFound;
    const combinedStyle = {
        ...(style || {}),
        ...(styling?.[name] || {}),
    };
    forwardProps.style = {
        ...forwardProps.style,
        ...styling[name],
    };
    return (<Component ref={ref} style={combinedStyle} {...forwardProps} />);
}

export default forwardRef(Icon)

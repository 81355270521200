import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

// kinda dumb styled components don't forward refs
const styles = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    '& *': {
        margin: theme.spacing(1)
    }
});

const LoaderText = styled('span')(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightMedium,
    color: 'inherit',
}));

const InvisibleText = styled('span')(({ theme }) => ({
    visibility: "hidden"
}));

const LoaderCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
    // animationDuration: '550ms',
    [`& .${circularProgressClasses.circle}`]: {
        strokeLinecap: 'round',
    },
}));

function Loader(props, ref) {
    const {
        className,
        interval = 250,
        disableLoadingText = false,
        loadingText = '...',
        preText = 'Loading',
        size,
        color,
        sx,
        ...forwardProps
    } = props;
    const [counter, setCounter] = useState(0);
    const intervalRef = useRef();

    // handling counter state
    useEffect(() => {
        const updateCounter = () => setCounter(currentCount => currentCount + 1 > loadingText.length ? 0 : currentCount + 1);
        intervalRef.current = setInterval(updateCounter, interval);
        return () => clearInterval(intervalRef.current);
    }, [loadingText, interval, intervalRef]);

    return (
        <div className={className} sx={sx} {...forwardProps} ref={ref} >
            {Boolean(ref) && 'hasRef'}
            <LoaderCircularProgress
                size={size}
                color={color}
                thickness={4}
            />
            {loadingText !== false && !disableLoadingText && (
                <LoaderText>
                    <InvisibleText>{loadingText}</InvisibleText>
                    {preText}{loadingText.substr(0, counter)}
                    <InvisibleText>{loadingText.substr(counter, loadingText.length - counter)}</InvisibleText>
                </LoaderText>
            )}
        </div>
    );
}

export default styled(forwardRef(Loader))(styles);

import { styled } from '@mui/material/styles';
import ErrorSplash from 'components/ErrorSplash';
import Loader from 'components/Loader';
import useImageLoader from 'hooks/useImageSource/useImageSource';
import React, { forwardRef } from 'react';
const styles = ({ theme, disabled }) => ({
    filter: disabled ? 'grayscale(100%)' : 'none'
});

function Image(props, ref) {
    const {
        className,
        width,
        height,
        src,
        imageId,
        onClick,
        disabled,
        disableLoadingText = false,
        alt = '',
        ...forwardProps
    } = props;
    const imageLoadState = useImageLoader(
        src || imageId
    );
    const { url: loadedSrc, loading, error } = imageLoadState;

    if (error) {
        return (
            <ErrorSplash
                ref={ref}
                className={className}
                sx={{ width, height }}
                onClick={onClick}
            />
        );
    }


    if (loading || !Boolean(loadedSrc)) {
        return (
            <Loader
                ref={ref}
                className={className}
                sx={{ width, height, minHeight: height }}
                onClick={onClick}
                disableLoadingText={disableLoadingText}
            />
            // <pre>{src} {JSON.stringify(imageLoadState, null, 4)}</pre>
        );
    }


    // TODO: why are we not using loadedSRC?
    return (
        <img
            ref={ref}
            className={className}
            src={loadedSrc}
            height={height}
            width={width}
            onClick={onClick}
            alt={alt}
            style={{
                minWidth: width,
                minHeight: height,
            }}
            {...forwardProps}
        />
    );
}

export default styled(forwardRef(Image))(styles);

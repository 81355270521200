import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import DetailLayout from 'components/DetailLayout';
import { useMojipostClient } from 'connectors/MojipostClient';
import usePersistentStateObject, { MODE_UPDATE } from "hooks/usePersistentStateObject";
import { USER_ACCOUNT_LAYOUT } from 'layouts/AccountLayouts';

function AccountDetail(props) {
    const client = useMojipostClient();
    const {
        initialValue,
        state,
        loading,
        loadError,
        fieldErrors,
        updateState,
        isDirty,
        resetStatePath,
        save,
        saving,
        reload,
        cancel,
        description,
        resetState,
        describe
    } = usePersistentStateObject({
        noId: true,
        mode: MODE_UPDATE,
        onGet: client.getUserAccount,
        onUpdate: client.updateAccount,
        onDescribe: client.describeAccount,
        // onUpdate: client.
    });

    // div wrapper enables scroll on mobile
    return (
        <div>
            <Typography variant="h4" margin={2}>Account Information</Typography>
            <Card style={{ margin: 8, maxWidth: 600 }} component="form">
                <CardContent>
                    <DetailLayout
                        layout={USER_ACCOUNT_LAYOUT}
                        value={state}
                        onChange={updateState}
                        isDirty={isDirty}
                        describe={describe}
                        disabled={saving}
                        error={fieldErrors}
                        onUndo={resetStatePath}
                        loading={loading}
                    />
                </CardContent>
                {isDirty() && (
                    <CardActions>
                        <Button disabled={saving} onClick={cancel}>Cancel</Button>
                        <div style={{ flexGrow: 1 }} />
                        <Button disabled={saving} variant="contained" onClick={save}>Save</Button>
                    </CardActions>
                )}
            </Card>
        </div>
    );
}

export default AccountDetail;

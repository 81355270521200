import LockIcon from '@mui/icons-material/LockTwoTone';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { TOOL_TYPE_ADD_TEXT, TOOL_TYPE_POINTER } from './hooks/useCanvasSelection';

const GRABBY_SIZE = 14;
const GRABBY_MOBILE_SIZE = 24;
const GRABBY_BORDER_WIDTH = 2;
const GRABBY_HIGHLIGHT_WIDTH = 6;
const SELECTION_HIGHLIGHT_WIDTH = 3;
const MOBILE_SELECTION_HIGHLIGHT_WIDTH = 8;
const SELECTION_BORDER_WIDTH = 2;
const MOBILE_SELECTION_BORDER_WIDTH = 4;
const SPINNY_LINE_LENGTH = 30;
const MOBILE_SPINNY_LINE_LENGTH = 50;
const SPINNY_LINE_WIDTH = SELECTION_BORDER_WIDTH;
const MOBILE_SPINNY_LINE_WIDTH = MOBILE_SELECTION_BORDER_WIDTH;
const BORDER_RADIUS_LINE_LENGTH = 30;
const MOBILE_BORDER_RADIUS_LINE_LENGTH = 35;
const BORDER_RADIUS_LINE_WIDTH = SELECTION_BORDER_WIDTH;
const MOBILE_BORDER_RADIUS_LINE_WIDTH = MOBILE_SELECTION_BORDER_WIDTH;

const styles = ({ theme, layerSelection }) => ({
    overscrollBehavior: 'contain',
    '.border': {
        transition: 'inherit',
        position: 'absolute',
        outlineStyle: 'solid',
        outlineColor: theme.palette.primary.light,
        outlineWidth: '2px',
        borderColor: theme.palette.primary.dark,
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.dark,
    },
    '.grabbie': {
        overscrollBehavior: 'none',
        boxSizing: 'border-box',
        position: 'absolute',
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.dark,
        borderWidth: GRABBY_BORDER_WIDTH,
        borderStyle: 'solid',
        width: GRABBY_SIZE,
        height: GRABBY_SIZE,
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.dark,
            outlineColor: theme.palette.primary.main,
            outlineWidth: GRABBY_HIGHLIGHT_WIDTH,
            outlineStyle: 'solid',
        },
        cursor: 'grab',
        '.grabbing &': {
            cursor: 'grabbing',
        },
    },
    '.top-left': {
        top: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
        left: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
    },
    '.top-middle': {
        top: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
        left: `calc(50% - ${GRABBY_SIZE / 2}px)`,
    },
    '.top-right': {
        top: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
        right: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
    },
    '.middle-left': {
        top: `calc(50% - ${GRABBY_SIZE / 2}px)`,
        left: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
    },
    '.middle-right': {
        top: `calc(50% - ${GRABBY_SIZE / 2}px)`,
        right: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
    },
    '.bottom-left': {
        bottom: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
        left: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
    },
    '.bottom-middle': {
        bottom: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
        left: `calc(50% - ${GRABBY_SIZE / 2}px)`,
    },
    '.bottom-right': {
        bottom: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
        right: -GRABBY_SIZE + GRABBY_BORDER_WIDTH,
    },
    '.spinny': {
        top: -GRABBY_SIZE / 2 - SPINNY_LINE_LENGTH,
        left: `calc(50% - ${GRABBY_SIZE / 2}px)`,
    },
    '.spinny-line': {
        position: 'absolute',
        top: -SPINNY_LINE_LENGTH,
        left: `calc(50% - ${SPINNY_LINE_WIDTH / 2}px)`,
        height: SPINNY_LINE_LENGTH,
        borderWidth: 0,
        borderLeftStyle: 'solid',
        borderLeftWidth: SPINNY_LINE_WIDTH,
        borderLeftColor: theme.palette.primary.dark
    },
    '.border-radius': {
        top: -GRABBY_SIZE / 2 - BORDER_RADIUS_LINE_LENGTH,
        left: `calc(-${GRABBY_SIZE / 2}px + (${layerSelection.currentScale} * ${layerSelection.selectedLayers[0]?.borderRadius}in))`
    },
    '.border-radius-line': {
        position: 'absolute',
        top: -BORDER_RADIUS_LINE_LENGTH,
        left: `calc(-${BORDER_RADIUS_LINE_WIDTH / 2}px + (${layerSelection.currentScale} * ${layerSelection.selectedLayers[0]?.borderRadius}in))`,
        height: BORDER_RADIUS_LINE_LENGTH,
        borderWidth: 0,
        borderLeftStyle: 'solid',
        borderLeftWidth: BORDER_RADIUS_LINE_WIDTH,
        borderLeftColor: theme.palette.primary.dark,
    },
    '.anchor': {
        transition: 'inherit',
        position: 'absolute',
        display: 'flex',
        '&:has(input)': {
            backgroundColor: 'rgba(0,0,0,0.1)'
        }
    },
    '.top-border': {
        position: 'absolute',
        borderBottomColor: theme.palette.primary.dark,
        borderBottomWidth: SELECTION_BORDER_WIDTH,
        borderBottomStyle: 'solid',
        borderTopColor: theme.palette.primary.light,
        borderTopWidth: SELECTION_HIGHLIGHT_WIDTH,
        borderTopStyle: 'dashed',
        marginTop: -(SELECTION_BORDER_WIDTH + SELECTION_HIGHLIGHT_WIDTH),
        marginLeft: -SELECTION_BORDER_WIDTH,
        paddingLeft: SELECTION_BORDER_WIDTH * 2,
    },
    '.left-border': {
        position: 'absolute',
        borderRightColor: theme.palette.primary.dark,
        borderRightWidth: SELECTION_BORDER_WIDTH,
        borderRightStyle: 'solid',
        borderLeftColor: theme.palette.primary.light,
        borderLeftWidth: SELECTION_HIGHLIGHT_WIDTH,
        borderLeftStyle: 'dashed',
        marginLeft: -(SELECTION_BORDER_WIDTH + SELECTION_HIGHLIGHT_WIDTH),
        marginTop: -SELECTION_BORDER_WIDTH,
        paddingTop: SELECTION_BORDER_WIDTH * 2,
    },
    '.right-border': {
        position: 'absolute',
        borderRightColor: theme.palette.primary.light,
        borderRightWidth: SELECTION_HIGHLIGHT_WIDTH,
        borderRightStyle: 'dashed',
        borderLeftColor: theme.palette.primary.dark,
        borderLeftWidth: SELECTION_BORDER_WIDTH,
        borderLeftStyle: 'solid',
        marginTop: -SELECTION_BORDER_WIDTH,
        paddingTop: SELECTION_BORDER_WIDTH * 2,
    },
    '.bottom-border': {
        position: 'absolute',
        borderBottomColor: theme.palette.primary.light,
        borderBottomWidth: SELECTION_HIGHLIGHT_WIDTH,
        borderBottomStyle: 'dashed',
        borderTopColor: theme.palette.primary.dark,
        borderTopWidth: SELECTION_BORDER_WIDTH,
        borderTopStyle: 'solid',
        marginLeft: - SELECTION_BORDER_WIDTH,
        paddingLeft: SELECTION_BORDER_WIDTH * 2,
    },
    '.shade': {
        backgroundColor: theme.palette.action.focus,
    },
    [theme.breakpoints.down('sm')]: {
        '.grabbie': {
            borderWidth: 4,
            width: GRABBY_MOBILE_SIZE,
            height: GRABBY_MOBILE_SIZE,
            '&:hover': {
                outlineWidth: 6,
            },
        },
        '.top-border': {
            borderBottomWidth: MOBILE_SELECTION_BORDER_WIDTH,
            borderTopWidth: MOBILE_SELECTION_HIGHLIGHT_WIDTH,
            marginTop: -(MOBILE_SELECTION_BORDER_WIDTH + MOBILE_SELECTION_HIGHLIGHT_WIDTH),
            marginLeft: -MOBILE_SELECTION_BORDER_WIDTH,
            paddingLeft: MOBILE_SELECTION_BORDER_WIDTH * 2,
        },
        '.left-border': {
            borderRightWidth: MOBILE_SELECTION_BORDER_WIDTH,
            borderLeftWidth: MOBILE_SELECTION_HIGHLIGHT_WIDTH,
            marginLeft: -(MOBILE_SELECTION_BORDER_WIDTH + MOBILE_SELECTION_HIGHLIGHT_WIDTH),
            marginTop: -MOBILE_SELECTION_BORDER_WIDTH,
            paddingTop: MOBILE_SELECTION_BORDER_WIDTH * 2,
        },
        '.right-border': {
            borderRightWidth: MOBILE_SELECTION_HIGHLIGHT_WIDTH,
            borderLeftWidth: MOBILE_SELECTION_BORDER_WIDTH,
            marginTop: -MOBILE_SELECTION_BORDER_WIDTH,
            paddingTop: MOBILE_SELECTION_BORDER_WIDTH * 2,
        },
        '.bottom-border': {
            borderBottomWidth: MOBILE_SELECTION_HIGHLIGHT_WIDTH,
            borderTopWidth: MOBILE_SELECTION_BORDER_WIDTH,
            marginLeft: - MOBILE_SELECTION_BORDER_WIDTH,
            paddingLeft: MOBILE_SELECTION_BORDER_WIDTH * 2,
        },
        '.spinny': {
            top: -GRABBY_MOBILE_SIZE / 2 - MOBILE_SPINNY_LINE_LENGTH,
            left: `calc(50% - ${GRABBY_MOBILE_SIZE / 2}px)`,
        },
        '.spinny-line': {
            top: -MOBILE_SPINNY_LINE_LENGTH,
            left: `calc(50% - ${MOBILE_SPINNY_LINE_WIDTH / 2}px)`,
            height: MOBILE_SPINNY_LINE_LENGTH,
            borderLeftWidth: MOBILE_SPINNY_LINE_WIDTH,
        },
        '.border-radius': {
            top: -GRABBY_MOBILE_SIZE / 2 - MOBILE_BORDER_RADIUS_LINE_LENGTH,
            left: `calc(-${GRABBY_MOBILE_SIZE / 2}px + (${layerSelection.currentScale} * ${layerSelection.selectedLayers[0]?.borderRadius}in))`
        },
        '.border-radius-line': {
            top: -MOBILE_BORDER_RADIUS_LINE_LENGTH,
            left: `calc(-${MOBILE_BORDER_RADIUS_LINE_WIDTH / 2}px + (${layerSelection.currentScale} * ${layerSelection.selectedLayers[0]?.borderRadius}in))`,
            height: MOBILE_BORDER_RADIUS_LINE_LENGTH,
            borderLeftWidth: MOBILE_BORDER_RADIUS_LINE_WIDTH,
        },
        '.top-left': {
            top: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
            left: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
        },
        '.top-middle': {
            top: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
            left: `calc(50% - ${GRABBY_MOBILE_SIZE / 2}px)`,
        },
        '.top-right': {
            top: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
            right: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
        },
        '.middle-left': {
            top: `calc(50% - ${GRABBY_MOBILE_SIZE / 2}px)`,
            left: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
        },
        '.middle-right': {
            top: `calc(50% - ${GRABBY_MOBILE_SIZE / 2}px)`,
            right: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
        },
        '.bottom-left': {
            bottom: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
            left: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
        },
        '.bottom-middle': {
            bottom: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
            left: `calc(50% - ${GRABBY_MOBILE_SIZE / 2}px)`,
        },
        '.bottom-right': {
            bottom: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
            right: -GRABBY_MOBILE_SIZE + GRABBY_BORDER_WIDTH,
        },
    }
});

const lockOverlay = clsx('border', 'shade');
const topLeftGrabbie = clsx('grabbie', 'top-left');
const topMiddleGrabbie = clsx('grabbie', 'top-middle');
const topRightGrabbie = clsx('grabbie', 'top-right');
const middleLeftGrabbie = clsx('grabbie', 'middle-left');
const middleRightGrabbie = clsx('grabbie', 'middle-right');
const bottomLeftGrabbie = clsx('grabbie', 'bottom-left');
const bottomMiddleGrabbie = clsx('grabbie', 'bottom-middle');
const bottomRightGrabbie = clsx('grabbie', 'bottom-right');
const spinnyGrabbie = clsx('grabbie', 'spinny');
const borderRadiusGrabbie = clsx('grabbie', 'border-radius');

function SelectionOverlay(props) {
    const { className, layers, layerSelection, viewport, ...forwardProps } = props;

    if (!layerSelection.hasSelection) {
        return false;
    }

    const displayBorderRadiusGrabby = layerSelection.selectedLayers.length === 1;
    const showGrabbies = !layerSelection.locked && [TOOL_TYPE_ADD_TEXT, TOOL_TYPE_POINTER].includes(layerSelection.tool);
    const showLock = layerSelection.locked;

    const {
        height, width,
        top, left, transform,
    } = layerSelection.selectionBoundsStyle;

    const borderHorizontal = `CALC(${width})`; // + ${SELECTION_BORDER_WIDTH * 2}px)`;
    const borderVertical = `CALC(${height})`; // + ${SELECTION_BORDER_WIDTH * 2}px)`;
    const anchorStyle = {
        top, left,
        transform,
        transformOrigin: `CALC(${width}/2) CALC(${height}/2)`,
    };

    return (
        <div className={className} {...forwardProps}>
            {showGrabbies && (
                <div className="anchor" style={anchorStyle}>
                    <div className="left-border" style={{ top: 0, height: borderVertical }}>
                        <div className={middleLeftGrabbie} data-type="grabbie-middle-left" />
                    </div>
                    <div className="right-border" style={{ top: 0, left: width, height: borderVertical }}>
                        <div className={middleRightGrabbie} data-type="grabbie-middle-right" />
                    </div>
                    <div className="bottom-border" style={{ width: borderHorizontal, top: height }}>
                        <div className={bottomLeftGrabbie} data-type="grabbie-bottom-left" />
                        <div className={bottomMiddleGrabbie} data-type="grabbie-bottom-middle" />
                        <div className={bottomRightGrabbie} data-type="grabbie-bottom-right" />
                    </div>
                    <div className="top-border" style={{ top: 0, width: borderHorizontal }}>
                        <div className="spinny-line" />
                        {displayBorderRadiusGrabby && (
                            <>
                                <div className="border-radius-line" />
                                <div className={borderRadiusGrabbie} data-type="grabbie-border-radius" />
                            </>
                        )}
                        <div className={spinnyGrabbie} data-type="grabbie-spinny" />
                        <div className={topLeftGrabbie} data-type="grabbie-top-left" />
                        <div className={topMiddleGrabbie} data-type="grabbie-top-middle" />
                        <div className={topRightGrabbie} data-type="grabbie-top-right" />
                    </div>
                </div>
            )}

            {showLock && (
                <div className={lockOverlay} style={layerSelection.selectionBoundsStyle}>
                    <LockIcon sx={{ fontSize: 50 }} />
                </div>
            )}
        </div >
    );
}

export default styled(SelectionOverlay)(styles)

import React from 'react';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const styles = ({ theme }) => ({
    // position: 'relative',
    // height: '4.75in',
    // width: '6.25in',
    // '& .point': {
    width: 10,
    height: 10,
    margin: -5,
    backgroundColor: 'green',
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 500,
    // }
});

function DebugPoints(props) {
    const { className, points } = props;

    const flattenedPoints = [];
    if (Array.isArray(points)) {
        points.forEach(point => flattenedPoints.push(point))
    } else if (typeof points === 'object') {
        Object.keys(points).forEach(
            color => points[color].forEach(
                point => flattenedPoints.push({
                    x: point.x,
                    y: point.y,
                    color,
                })
            )
        );
    }

    return (
        // <div className={'debug-points ' + className} style={style}>
        <>
            {flattenedPoints.map((point, key) => (
                <div
                    key={key}
                    className={className}
                    style={{
                        top: point.y + 'in',
                        left: point.x + 'in',
                        backgroundColor: point?.color,
                    }}
                />
            ))}
        </>
    );
}

export default styled(DebugPoints)(styles);

import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import ContentEditable from 'components/ContentEditable';
import ErrorSplash from 'components/ErrorSplash';
import { functionPivot } from 'components/Field';
import Loader from 'components/Loader';
import useImageLoader from 'hooks/useImageSource/useImageSource';
import React, { useEffect, useRef } from 'react';

const styles = ({ theme }) => ({
    // zIndex: 400,
    // display: 'flex',
    '&.notSelected': {
        // filter: 'grayscale(80%)',
        // opacity: 0.8,
    },
    '&.selected': {

    },
    '&.highlight': {
        outline: '2px solid ' + theme.palette.primary.light,
        // backgroundColor: 'rgba(255,255,255, 0.2) !important',
    }
});

function replaceCaret(el) {
    // Place the caret at the end of the element
    const target = document.createTextNode('');
    el.appendChild(target);
    // do not move caret if element was not focused
    const isTargetFocused = document.activeElement === el;
    if (target !== null && target.nodeValue !== null && isTargetFocused) {
        var sel = window.getSelection();
        if (sel !== null) {
            var range = document.createRange();
            range.setStart(target, target.nodeValue.length);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
        if (el instanceof HTMLElement) el.focus();
    }
}

let getLayerStateCount = 0;

function renderStringTemplate(template, variables) {
    template
        .matchAll('{(.*?)}')
        .forEach(([placeholder, variableName]) => {
            template = template.replace(placeholder, variables[variableName]);
        });

    // Object.keys(variables).forEach(variableName => {
    //     template = template.replace(`{${variableName}}`, )
    // });
    return template;
}
const FILTER_TEMPLATE = {
    blur: 'blur({value}px)',
    brightness: 'brightness({value}%)',
    contrast: 'contrast({value}%)',
    grayscale: 'grayscale({value}%)',
    invert: 'invert({value}%)',
    opacity: 'opacity({value}%)',
    sepia: 'sepia({value}%)',
    saturate: 'saturate({value}%)',
    huerotate: 'hue-rotate({value}deg)',
};
function filterConfigToString(filterConfig) {
    const template = functionPivot(filterConfig, 'type', () => '', FILTER_TEMPLATE);
    return renderStringTemplate(template, filterConfig);
}

export function getLayerState(layer) {
    getLayerStateCount++;
    const {
        // app state
        hidden = false,
        locked = false,

        // text
        text = '',
        textAlign = 'left',
        verticalAlign = 'top',
        fontWeight = 'normal',
        // fontStyle = 'normal',
        fontFamily = 'Roboto',
        color = '#000',
        fontSize = 20,

        scale = 1,

        // size
        width = 1,
        height = 1,

        // location
        x = 0,
        y = 0,

        // rotation
        rotation = 0,

        // border
        borderRadius = 0,
        borderWidth = 0,
        borderStyle = 'solid',
        borderColor = '#000',
        padding = 0,

        // look and feel
        backgroundColor,
        backgroundImage,

        // filters
        filters: filterConfig = [],
    } = layer || {};

    // compile filters
    const filters = filterConfig.map(filterConfigToString).join(' ');
    const textEditableFilters = filterConfig
        .filter(item => item.type !== 'blur')
        .map(filterConfigToString).join(' ');

    // setting up size attributes
    const positionAttributes = {
        position: 'absolute',
        boxSizing: 'border-box',
        top: y + 'in',
        left: x + 'in',
        width: width + 'in',
        minWidth: width + 'in',
        maxWidth: width + 'in',
        height: height + 'in',
        minHeight: height + 'in',
        maxHeight: height + 'in',
        transform: 'rotate(' + parseFloat(rotation) + 'deg)',
    };
    if (scale !== 1) {
        positionAttributes.transform += ' scale(' + scale + ')';
    }
    const borderAttributes = {
        borderRadius: borderRadius + 'in',
        borderColor,
        borderWidth: borderWidth + 'in',
        borderStyle,
        padding: padding + 'in',
    };
    const fontAttributes = {
        text,
        fontFamily: JSON.stringify(fontFamily),
        // fontStyle,
        fontWeight,
        textAlign,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: (
            verticalAlign === 'top'
                ? 'start'
                : (verticalAlign === 'middle'
                    ? "center"
                    : (verticalAlign === 'bottom'
                        ? 'end'
                        : verticalAlign))),
        fontSize: fontSize + 'pt',
        color,
    };
    const backgroundAttributes = {
        backgroundColor,
        backgroundImage,
        backgroundSize: width + 'in ' + height + 'in',
    };

    // return attribute groups
    return {
        hidden,
        locked,
        text,
        positionAttributes,
        borderAttributes,
        fontAttributes,
        backgroundAttributes,
        filterAttributes: {
            filter: filters,
        },
        textEditableFilterAttributes: {
            filter: textEditableFilters,
        },
        values: {
            // text
            text,
            textAlign,
            verticalAlign,
            fontWeight,
            // fontStyle,
            fontFamily: JSON.stringify(fontFamily),
            color,
            fontSize,
            // size
            width,
            height,
            // location
            x,
            y,
            // rotation
            rotation,
            // border
            borderRadius,
            borderWidth,
            borderStyle,
            borderColor,
            padding,
            // look and feel
            backgroundColor,
            backgroundImage,
            // filters
            filters,
        }
    };
}

function Layer(props) {
    const {
        className,
        textEditable = false,
        selected = false,
        selectionExists = false,
        attributes,
        preview,
        id,
        highlight,
        onChange,
        ...forwardProps
    } = props;
    const rootClass = clsx(className, {
        selected,
        notSelected: !selected && selectionExists,
        highlight,
    });

    const {
        hidden,
        text,
        positionAttributes,
        borderAttributes,
        fontAttributes,
        backgroundAttributes,
        filterAttributes,
        textEditableFilterAttributes,
    } = getLayerState(attributes);

    const imageState = useImageLoader(backgroundAttributes.backgroundImage);
    const {
        loading: imageLoading,
        error: imageError,
        url: imageURL,
    } = imageState;
    backgroundAttributes.backgroundImage = Boolean(imageURL)
        ? `url(${imageURL})`
        : undefined;
    // console.log(imageState)

    const inputRef = useRef();
    const focusInput = () => {
        inputRef.current.focus();
    };
    // text editor auto-focus
    useEffect(() => {
        if (textEditable && inputRef.current) {
            inputRef.current.focus();
        }
    }, [textEditable, inputRef]);

    const handleInput = value => {
        onChange('text', value);
    };

    // useEffect(() => {
    //     if (inputRef.current) {
    //         replaceCaret(inputRef.current);
    //     }
    // }, [text, inputRef]);


    if (hidden && !preview) {
        return null;
    }

    if (Boolean(imageError)) {
        return (
            <ErrorSplash
                data-key={id}
                data-type="layer"
                data-selected={selected}
                sx={{
                    ...positionAttributes,
                    ...borderAttributes,
                    // ...filterAttributes,
                }}
            />
        );
    }

    if (Boolean(imageLoading)) {
        return (
            <Loader
                data-key={id}
                data-type="layer"
                data-selected={selected}
                sx={{
                    ...positionAttributes,
                    ...borderAttributes,
                    // ...filterAttributes,
                }}
            />

        );
    }

    if (textEditable) {
        return (
            <div
                data-key={id}
                data-type="layer"
                data-selected={selected}
                onClick={focusInput}
                style={{
                    ...positionAttributes,
                    ...borderAttributes,
                    ...fontAttributes,
                    ...textEditableFilterAttributes,
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    borderColor: 'transparent',
                    // flexDirection: 'column',
                }}
                // defaultValue={text}
                {...forwardProps}
            >
                <ContentEditable
                    data-key={id}
                    data-type="textarea"
                    data-selected={selected}
                    innerRef={inputRef}
                    html={text}
                    onChange={handleInput}
                    className={rootClass}
                    style={{
                        padding: 'initial',
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        // textAlign: 'inherit',
                        color: 'inherit',
                        letterSpacing: 'inherit',
                        background: 'transparent',
                        outline: '0px',
                        borderWidth: '0px',
                        wordBreak: 'break-word',
                        minHeight: `${text}`.trim().length > 0 ? 'initial' : '0.25in',
                        // borderColor: 'transparent'
                    }}
                />
            </div>
        );
    }

    return (
        <div
            data-key={id}
            data-type="layer"
            data-selected={selected}
            className={rootClass}
            style={!hidden ? ({
                ...positionAttributes,
                ...borderAttributes,
                ...fontAttributes,
                ...backgroundAttributes,
                ...filterAttributes,
                // outline: highlight ? '3px white dotted' : 'none'
            }) : ({
                ...positionAttributes,
            })}
            {...forwardProps}>
            <div
                data-key={id}
                data-type="layer"
                data-selected={selected}
                style={{ wordBreak: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: text }}
            />
        </div>
    );
}

export default styled(Layer)(styles);

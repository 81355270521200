import { useCallback, useRef, useState } from 'react';
import Matrix from '../../../library/Matrix';
import Vector from '../../../library/Vector';
import { TOOL_TYPE_ADD_SQUARE, TOOL_TYPE_ADD_TEXT, TOOL_TYPE_POINTER } from './useCanvasSelection';

export const ZOOM_EASE = 1000;
export const ROTATION_EASE = 100 * Math.PI;
export const PIXELS_PER_INCH_RATIO = 1 / 96;
export const PIXELS_PER_INCH = 96;
export const FRAMES_PER_SECOND = 30;
export const RENDER_FREQUENCY = 1000 / FRAMES_PER_SECOND;
export const PRESS_TIMEOUT = 500;
export const GRABBING_STATES = ['grabbing', 'mouseDownOnGrabbie'];
export const MOUSE_RIGHT_BUTTON = 3;
export const MOUSE_MIDDLE_BUTTON = 2;
export const MOUSE_LEFT_BUTTON = 1;

export const layerPresets = {
    [TOOL_TYPE_ADD_TEXT]: {
        text: 'Placeholder',
        borderWidth: 0,
        backgroundColor: 'rgba(255,255,255,0.0)',
        borderColor: 'rgba(0,0,0,0.0)',
    },
    [TOOL_TYPE_ADD_SQUARE]: {
    }
}

export const grabbieTransformMap = {
    // for performance sake we will be treating all middle grabbies as if they were on the axis
    'top-middle': {
        getScaleAnchor: ({ x, y, width, height }) => ({ x: x + width / 2, y: y + height }),
        getScaleGrabby: ({ x, y, width }) => ({ x: x + width / 2, y }),
        getDimensions: ({ width, height }, mouseMovement) => ({
            height: height - mouseMovement.y,
            width,
        }),
        getNewScaleGrabbyRotated: ({ scaleGrabbyRotated: { x, y }, mouseMovement, layer }) => {
            const mouseMovementYRotated = mouseMovement.getYVector().rotateDeg(layer.rotation);
            return {
                x: x + mouseMovementYRotated.x,
                y: y + mouseMovementYRotated.y
            };
        },
    },
    'middle-left': {
        getScaleAnchor: ({ x, y, width, height }) => ({ x: x + width, y: y + height / 2 }),
        getScaleGrabby: ({ x, y, width, height }) => ({ x, y: y + height / 2 }),
        getDimensions: ({ width, height }, mouseMovement) => ({
            height,
            width: width - mouseMovement.x,
        }),
        getNewScaleGrabbyRotated: ({ scaleGrabbyRotated: { x, y }, mouseMovement, layer }) => {
            const mouseMovementXRotated = mouseMovement.getXVector().rotateDeg(layer.rotation);
            return {
                x: x + mouseMovementXRotated.x,
                y: y + mouseMovementXRotated.y
            };
        },
    },
    'bottom-middle': {
        getScaleAnchor: ({ x, y, width }) => ({ x: x + width / 2, y }),
        getScaleGrabby: ({ x, y, width, height }) => ({ x: x + width / 2, y: y + height }),
        getDimensions: ({ width, height }, mouseMovement) => ({
            height: height + mouseMovement.y,
            width,
        }),
        getNewScaleGrabbyRotated: ({ scaleGrabbyRotated: { x, y }, mouseMovement, layer }) => {
            const mouseMovementYRotated = mouseMovement.getYVector().rotateDeg(layer.rotation);
            return {
                x: x + mouseMovementYRotated.x,
                y: y + mouseMovementYRotated.y
            };
        },
    },
    'middle-right': {
        getScaleAnchor: ({ x, y, width, height }) => ({ x, y: y + height / 2 }),
        getScaleGrabby: ({ x, y, width, height }) => ({ x: x + width, y: y + height / 2 }),
        getDimensions: ({ width, height }, mouseMovement) => ({
            height,
            width: width + mouseMovement.x,
        }),
        getNewScaleGrabbyRotated: ({ scaleGrabbyRotated: { x, y }, mouseMovement, layer }) => {
            const mouseMovementXRotated = mouseMovement.getXVector().rotateDeg(layer.rotation);
            return {
                x: x + mouseMovementXRotated.x,
                y: y + mouseMovementXRotated.y
            };
        },
    },
    // diagonals!
    'top-left': {
        getScaleAnchor: ({ x, y, width, height }) => ({ x: x + width, y: y + height }),
        getScaleGrabby: ({ x, y }) => ({ x, y }),
        getDimensions: ({ width, height }, mouseMovement) => ({
            width: width - mouseMovement.x,
            height: height - mouseMovement.y,
        }),
        getNewScaleGrabbyRotated: ({ scaleGrabbyRotated: { x, y }, mouseMovementRotated }) => ({
            x: x + mouseMovementRotated.x,
            y: y + mouseMovementRotated.y
        }),
    },
    'top-right': {
        getScaleAnchor: ({ x, y, height }) => ({ x, y: y + height }),
        getScaleGrabby: ({ x, y, width }) => ({ x: x + width, y }),
        getDimensions: ({ width, height }, mouseMovement) => ({
            width: width + mouseMovement.x,
            height: height - mouseMovement.y,
        }),
        getNewScaleGrabbyRotated: ({ scaleGrabbyRotated: { x, y }, mouseMovementRotated }) => ({
            x: x + mouseMovementRotated.x,
            y: y + mouseMovementRotated.y
        }),
    },
    'bottom-left': {
        getScaleAnchor: ({ x, y, width, height }) => ({ x: x + width, y: y }),
        getScaleGrabby: ({ x, y, height }) => ({ x, y: y + height }),
        getDimensions: ({ width, height }, mouseMovement) => ({
            width: width - mouseMovement.x,
            height: height + mouseMovement.y,
        }),
        getNewScaleGrabbyRotated: ({ scaleGrabbyRotated: { x, y }, mouseMovementRotated }) => ({
            x: x + mouseMovementRotated.x,
            y: y + mouseMovementRotated.y
        }),
    },
    'bottom-right': {
        getScaleAnchor: ({ x, y }) => ({ x, y }),
        getScaleGrabby: ({ x, y, width, height }) => ({ x: x + width, y: y + height }),
        getDimensions: ({ width, height }, mouseMovement) => ({
            width: width + mouseMovement.x,
            height: height + mouseMovement.y,
        }),
        getNewScaleGrabbyRotated: ({ scaleGrabbyRotated: { x, y }, mouseMovementRotated }) => ({
            x: x + mouseMovementRotated.x,
            y: y + mouseMovementRotated.y
        }),
    }
};

export default function useMouse({
    addLayer, viewport, layerSelection, debugMode
}) {
    const {
        screenRef,
        // canvasRef,
        toolsRef,
        propertiesRef,
        viewportToolsRef,
        updateCanvasLocation,
        updateCanvasRotation,
        updateCanvasZoom,
        viewportMatrix,
        disableAnimations,
        preview,
    } = viewport;
    const {
        // selectionRef,
        hasSelection,
        isItemSelected,
        setSelection,
        toggleItem,
        clearSelection,
        updateSelection,
        setTool,
        tool
    } = layerSelection;
    const action = useRef();
    const mouseDownPosRef = useRef();
    const debugPoints = useRef([]);
    const debugVectors = useRef([]);
    const [, forceRender] = useState(0);
    const [previewProperties, setPreviewProperties] = useState(false);
    const previewPropertiesRef = useRef();
    previewPropertiesRef.current = previewProperties;
    const handleMouseEvent = useCallback(
        event => {
            const {
                target: initialTarget, type,
                ctrlKey, shiftKey, altKey,
                deltaX, deltaY,
                movementX, movementY,
                clientX: mouseX, clientY: mouseY,
                nativeEvent: {
                    which: mouseButtonDown
                } = {}
            } = event;

            if ([MOUSE_MIDDLE_BUTTON, MOUSE_RIGHT_BUTTON].includes(mouseButtonDown)) {
                // this removes strange behaviors like right clicking and getting stuck in a drag
                // Dr. short circut we meet again
                return;
            }

            // ignore mouse events on toolsRef
            if (
                (screenRef.current && !screenRef.current.contains(initialTarget)) ||
                (toolsRef.current && toolsRef.current.contains(initialTarget)) ||
                (propertiesRef.current && propertiesRef.current.contains(initialTarget)) ||
                (viewportToolsRef.current && viewportToolsRef.current.contains(initialTarget))
            ) {
                return false;
            }

            // important to do this after the panel ignore
            let target = initialTarget;
            while (!target?.dataset?.type) {
                target = target.parentNode;
            }


            // debugPoints.current = [];
            if (type !== 'wheel' && !isItemSelected(target.dataset.key)) {
                // this prevents some strange dragging behavior freezing the screen
                event.preventDefault();
            }
            const screenRect = screenRef.current.getBoundingClientRect();
            const mousePos = new Vector({ x: mouseX - screenRect.x, y: mouseY - screenRect.y });
            if (type === 'wheel') {
                if (altKey) {
                    const rotationDiff = deltaY / ROTATION_EASE;
                    updateCanvasRotation(rotationDiff, mousePos);
                } else if (shiftKey || ctrlKey) {
                    const zoomIncrement = -deltaY / ZOOM_EASE;
                    updateCanvasZoom(currentZoom => zoomIncrement + currentZoom, mousePos);
                } else {
                    updateCanvasLocation(-deltaX, -deltaY);
                }
            } else if (type === 'mousedown') {
                if (!hasSelection && tool.startsWith('add:')) {
                    if (tool === TOOL_TYPE_ADD_TEXT) {
                        action.current = 'new:text';
                    } else if (tool === TOOL_TYPE_ADD_SQUARE) {
                        action.current = 'new:square';
                    }
                    mouseDownPosRef.current = mousePos;
                } else if (action.current === 'dragging') {
                    action.current = 'cleanup';
                } else if (target.dataset.type === 'layer' && isItemSelected(target.dataset.key)) {
                    action.current = 'mouseDownOnSelectedLayer';
                } else if (['workspace', 'canvas', 'layer'].includes(target.dataset.type)) {
                    action.current = 'mouseDownOnWorkArea';
                } else if (target.dataset.type && target.dataset.type.startsWith('grabbie-')) {
                    action.current = target.dataset.type.replace('grabbie-', 'mouseDownOnGrabbie:');
                }
                // make sure to disable animations in the viewport
                disableAnimations();
            } else if (type === 'mousemove') {
                if (action.current?.startsWith('new:')) {
                    const inverseViewPort = Matrix.from(viewportMatrix).inverse();
                    const startPoint = inverseViewPort.project(Vector.from(mouseDownPosRef.current)).multiply(PIXELS_PER_INCH_RATIO);
                    const endPoint = inverseViewPort.project(Vector.from(mousePos)).multiply(PIXELS_PER_INCH_RATIO);
                    const diagonal = Vector.from(endPoint).sub(startPoint);
                    const { angleDeg } = viewportMatrix.destructure();
                    diagonal.rotateDeg(angleDeg);
                    const width = diagonal.getXVector();
                    if (width.x < 0) {
                        startPoint.add(width);
                    }
                    const height = diagonal.getYVector();
                    if (height.y < 0) {
                        startPoint.add(height);
                    }
                    const centerPoint = Vector.from(diagonal)
                        .rotateDeg(-angleDeg)
                        .multiply(1 / 2)
                        .add(startPoint);
                    const adjustedStartPoint = Vector.from(startPoint)
                        .rotateDegAroundPoint(angleDeg, centerPoint);
                    // diagonal.displayCenter = startPoint;
                    // width.displayCenter = startPoint;
                    // height.displayCenter = startPoint;
                    // debugPoints.current = [
                    //     startPoint,
                    //     endPoint,

                    //     centerPoint
                    // ];
                    // debugVectors.current = [
                    //     diagonal.rotateDeg(-angleDeg),
                    //     width.rotateDeg(-angleDeg),
                    //     height.rotateDeg(-angleDeg),
                    //     adjustedStartPoint
                    // ];
                    setPreviewProperties({
                        x: adjustedStartPoint.x,
                        y: adjustedStartPoint.y,
                        width: width.distance,
                        height: height.distance,
                        rotation: -angleDeg,
                        ...layerPresets[tool]
                    });

                    // forceRender(Math.random());
                } else if (action.current === 'mouseDownOnSelectedLayer' || action.current === 'dragging') {
                    action.current = 'dragging';
                    const inverseViewPort = Matrix.from(viewportMatrix).clearTranslation().inverse();
                    const mouseMovement = inverseViewPort.project(Vector.from({ x: movementX, y: movementY })).multiply(PIXELS_PER_INCH_RATIO);
                    updateSelection(({ x, y }) => ({
                        x: parseFloat(x) + mouseMovement.x,
                        y: parseFloat(y) + mouseMovement.y
                    }));
                } else if (action.current === 'mouseDownOnWorkArea' || action.current === 'panning') {
                    action.current = 'panning';
                    updateCanvasLocation(movementX, movementY);
                } else if (action.current?.startsWith('mouseDownOnGrabbie:') || action.current?.startsWith('grabbing:')) {
                    const [, grabbie] = action.current.split(':');
                    const inverseViewPort = Matrix.from(viewportMatrix).clearTranslation().inverse();
                    const mouseMovementRotated = inverseViewPort.project(Vector.from({ x: movementX, y: movementY })).multiply(PIXELS_PER_INCH_RATIO);
                    // const translatedMouseMovement = viewportMatrix.project(mouseMovement);
                    if (grabbie in grabbieTransformMap) {
                        const { [grabbie]: transform } = grabbieTransformMap;
                        // mouseMovementRotated.multiply(20);
                        updateSelection((layer) => {
                            layer = {
                                x: parseFloat(layer.x),
                                y: parseFloat(layer.y),
                                width: parseFloat(layer.width),
                                height: parseFloat(layer.height),
                                rotation: parseFloat(layer.rotation), // deg
                            };
                            const mouseMovement = Vector.from(mouseMovementRotated).rotateDeg(-layer.rotation);
                            const scaleAnchor = transform.getScaleAnchor(layer);
                            const scaleGrabby = transform.getScaleGrabby(layer);
                            const centerPoint = {
                                x: layer.x + layer.width / 2,
                                y: layer.y + layer.height / 2,
                            };
                            const scaleAnchorRotated = Vector.from(scaleAnchor).rotateDegAroundPoint(layer.rotation, centerPoint);
                            const scaleGrabbyRotated = Vector.from(scaleGrabby).rotateDegAroundPoint(layer.rotation, centerPoint);
                            const newScaleGrabbyRotated = transform.getNewScaleGrabbyRotated({
                                scaleGrabbyRotated,
                                mouseMovementRotated,
                                mouseMovement,
                                layer,
                            });
                            const newCenterPoint = {
                                x: (scaleAnchorRotated.x + newScaleGrabbyRotated.x) / 2,
                                y: (scaleAnchorRotated.y + newScaleGrabbyRotated.y) / 2,
                            };
                            const newDimensions = transform.getDimensions(layer, mouseMovement);
                            const newPosition = {
                                x: newCenterPoint.x - newDimensions.width / 2,
                                y: newCenterPoint.y - newDimensions.height / 2,
                            };

                            return {
                                ...newPosition,
                                ...newDimensions
                            }
                        });
                    } else if (grabbie === 'spinny') {
                        // const screenRect = screenRef.current.getBoundingClientRect();


                        updateSelection((layer) => {
                            const mousePos = new Vector({
                                x: mouseX - screenRect.x,
                                y: mouseY - screenRect.y
                            });

                            Matrix.from(viewportMatrix)
                                .inverse()
                                .project(mousePos)
                                .multiply(PIXELS_PER_INCH_RATIO);

                            layer = {
                                x: parseFloat(layer.x),
                                y: parseFloat(layer.y),
                                width: parseFloat(layer.width),
                                height: parseFloat(layer.height),
                            };
                            return {
                                rotation: Vector.from(mousePos).sub({
                                    x: layer.x + layer.width / 2,
                                    y: layer.y + layer.height / 2,
                                }).angleDeg + 180 / 2
                            };
                        });
                    } else if (grabbie === 'border-radius') {
                        // const screenRect = screenRef.current.getBoundingClientRect();


                        updateSelection((layer) => {
                            const mousePos = new Vector({
                                x: mouseX - screenRect.x,
                                y: mouseY - screenRect.y
                            });

                            Matrix.from(viewportMatrix)
                                .inverse()
                                .project(mousePos)
                                .multiply(PIXELS_PER_INCH_RATIO);

                            layer = {
                                x: parseFloat(layer.x),
                                y: parseFloat(layer.y),
                                width: parseFloat(layer.width),
                                height: parseFloat(layer.height),
                                rotation: parseFloat(layer.rotation),
                            };
                            const centerPoint = Vector.from({
                                x: layer.x + layer.width / 2,
                                y: layer.y + layer.height / 2,
                            });
                            const m = Vector.from(mousePos).rotateDegAroundPoint(-layer.rotation, centerPoint);
                            const pointZero = Vector.from(layer);
                            const diff = Vector.from(m).sub(pointZero);

                            return {
                                borderRadius: diff.x,
                            }
                        });
                    }
                    action.current = action.current.replace('mouseDownOnGrabbie:', 'grabbing:');
                }
            } else if (type === 'click') {
                const midAction = ['dragging', 'panning', 'grabbing'].includes(action.current)
                    || action.current?.startsWith('grabbing:')
                    || action.current?.startsWith('mouseDownOnGrabbie:');
                if (action.current.startsWith('new:')) {
                    setSelection(addLayer(previewPropertiesRef.current));
                    setPreviewProperties(false);
                    if (action.current === 'new:square') {
                        setTool(TOOL_TYPE_POINTER);
                    }
                } else if (action.current === 'cleanup') {
                    // do nothing, this can happen when dragging off the window and relesing,
                } else if (!midAction && target.dataset.type === 'layer') {
                    if (ctrlKey) {
                        toggleItem(target.dataset.key);
                    } else {
                        setSelection([target.dataset.key]);
                    }
                } else if (target.dataset.type !== 'layer' && !midAction && target.dataset.type !== 'textarea') {
                    clearSelection();
                    setTool(TOOL_TYPE_POINTER);
                }
                action.current = null;
                forceRender(i => i + 1);
            } else if (type === 'dblclick') {
                if (target.dataset.selected === 'true') {
                    setTool(TOOL_TYPE_ADD_TEXT);
                }
            }
            return false;
        }, [
        clearSelection, setSelection, toggleItem, updateCanvasLocation, updateCanvasRotation,
        updateCanvasZoom, updateSelection, action, isItemSelected,
        screenRef, toolsRef, viewportToolsRef, //canvasRef,
        propertiesRef,
        viewportMatrix, disableAnimations,// selectionRef
        forceRender,
        setTool, tool, hasSelection, mouseDownPosRef, addLayer, setPreviewProperties
    ]);

    // TODO: rely on viewport ref if possible
    // useEffect(() => {
    //     console.log('NEW MOUSE HANDLE');
    // }, [viewportMatrix])

    const mouseEventProps = {};
    [
        'onClick', 'onWheel',/* 'onCopy', 'onCut', 'onPaste',*/
        'onContextMenu', 'onDoubleClick', 'onMouseDown', 'onMouseEnter', 'onMouseLeave',
        'onMouseMove', 'onMouseOut', 'onMouseOver', 'onMouseUp',
    ].forEach(eventHandle => mouseEventProps[eventHandle] = handleMouseEvent);

    // this is how we disable mouse logic in preview mode
    // todo replace with preview logic, maybe that 3d tilt effect with mouse =)
    const mouseEventPreviewProps = {};

    const mouseEventHandlerProps = preview
        ? mouseEventPreviewProps
        : (debugMode ? {} : mouseEventProps);

    return {
        mouseEventHandlerProps,
        mouseState: action.current,
        debugPoints: debugPoints.current,
        debugVectors: debugVectors.current,
        previewProperties
    };
}

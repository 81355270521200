import { updateStateObject } from 'hooks/useXState';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createClamp } from '../../../library/Clamp';
import Matrix from '../../../library/Matrix';
import Vector from '../../../library/Vector';
import { PIXELS_PER_INCH } from './useMouse';

// const zoomClamp = value => clamp(value, { min: 0.2, max: 5 });
export const MAX_ZOOM = 5;
export const MIN_ZOOM = 0.2;
const zoomClamp = createClamp({ min: MIN_ZOOM, max: MAX_ZOOM });

function calculateScreenProperties({ orientation, canvasRef, screenRef, toolsRef, propertiesRef, viewportToolsRef }) {
    // TODO: adjust padding based on screen size #mobile
    const swapWidthAndHeight = orientation === 'portrait' || orientation === 'portraitAlt';
    const { current: { offsetWidth: screenWidth, offsetHeight: screenHeight } } = screenRef;
    const { current: { offsetWidth: ogCanvasWidth, offsetHeight: ogCanvasHeight } } = canvasRef;
    const canvasWidth = swapWidthAndHeight ? ogCanvasHeight : ogCanvasWidth;
    const canvasHeight = swapWidthAndHeight ? ogCanvasWidth : ogCanvasHeight;
    const rotation = orientation === 'portrait'
        ? 90
        : orientation === 'landscapeAlt'
            ? 180
            : orientation === 'portraitAlt'
                ? 270
                : 0;
    const translation = orientation === 'portrait'
        ? Vector.from({ x: canvasWidth, y: 0 })
        : orientation === 'landscapeAlt'
            ? Vector.from({ x: canvasWidth, y: canvasHeight })
            : orientation === 'portraitAlt'
                ? Vector.from({ x: 0, y: canvasHeight })
                : Vector.from({ x: 0, y: 0 });

    const {
        offsetHeight: viewportToolsHeight = 0,
    } = viewportToolsRef.current || {};

    const {
        offsetWidth: toolsWidth = 0,
        offsetLeft: toolLeft = 0,
    } = toolsRef.current;

    const {
        offsetWidth: propertiesWidth = 0,
    } = propertiesRef.current || {};

    const padding = 75;
    const marginTop = padding; //headerRef.current.offsetHeight + padding;
    const marginBottom = padding + viewportToolsHeight;//padding;
    const marginLeft = padding + toolsWidth + toolLeft;
    const marginRight = padding + propertiesWidth;
    const canvasSizeRatio = canvasWidth / canvasHeight;
    const canvasSize = new Vector({ x: canvasWidth, y: canvasHeight });
    const visibleScreenWidth = screenWidth - (marginLeft + marginRight);
    const visibleScreenHeight = screenHeight - (marginTop + marginBottom);
    const visibleScreenSizeRatio = visibleScreenWidth / visibleScreenHeight;
    const visibleScreenSize = new Vector({ x: visibleScreenWidth, y: visibleScreenHeight });
    const visibleScreenPos = new Vector({ x: marginLeft, y: marginTop });
    const visibleScreenCenterPos = Vector.from(visibleScreenPos)
        .add(Vector.from(visibleScreenSize).multiply(1 / 2));
    const canvasCenterPos = Vector.from(visibleScreenCenterPos)
        .sub(Vector.from(canvasSize).multiply(1 / 2));

    return {
        rotation, translation,
        screenWidth, screenHeight,
        canvasWidth, canvasHeight, canvasSize, canvasSizeRatio, canvasCenterPos,
        visibleScreenWidth, visibleScreenHeight, visibleScreenSizeRatio,
        visibleScreenPos, visibleScreenCenterPos,
        getFitCanvasToScreenZoom() {
            if (visibleScreenSizeRatio < canvasSizeRatio) {
                // limiting factor is height
                return visibleScreenWidth / canvasWidth;
            }
            // limiting factor is width
            return visibleScreenHeight / canvasHeight;
        },
    }
}

function getElementCenterPoint(element) {
    const { offsetWidth, offsetHeight } = element;
    return new Vector({ x: offsetWidth / 2, y: offsetHeight / 2 });
}

export default function useViewport(config) {
    const { orientation } = config || {};
    const canvasRef = useRef();
    const screenRef = useRef()
    const toolsRef = useRef();
    const viewportToolsRef = useRef();
    const previewRef = useRef();
    const propertiesRef = useRef();
    const previewStateRef = useRef(null);
    const [viewport, _updateViewport] = useState({
        viewportMatrix: Matrix.from(),
        switcherPreview: null,
        animations: true,
        zoom: 1,
        statePoints: [],
    });
    const [guides, setGuides] = useState(false);
    const toggleGuides = useCallback(() => setGuides(v => !v), [setGuides]);

    const updateViewport = useCallback(update => {
        _updateViewport(originalViewport => {
            const updatedViewport = updateStateObject(originalViewport, update);
            const viewportMatrix = Matrix.from();
            if (previewRef.current && screenRef.current && canvasRef.current) {
                const { current: { offsetWidth: screenWidth, offsetHeight: screenHeight } } = screenRef;
                const { current: { offsetWidth: canvasWidth, offsetHeight: canvasHeight } } = canvasRef;
                const { current: { offsetWidth: previewWidth, offsetHeight: previewHeight } } = previewRef;
                const widthRatio = (previewWidth / 3) / canvasWidth;
                const heightRatio = (previewHeight / 3) / canvasHeight;
                const previewScaleRatio = Math.min(widthRatio, heightRatio);
                const inverseViewPort = Matrix.from(updatedViewport.viewportMatrix).scale(PIXELS_PER_INCH).inverse();
                viewportMatrix.translate(previewWidth / 3, previewHeight / 3);
                viewportMatrix.scale(previewScaleRatio);
                const screenPoints = [
                    Vector.from({ x: 0, y: 0 }),
                    Vector.from({ x: screenWidth, y: screenHeight }),
                ].map(inverseViewPort.project);
                const screenCenter = Vector.from(screenPoints[0]).add(screenPoints[1]).multiply(1 / 2);
                const canvasRotation = updatedViewport.viewportMatrix.destructure().angle;
                screenPoints.forEach(screenPoint => screenPoint.rotateAroundPoint(canvasRotation, screenCenter));
                const width = Vector.from({ x: screenPoints[1].x - screenPoints[0].x, y: 0 }).distance;
                const height = Vector.from({ x: 0, y: screenPoints[1].y - screenPoints[0].y }).distance;
                updatedViewport.switcherPreview = {
                    screen: {
                        width, height,
                        x: screenCenter.x - width / 2,
                        y: screenCenter.y - height / 2,
                        rotation: -canvasRotation
                    },
                    viewportMatrix,
                };
            }
            return updatedViewport;
        });
    }, [_updateViewport, previewRef, screenRef, canvasRef]);

    const resetViewport = useCallback(() => {
        const screen = calculateScreenProperties({
            orientation, canvasRef, screenRef, propertiesRef, toolsRef, viewportToolsRef
        });
        const viewportMatrix = new Matrix();
        viewportMatrix.translate(screen.canvasCenterPos.x, screen.canvasCenterPos.y);
        viewportMatrix.translate(screen.translation.x, screen.translation.y);
        viewportMatrix.rotateDeg(screen.rotation);
        updateViewport({
            viewportMatrix,
            zoom: 1,
            animations: true,
        });
    }, [updateViewport, orientation]);


    // this code prevents user from zooming with ctrl + wheel
    // TODO: does this belong here or in the screen component?
    useEffect(() => {
        const component = screenRef.current;
        function noZoom(event) {
            if (event.ctrlKey) {
                event.preventDefault();
            }
        }
        component.addEventListener('wheel', noZoom, true);
        return () => {
            component.removeEventListener('wheel', noZoom);
        };
    }, []);

    const zoomTo = useCallback((layer) => {
        const { x, y, width, height, rotation } = layer;
        const screen = calculateScreenProperties({
            orientation, canvasRef, screenRef, propertiesRef, toolsRef, viewportToolsRef
        });
        const viewportMatrix = new Matrix();
        // viewportMatrix.rotateAroundPointDeg(-screen.rotation, screen.visibleScreenCenterPos);
        const layerPos = new Vector({ x, y }).multiply(PIXELS_PER_INCH);
        const layerSize = new Vector({ x: width, y: height }).multiply(PIXELS_PER_INCH);
        const layerSizeRatio = width / height;
        const layerCenterPos = Vector.from(layerPos).add(Vector.from(layerSize).multiply(1 / 2));
        const viewportCenterPos = Vector.from(layerCenterPos).multiply(-1).add(screen.visibleScreenCenterPos);
        let zoom = 1;
        if (screen.visibleScreenSizeRatio < layerSizeRatio) {
            console.log('here')
            zoom = screen.visibleScreenWidth / layerSize.x;
        } else {
            zoom = screen.visibleScreenHeight / layerSize.y;
        }
        zoom *= .75;
        viewportMatrix.scaleAroundPoint(zoom, screen.visibleScreenCenterPos);
        viewportMatrix.rotateAroundPointDeg(-rotation, screen.visibleScreenCenterPos)
        viewportMatrix.translate(viewportCenterPos.x, viewportCenterPos.y);
        updateViewport({
            viewportMatrix,
            zoom: zoom,
            animations: true
        });
    }, [updateViewport, orientation]);

    // viewport modifiers
    const fitCanvasViewport = useCallback(() => {
        const screen = calculateScreenProperties({
            orientation, canvasRef, screenRef, propertiesRef, toolsRef, viewportToolsRef
        });
        const zoom = screen.getFitCanvasToScreenZoom();
        const viewportMatrix = new Matrix();
        viewportMatrix.scaleAroundPoint(zoom, screen.visibleScreenCenterPos);
        viewportMatrix.translate(screen.canvasCenterPos.x, screen.canvasCenterPos.y);
        viewportMatrix.translate(screen.translation.x, screen.translation.y);
        viewportMatrix.rotateDeg(screen.rotation);
        updateViewport({
            viewportMatrix,
            zoom,
            animations: true,
        });
    }, [updateViewport, orientation]);

    // default view to canvas fitting viewport
    useEffect(() => {
        fitCanvasViewport();
    }, [fitCanvasViewport]);

    const togglePreview = useCallback(() => {
        if (previewStateRef.current) {
            updateViewport(previewStateRef.current);
            previewStateRef.current = null;
        } else {
            previewStateRef.current = {
                viewportMatrix: viewport.viewportMatrix,
                animations: true,
                zoom: viewport.zoom,
            };
            fitCanvasViewport();
        }
    }, [previewStateRef, updateViewport, fitCanvasViewport, viewport]);
    const preview = Boolean(previewStateRef.current);

    const updateCanvasZoom = useCallback((zoom, point = false, animations = false) => updateViewport(
        ({ viewportMatrix, zoom: currentZoom }) => {
            const matrix = new Matrix();
            const newZoom = zoomClamp(typeof zoom === 'function' ? zoom(currentZoom) : zoom);
            if (point) {
                matrix.scaleAroundPoint(newZoom / currentZoom, point);
            } else {
                point = getElementCenterPoint(screenRef.current);
                matrix.scaleAroundPoint(newZoom / currentZoom, point);
            }
            return {
                viewportMatrix: matrix.dot(viewportMatrix),
                zoom: newZoom,
                animations,
            };
        }
    ), [updateViewport, screenRef]);


    const updateCanvasLocation = useCallback((translateX, translateY) => updateViewport(
        ({ viewportMatrix, zoom }) => {
            const matrix = new Matrix();
            matrix.translate(translateX, translateY);
            return {
                viewportMatrix: matrix.dot(viewportMatrix),
                zoom,
                animations: false,
            };
        }
    ), [updateViewport]);

    const updateCanvasRotation = useCallback((rotation, point = false, deg = false) => updateViewport(
        ({ viewportMatrix, zoom }) => {
            const matrix = new Matrix();
            if (deg) {
                if (point) {
                    matrix.rotateAroundPointDeg(rotation, point);
                } else {
                    point = getElementCenterPoint(screenRef.current);
                    matrix.rotateAroundPointDeg(rotation, point);
                }
            } else {
                if (point) {
                    matrix.rotateAroundPoint(rotation, point);
                } else {
                    point = getElementCenterPoint(screenRef.current);
                    matrix.rotateAroundPoint(rotation, point);
                }
            }
            return {
                viewportMatrix: matrix.dot(viewportMatrix),
                animations: false,
                zoom,
                statePoints: {
                    'blue': [point],
                }
            };
        }
    ), [updateViewport]);

    const disableAnimations = useCallback(() => updateViewport(orig => ({ ...orig, animations: false })), [updateViewport]);

    const getCanvasSize = useCallback(() => {
        return {
            widthIn: 6.25,
            heightIn: 4.5,
            width: 6.25 * PIXELS_PER_INCH,
            height: 4.5 * PIXELS_PER_INCH
        };
    }, []);

    return {
        screenRef,
        canvasRef,
        toolsRef,
        viewportToolsRef,
        previewRef,
        propertiesRef,
        ...viewport,
        updateViewport,
        updateCanvasZoom,
        resetViewport,
        updateCanvasLocation,
        updateCanvasRotation,
        fitCanvasViewport,
        togglePreview,
        preview,
        toggleGuides,
        guides,
        disableAnimations,
        getCanvasSize,
        zoomTo,
    };
}

import { useCallback, useMemo } from 'react';
import GoogleFonts from '../../../connectors/GoogleFonts';
import useAsyncCallback from '../../../hooks/useAsyncCallback';

const EMPTY_LAYERS = [];

const FontManager = {
    fontLoadingState: {},
    loadFonts(fontList) {
        // keep in mind the goal of this function is to always return the SAME promise
        const fontsToLoad = new Set([...fontList]);
        const fontLoadingStateKey = [...fontsToLoad].sort().join('|');
        const currentPromises = new Set();

        // first we check if we are already loading this fontList
        if (fontLoadingStateKey === '') {
            return true;
        } else if (fontLoadingStateKey in this.fontLoadingState) {
            return this.fontLoadingState[fontLoadingStateKey];
        }

        // remove loaded fonts and tally currently loading fonts
        Object.keys(this.fontLoadingState)
            .forEach(font => {
                // if true then font is loaded
                if (this.fontLoadingState[font] instanceof Promise) {
                    currentPromises.add(this.fontLoadingState[font]);
                } else if (this.fontLoadingState[font] === true) {
                    fontsToLoad.delete(font);
                }
            });

        // starting to load any fonts we need
        const promiseForUnloadedFonts = this.loadCSS(fontLoadingStateKey, fontsToLoad);
        // mark each individual font load state with the promise responsible for it
        fontsToLoad.forEach(font => this.fontLoadingState[font] = promiseForUnloadedFonts);

        const hasCurrentPromises = Boolean(currentPromises.size);
        let promise = true;
        if (promiseForUnloadedFonts instanceof Promise) {
            promise = hasCurrentPromises
                // we need to include current promises with the new promise
                ? Promise.all([promiseForUnloadedFonts, ...currentPromises])
                : promiseForUnloadedFonts;
        } else if (hasCurrentPromises) {
            promise = currentPromises.size > 1
                ? Promise.all([...currentPromises])
                : [...currentPromises][0];
        }
        this.fontLoadingState[fontLoadingStateKey] = promise;
        return this.fontLoadingState[fontLoadingStateKey];
    },
    loadCSS(fontLoadingStateKey, fontsToLoad) {
        if (fontsToLoad.size) {
            return new Promise((resolve, reject) => {
                const fontString = [...fontsToLoad].join('|');
                const fontURL = 'https://fonts.googleapis.com/css?family=' + fontString;
                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.href = fontURL;
                link.onload = () => {
                    // font is loaded so we need to update loading state
                    this.fontLoadingState[fontLoadingStateKey] = true;
                    fontsToLoad.forEach(font => this.fontLoadingState[font] = true);
                    resolve();
                };
                link.onerror = reject;
                document.head.appendChild(link);
            });
        }
        return true;
    },
    partialFontLoadingState: {},
    loadPartialFont(font, string) {
        if (this.fontLoadingState[font]) {
            // if we have loaded the full font no point in loading a partial one
            return this.fontLoadingState[font];
        }
        if (this.partialFontLoadingState[font]) {
            return this.partialFontLoadingState[font];
        }
        const promise = this.loadPartialCSS(font, string);
        this.partialFontLoadingState[font] = promise;
        return promise;
    },
    loadPartialCSS(font, string) {
        return new Promise((resolve, reject) => {
            const fontURL = 'https://fonts.googleapis.com/css?family=' + font + '&text=' + string;
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = fontURL;
            link.onload = () => {
                // font is loaded so we need to update loading state
                this.partialFontLoadingState[font] = true;
                resolve();
            };
            link.onerror = reject;
            document.head.appendChild(link);
        });
    }
}

export function usePartialFontLoader(fontFamily, text) {
    // making font manager aware of
    const loadFonts = useCallback(() => FontManager.loadPartialFont(fontFamily, text), [fontFamily, text]);
    const { loading } = useAsyncCallback(loadFonts, { autoCall: true });
    return {
        loading,
    };
}

export function useFontOptions() {
    const {
        result: fonts,
        loading,
        error,
        callback: load,
    } = useAsyncCallback(GoogleFonts.getWebFonts);
    const options = fonts && fonts.items.map(font => font.family).sort();
    return {
        options: options || [],
        loading,
        error,
        load,
    };
}

export function useFontLoader(fonts) {
    // making font manager aware of
    const loadFonts = useCallback(() => FontManager.loadFonts(fonts), [fonts]);
    const { loading: fontsLoading } = useAsyncCallback(loadFonts, { autoCall: true });
    return {
        fontsLoading
    };
};

export function usePostcardFontLoader(state) {
    const {
        front: {
            layers: frontLayers = EMPTY_LAYERS
        } = {},
        back: {
            layers: backLayers = EMPTY_LAYERS
        } = {},
    } = state || {};

    const fontList = useMemo(() => {
        const fonts = new Set();
        const tallyFonts = layer => layer.fontFamily && fonts.add(layer.fontFamily);
        frontLayers.forEach(tallyFonts);
        backLayers.forEach(tallyFonts);
        return fonts;
    }, [frontLayers, backLayers]);

    return useFontLoader(fontList);
}

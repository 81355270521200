import { styled } from '@mui/material/styles';
import React from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { useQueryParamsRead } from '../hooks/useQueryParams';
import useUserAccount from '../hooks/useUserAccount';
import ErrorSplash from './ErrorSplash';
import Loader from './Loader';
import Login from './Login';
import Logout from './Logout';
import Splash from './Splash';

const HTTP_ES_FORBIDDEN = 403;

const styles = ({ theme }) => ({
    height: '100%',
    display: 'flex',
    '& > *': {
        flexGrow: 1,
        width: '50%'
    },
    '& .presentation': {
        backgroundColor: theme.palette.background.default,
    },
    '& .error-splash': {
        borderRadius: 5,
    }
});

function Authenticated(props) {
    const { className, children } = props;
    const {
        account,
        account: {
            user
        } = {},
        loading,
        error
    } = useUserAccount();
    // console.log('GOT', account, user);
    const onLoginPage = useMatch('/login');
    const onLogoutPage = useMatch('/logout');
    const {
        search,
        pathname,
        searchParams: {
            redirect
        }
    } = useQueryParamsRead();

    if (error && error?.response?.status !== HTTP_ES_FORBIDDEN) {
        return (
            <div className={className}>
                <Splash>
                    <ErrorSplash className="error-splash" elevation={5}>
                        {error?.response?.status}: {error?.message || error?.response?.statusText || "Unknown Error"}
                        <br />
                        {error?.code || "No Code =("}
                        <br />
                        {`${error?.response?.data}` || "..."}
                    </ErrorSplash>
                </Splash>
            </div>
        );
    }

    if (user && user.isAuthenticated && !onLogoutPage) {
        if (onLoginPage) {
            if (redirect) {
                return (
                    <Navigate to={redirect} />
                );
            }
            return (
                <Navigate to="/" />
            );
        }
        return children;
    }

    const redirectSearch = (new URLSearchParams({ redirect: pathname + search })).toString();

    return (
        <div className={className}>
            {/* <div className="presentation">
                Ad or Playful Art
            </div> */}
            <Splash>
                {loading ? <Loader size={100} color="inherit" /> : (
                    // <pre>{JSON.stringify(search, null, 4)}</pre>
                    <Routes>
                        <Route exact path="/register" />
                        <Route exact path="/login" element={<Login />} />
                        {user?.isAuthenticated && (<Route exact path="/logout" element={<Logout />} />)}
                        <Route path="*"
                            element={
                                <Navigate to={{
                                    pathname: '/login',
                                    search: '?' + redirectSearch
                                }} />
                            }
                        />
                    </Routes>
                )}
            </Splash>
        </div >
    );
}

export default styled(Authenticated, { name: 'MPSplash' })(styles);

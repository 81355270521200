import { DragHandleRounded } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Tooltip } from '@mui/material';
import AccordionActions from '@mui/material/AccordionActions';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Icon from 'components/Icon';
import useInterval from 'hooks/useInterval';
import React, { useCallback, useRef } from 'react';
import LayerPreview from './LayerPreview';

const styles = ({ theme }) => ({
    '&.container': {
        maxHeight: '300px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarColor: `${theme.palette.primary.light} ${theme.palette.divider}`,
        '& > div:nth-of-type(even)': {
            backgroundColor: 'rgba(0,0,0, 0.04)'
        }
    },
    '&.topShadow': {
        background: 'linear-gradient(0deg, rgba(0,0,0,0) 96%, rgba(0,0,0,0.10) 99%, rgba(0,0,0,0.22) 100%)',
    },
    '&.bottomShadow': {
        background: 'linear-gradient(180deg, rgba(0,0,0,0) 96%, rgba(0,0,0,0.10) 99%, rgba(0,0,0,0.22) 100%)',
    },
    '&.topShadow.bottomShadow': {
        background: 'linear-gradient(180deg, rgba(0,0,0,0) 96%, rgba(0,0,0,0.10) 99%, rgba(0,0,0,0.22) 100%), linear-gradient(0deg, rgba(0,0,0,0) 96%, rgba(0,0,0,0.10) 99%, rgba(0,0,0,0.22) 100%)',
    },
    '& .preview': {
        marginRight: theme.spacing(1),
        width: 56 + parseInt(theme.spacing(2), 10),
    },
    '& .row': {
        padding: 0,
    },
    '&.greedyText': {
        flexGrow: 1,
    },
    '& .placeholder': {
        margin: theme.spacing(1),
        border: '4px dashed ' + theme.palette.primary.light,
        padding: theme.spacing(.5),
        color: theme.palette.primary.light,
        fontWeight: 'bold',
    },
    '& .dragging': {
        transition: 'none',
        backgroundColor: theme.palette.background.paper,
        zIndex: 100,
        boxShadow: theme.shadows[3],
        // borderRadius: '50px',
        // overflow: 'hidden',
    },
    '& .dropped': {

    },
    '& .insertAbove': {
        borderTop: '4px dashed ' + theme.palette.primary.light,
    },
    '& .insertBelow': {
        borderBottom: '4px dashed ' + theme.palette.primary.light,
    },
});

const layerErrorCount = (accumulator, currentValue) => accumulator + Object.keys(currentValue).length;
const ignoreEvent = event => event.stopPropagation() && false;
const AUTO_SCROLL_INTERVAL = 50;
const AUTO_SCROLL_AMOUNT = 5;

function useDragAndDropOrganizer(updateLayerOrder) {
    const listContainerRef = useRef();
    const draggingItem = useRef(false);
    const lastMouseYRef = useRef();
    const autoScrollUp = useCallback(
        () => listContainerRef.current.scrollBy(0, -AUTO_SCROLL_AMOUNT),
        [listContainerRef]
    );
    const {
        activate: activateUpScroll,
        deactivate: deactivateUpScroll,
    } = useInterval(autoScrollUp, AUTO_SCROLL_INTERVAL);
    const autoScrollDown = useCallback(
        () => listContainerRef.current.scrollBy(0, AUTO_SCROLL_AMOUNT),
        [listContainerRef]
    );
    const {
        activate: activateDownScroll,
        deactivate: deactivateDownScroll,
    } = useInterval(autoScrollDown, AUTO_SCROLL_INTERVAL);

    const handleDragStart = useCallback((event) => {
        // event.preventDefault();
        // event.stopPropagation();
        draggingItem.current = parseInt(event.currentTarget.dataset.index, 10);
        [...listContainerRef.current.children].forEach(elem => {
            const elemIndex = parseInt(elem.dataset.index, 10);
            if (elemIndex === draggingItem.current) {
                elem.classList.add('dragging');
            }
        });
        return false;
        // dragStartRef.current = event.clientY;
        // event.dataTransfer.setData("indexToMove", ;
    }, [draggingItem]);

    const handleDragging = useCallback((event) => {
        // event.preventDefault();
        // event.stopPropagation();
        // console.log(event);
        if (draggingItem.current !== false) {
            listContainerRef.current.style.overflowY = 'hidden';
            let mouseY = event?.clientY || event?.touches?.[0]?.clientY;
            let currentContainer = listContainerRef.current;
            while (currentContainer !== document.body) {
                mouseY -= (currentContainer.offsetTop - currentContainer.scrollTop);
                currentContainer = currentContainer.offsetParent;
            }
            lastMouseYRef.current = [mouseY, listContainerRef.current.scrollTop];

            const mousePortLocation = mouseY - listContainerRef.current.scrollTop;
            if (mousePortLocation < 50) {
                // auto scroll at the top
                // listContainerRef.current.scrollBy(0, -1);
                activateUpScroll();
            } else if (mousePortLocation > listContainerRef.current.offsetHeight - 50) {
                activateDownScroll();
            } else {
                deactivateUpScroll();
                deactivateDownScroll();
            }

            const proxyHeight = listContainerRef.current.children[0].offsetHeight;
            const dropIndex = Math.floor(mouseY / proxyHeight);

            [...listContainerRef.current.children].forEach(elem => {
                const elemIndex = parseInt(elem.dataset.index, 10);

                let transform = 'translate(0px, 0px)';
                // elem.classList.remove('dragging');
                elem.style.removeProperty('transition');
                if (elemIndex === draggingItem.current) {
                    // case 1) the dragged item
                    const diff = mouseY - (elem.offsetTop + elem.offsetHeight / 2);
                    transform = `translate(10%, ${diff}px) scale(0.8)`;
                    // elem.classList.add('dragging');
                    // const offset = draggingItem.current
                } else if (elemIndex > dropIndex && elemIndex < draggingItem.current) {
                    transform = `translate(0px, ${elem.offsetHeight}px)`;
                } else if (elemIndex < dropIndex && elemIndex > draggingItem.current) {
                    transform = `translate(0px, ${-elem.offsetHeight}px)`;
                } else if (elemIndex === dropIndex) {
                    if (elemIndex > draggingItem.current) {
                        transform = `translate(0px, ${-elem.offsetHeight}px)`;
                    } else if (elemIndex < draggingItem.current) {
                        transform = `translate(0px, ${elem.offsetHeight}px)`;
                    }
                }

                elem.style.transform = transform;
            });
        }
    }, [listContainerRef, draggingItem, activateUpScroll, activateDownScroll, deactivateUpScroll, deactivateDownScroll]);

    const handleScrolling = useCallback(event => {
        if (draggingItem.current) {
            const [lastMouseY, lastContainerScrollTop] = lastMouseYRef.current;
            [...listContainerRef.current.children].forEach(elem => {
                const elemIndex = parseInt(elem.dataset.index, 10);
                if (elemIndex === draggingItem.current) {
                    const scrollDiff = lastContainerScrollTop - listContainerRef.current.scrollTop;
                    const diff = (lastMouseY - scrollDiff) - (elem.offsetTop + elem.offsetHeight / 2);
                    elem.style.transform = `translate(10%, ${diff}px) scale(0.8)`;
                }
            });
        }
    }, [draggingItem, lastMouseYRef, listContainerRef]);

    const handleDrop = useCallback((event) => {
        deactivateUpScroll();
        deactivateDownScroll();
        if (draggingItem.current !== false) {
            listContainerRef.current.style.overflowY = 'auto';
            let mouseY = event.clientY || event?.changedTouches?.[0]?.clientY;;
            let currentContainer = listContainerRef.current;
            while (currentContainer !== document.body) {
                mouseY -= (currentContainer.offsetTop - currentContainer.scrollTop);
                currentContainer = currentContainer.offsetParent;
            }

            const proxyHeight = listContainerRef.current.children[0].offsetHeight;
            const dropIndex = Math.floor(mouseY / proxyHeight);
            updateLayerOrder(draggingItem.current, dropIndex);

            // TODO: smoother animations begining and end
            [...listContainerRef.current.children].forEach(elem => {
                elem.classList.remove('dragging');
                elem.style.transition = 'none';
                elem.style.transform = 'translate(0px, 0px)';
            });
        }
        draggingItem.current = false;
    }, [draggingItem, listContainerRef, updateLayerOrder, deactivateUpScroll, deactivateDownScroll]);

    return {
        containerProps: {
            ref: listContainerRef,
            onMouseMove: handleDragging,
            onTouchMove: handleDragging,
            onMouseUp: handleDrop,
            onTouchEnd: handleDrop,
            onScroll: handleScrolling,
        },
        grippyProps: (index) => ({
            'data-index': index,
            onMouseDown: handleDragStart,
            onTouchStart: handleDragStart,
            onClick: ignoreEvent
        }),
        itemProps: (index) => ({
            'data-index': index,
        })
    };
}



function LayerProperties(props) {
    const { className, layers, layerSelection, pageErrors, viewport } = props;
    const {
        deleteLayer,
        updateLayers,
        deleteSelection,
        hasSelection,
        selection,
        bringSelectionForward,
        bringSelectionToFront,
        bringSelectionBackward,
        bringSelectionToBack,
        updateLayerOrder,
    } = layerSelection;


    const update = event => layerSelection.updateSelection({ [event.target.name]: event.target.value });
    const canDelete = hasSelection;
    const canBringForward = selection.length === 1 && selection[0] < layers.length - 1;
    const canBringBackward = selection.length === 1 && selection[0] > 0;
    const updateLayerOrderReverse = useCallback((location, newLocation) => {
        const locationId = layers.length - (location + 1);
        const newLocationId = layers.length - (newLocation + 1);
        updateLayerOrder(locationId, newLocationId);
    }, [updateLayerOrder, layers]);

    const {
        containerProps,
        grippyProps,
        itemProps
    } = useDragAndDropOrganizer(updateLayerOrderReverse);

    const classes = [className];
    if (containerProps.ref.current?.scrollTop > 0) {
        classes.push('topShadow');
    }
    if (
        containerProps.ref.current &&
        containerProps.ref.current?.scrollTop !== containerProps.ref.current?.scrollHeight - containerProps.ref.current?.offsetHeight
    ) {
        classes.push('bottomShadow');
    }

    const handleOnScroll = containerProps.onScroll;
    containerProps.onScroll = (event) => {
        handleOnScroll(event);
        if (containerProps.ref.current.scrollTop > 0) {
            containerProps.ref.current.classList.add('topShadow');
        } else {
            containerProps.ref.current.classList.remove('topShadow');
        }
        if (containerProps.ref.current.scrollTop !== containerProps.ref.current.scrollHeight - containerProps.ref.current.offsetHeight) {
            containerProps.ref.current.classList.add('bottomShadow');
        } else {
            containerProps.ref.current.classList.remove('bottomShadow');
        }
    };

    return (
        <>
            <List
                className={`${classes.join(' ')} container`}
                {...containerProps}
            >
                {[...layers].reverse().map((layer, layerIndex) => {
                    const layerId = layers.length - (layerIndex + 1);
                    const uniqueIdForLayerNeeded = layerIndex; // one that doesn't change with ORDER
                    // const thisPreventsBugsButCausesOthers = `${layerIndex}-${layer.label}`; // like breakds label editing

                    return (
                        <Box
                            key={uniqueIdForLayerNeeded}
                            {...itemProps(layerIndex)}
                            sx={{
                                boxShadow: theme => theme.shadows[0],
                                transition: theme => theme.transitions.create(['transform', 'boxShadow']),
                                borderBottomStyle: 'dashed',
                                borderBottomColor: theme => theme.palette.error.dark,
                                borderBottomWidth: Object.keys(pageErrors?.layers?.[layerId] || {}).length
                                    ? 2
                                    : 0,
                                borderTopStyle: 'dashed',
                                borderTopColor: theme => theme.palette.error.dark,
                                borderTopWidth: Object.keys(pageErrors?.layers?.[layerId] || {}).length
                                    ? 2
                                    : 0,
                                backgroundColor: Object.keys(pageErrors?.layers?.[layerId] || {}).length
                                    ? theme => theme.palette.error.light
                                    : 'none'
                            }}>
                            <ListItemButton
                                className="row"
                                selected={layerSelection.isItemSelected(layerId)}
                                onClick={() => layerSelection.selectItem(layerId)}
                                onDoubleClick={() => viewport.zoomTo(layer)}
                            >
                                <ListItemAvatar className="preview">
                                    <LayerPreview layer={layer} />
                                </ListItemAvatar>
                                <ListItemText>
                                    <InputBase
                                        name="label"
                                        placeholder="Layer Name"
                                        value={layer.label}
                                        onChange={update}
                                    // dirty={isDirty}
                                    // error={}
                                    // helperText={}
                                    />
                                </ListItemText>
                                {layer.locked ? (
                                    <IconButton
                                        size="small"
                                        onClick={event => {
                                            event.stopPropagation();
                                            updateLayers(layerId, {
                                                locked: false,
                                            });
                                        }}>
                                        <LockIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        size="small"
                                        onClick={event => {
                                            event.stopPropagation();
                                            updateLayers(layerId, {
                                                locked: true,
                                            });
                                        }}>
                                        <LockOpenIcon />
                                    </IconButton>
                                )}
                                {layer.hidden ? (
                                    <IconButton
                                        size="small"
                                        onClick={event => {
                                            event.stopPropagation();
                                            updateLayers(layerId, {
                                                hidden: false,
                                            });
                                        }}>
                                        <VisibilityOffIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        size="small"
                                        onClick={event => {
                                            event.stopPropagation();
                                            updateLayers(layerId, {
                                                hidden: true,
                                            });
                                        }}>
                                        <VisibilityIcon />
                                    </IconButton>
                                )}
                                <IconButton {...grippyProps(layerIndex)}>
                                    <DragHandleRounded />
                                </IconButton>
                            </ListItemButton>
                        </Box>
                    );
                })}
            </List>
            <AccordionActions>
                <span className={`${className} greedyText`}>{layers.length} Layers</span>
                <IconButton
                    disabled={!canBringBackward}
                    size="small"
                    onClick={bringSelectionBackward}
                >
                    <Tooltip arrow title="Send Backward">
                        <Icon name="bringBackward" />
                    </Tooltip>
                </IconButton>
                <IconButton
                    disabled={!canBringForward}
                    size="small"
                    onClick={bringSelectionForward}
                >
                    <Tooltip arrow title="Bring Forward">
                        <Icon name="bringForward" />
                    </Tooltip>
                </IconButton>
                <IconButton
                    disabled={!canBringBackward}
                    size="small"
                    onClick={bringSelectionToBack}
                >
                    <Tooltip arrow title="Send To Back">
                        <Icon name="bringToBack" />
                    </Tooltip>
                </IconButton>
                <IconButton
                    disabled={!canBringForward}
                    size="small"
                    onClick={bringSelectionToFront}
                >
                    <Tooltip arrow title="Bring To Front">
                        <Icon name="bringToFront" />
                    </Tooltip>
                </IconButton>
                <IconButton
                    disabled={!canDelete}
                    size="small"
                    onClick={deleteSelection}
                >
                    <Tooltip arrow title="Delete">
                        <Icon name="delete" />
                    </Tooltip>
                </IconButton>
            </AccordionActions >
        </>
    );
}

export default styled(LayerProperties)(styles);

import DetailLayout from 'components/DetailLayout';
import { MODE_WRITE } from 'components/Field';
import React from 'react';


const BORDER_LAYOUT = [
    ['borderWidth', 'borderRadius'],
    ['borderStyle', 'padding'],
    'borderColor'
];

export const BORDER_PROPERTIES = BORDER_LAYOUT.flat();

export default function BorderProperties(props) {
    const { className, disabled, properties, layerErrors, onChange, describe } = props;
    return (
        <DetailLayout
            className={className}
            layout={BORDER_LAYOUT}
            value={properties}
            error={layerErrors}
            onChange={onChange}
            disabled={disabled}
            describe={describe}
            xs={6}
            FieldProps={{
                variant: 'filled',
                size: 'small',
                mode: MODE_WRITE,
            }}
        />
    );
}

import { Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import Field from './Field';

const styles = ({ theme }) => ({
    root: {}
});

function DetailLayout(props) {
    const {
        // style
        className,
        columnSpacing,
        rowSpacing,
        spacing = 1,
        // structure
        layout,
        // input capabilities
        value,
        onChange,
        isDirty,
        describe,
        disabled,
        error,
        FieldProps = {},
        onUndo,
        loading,
        xs = 12,
    } = props;


    return (
        <Grid className={className} container spacing={spacing} columnSpacing={columnSpacing} rowSpacing={rowSpacing}>
            {
                layout.map((row, rowId) => {
                    const normalizedRow = Array.isArray(row) ? row : [row];
                    return normalizedRow.map((column, columnId) => {
                        const {
                            cellProps = {},
                            Component = typeof column === 'function'
                                ? column
                                : Field,
                            ...normalizedFieldProps
                        } = typeof column === 'object'
                                ? column
                                : typeof column === 'function'
                                    ? {}
                                    : { name: column };

                        const handleUndo = onUndo && (
                            typeof column === 'function'
                                ? onUndo
                                : () => onUndo(normalizedFieldProps.name)
                        );
                        const fieldDescription = typeof column === 'function'
                            ? describe?.()
                            : describe?.(normalizedFieldProps.name);

                        normalizedFieldProps.value = value;
                        normalizedFieldProps.error = error;
                        normalizedFieldProps.name?.split('.').forEach(valueKey => {
                            normalizedFieldProps.value = valueKey in (normalizedFieldProps?.value || {})
                                ? normalizedFieldProps.value[valueKey]
                                : '';
                            normalizedFieldProps.error = normalizedFieldProps.error?.[valueKey] || undefined;
                        });

                        // misc display variables
                        const rows = FieldProps.rows || normalizedFieldProps.rows || 1;
                        const opacity = 1 - (rowId - 1) / layout.length;
                        normalizedFieldProps.fullWidth = true;
                        normalizedFieldProps.margin = 'none';
                        // if (Component === Field) {
                        // }

                        return (
                            <Grid
                                item
                                key={`r${rowId}-c${columnId}:${normalizedFieldProps.name}`}
                                xs={normalizedRow.length > 1 ? xs : 12}
                                sm={Math.floor(12 / normalizedRow.length)}
                                style={{ opacity: loading ? opacity : 1 }}
                                {...cellProps}
                            >
                                {
                                    loading ? (
                                        <>
                                            <Skeleton
                                                variant="rounded"
                                                animation="wave"
                                                height={8}
                                                width={80}
                                                sx={{ marginBottom: 0.5 }}
                                            />
                                            <Skeleton
                                                variant="rounded"
                                                animation="wave"
                                                height={19 + 23 * rows}
                                            />
                                        </>
                                    ) : (
                                        <Component
                                            describe={describe}
                                            description={fieldDescription}
                                            isDirty={isDirty}
                                            onChange={onChange}
                                            disabled={disabled}
                                            onUndo={handleUndo}
                                            {...FieldProps}
                                            {...normalizedFieldProps}
                                        />
                                    )
                                }
                            </Grid >
                        );
                    });
                })
            }
        </Grid >
    );
}

export default styled(DetailLayout)(styles);

import styled from "@emotion/styled";
import { Fab } from "@mui/material";
import Icon from "components/Icon";
import Plate from "./Plate";

const styles = ({ theme }) => ({
    "& .plateFab": {
        position: 'absolute',
        top: 0,
        right: theme.spacing(1),
        transform: "translate(0px, -50%)",
    }
});

function PostcardPlate(props) {
    const {
        ...forwardProps
    } = props

    return (
        <Plate {...forwardProps}>
            <Fab className="plateFab" size="large" color="secondary">
                <Icon name="send" />
            </Fab>
        </Plate>
    );
}

export default styled(PostcardPlate)(styles);

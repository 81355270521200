import AddContactIcon from '@mui/icons-material/PersonAddAltRounded';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useMojipostClient } from '../connectors/MojipostClient';
import usePersistentStateObject, { POST_SAVE_BAHAVIOR_CLEAR } from '../hooks/usePersistentStateObject';
import { CREATE_LAYOUT } from '../layouts/ContactLayouts';
import DetailLayout from './DetailLayout';
import { MODE_WRITE } from './Field';
import Map from './Map';
import Marker from './Marker';

export default function NewContactDialog(props) {
    const { onClose, ...forwardProps } = props;
    const client = useMojipostClient();
    const {
        state,
        loading,
        updateState,
        isDirty,
        resetState,
        save,
        saving,
        describe,
        fieldErrors,
        resetStatePath,
        cancel,
    } = usePersistentStateObject({
        onSave: onClose,
        onCreate: client.postAddress,
        onDescribe: client.describeAddress,
        postSaveBehavior: POST_SAVE_BAHAVIOR_CLEAR
    });

    const cancelDialog = () => {
        cancel();
        onClose();
    };

    const disableForm = loading || saving;
    const formStateIsDirty = isDirty();

    return (
        <Dialog maxWidth="md" {...forwardProps} onClose={cancelDialog} >
            <Box sx={{ display: 'flex' }}>
                <Box>
                    <Map style={{ width: '350px', height: '100%' }}>
                        <Marker center address={state} />
                    </Map>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                        <AddContactIcon />
                        Add Contact
                    </DialogTitle>
                    <DialogContent sx={{ overflow: 'visible' }}>
                        <DetailLayout
                            spacing={2}
                            layout={CREATE_LAYOUT}
                            value={state}
                            onChange={updateState}
                            isDirty={isDirty}
                            onUndo={resetStatePath}
                            describe={describe}
                            disabled={disableForm}
                            error={fieldErrors}
                            FieldProps={{
                                mode: MODE_WRITE,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={disableForm}
                            color="primary"
                            variant="outlined"
                            onClick={cancelDialog}
                        >Cancel</Button>
                        {formStateIsDirty && (
                            <Button
                                onClick={resetState}
                                disabled={disableForm}
                            >Reset</Button>
                        )}
                        <div style={{ flexGrow: 1 }} />
                        <Button
                            disabled={disableForm}
                            color="primary"
                            variant="contained"
                            onClick={save}
                        >Save</Button>
                    </DialogActions>
                </Box>
            </Box>
        </Dialog>
    );
}

import styled from "@emotion/styled";
import usePersistentStateObject from "hooks/usePersistentStateObject";
import { forwardRef } from "react";

const styles = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[8],
    '& > .title': {
        flexGrow: 1,
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.5),
        ...theme.typography.h5
    },
});

function Plate(props, ref) {
    const {
        children,
        recordId,
        onGet,
        onDescribe,
        addListener,
        removeListener,
        ...forwardProps
    } = props;

    const {
        state: record,
        loading,
        loadError,
    } = usePersistentStateObject({
        id: recordId,
        onGet,
        onDescribe,
        addListener,
        removeListener,
        // autoLoad: Boolean(recordId),
    });

    const hasData = Boolean(!loading && !loadError && record);
    const titleText = hasData ? record.name : 'Loading...';

    return (
        <div {...forwardProps} ref={ref}>
            <div className="title">{titleText}</div>
            {children}
        </div>
    );
}

export default styled(forwardRef(Plate))(styles);

import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useMemo } from 'react';
import Canvas from './Canvas';

const styles = ({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(1),
    '.canvas': {
        flexGrow: 1,
        ...theme.shape,
        background: 'rgba(0, 0, 0, .7)',
        marginRight: theme.spacing(1),
        border: '1px solid ' + theme.palette.divider,
        position: 'relative',
        overflow: 'hidden',
    },
    ".switcher": {
        display: 'flex',
        flexDirection: 'column',
        '.item': {
            transition: theme.transitions.create('all'),
            opacity: '65%',
            flexGrow: 1,
            'img': {
                ...theme.shape,
            },
            ':not(:last-child)': {
                marginBottom: theme.spacing(1),
            },
            '&.selected': {
                opacity: '100%'
            }
        }
    },
    ".screen": {
        border: '10px solid white',
        // background: 'rgba(255,255,255,0.2)',
        position: 'absolute',
    }
});

const layerErrorCount = (accumulator, currentValue) => accumulator + Object.keys(currentValue).length;

function PostcardCanvasSwitcher(props) {
    const {
        className,
        preview,
        onFocusFront,
        onFocusBack,
        currentPageIdentifier,
        viewport: {
            previewRef, updateViewport, switcherPreview, animations
        },
        layers,
        layerSelection,
        fieldErrors,
        state,
    } = props;

    useEffect(() => {
        if (!switcherPreview) {
            // updateViewport has the ability to calculate the switcherPreview
            // but the previewRef needs to be assigned a mounted component before it can be calculated
            // so this effect is designed to update the viewport on mount
            updateViewport(x => x);
        }
    }, [switcherPreview, updateViewport]);

    const {
        frontErrorCount,
        backErrorCount,
    } = useMemo(() => ({
        frontErrorCount: fieldErrors?.front?.layers?.reduce(layerErrorCount, 0) || 0,
        backErrorCount: fieldErrors?.back?.layers?.reduce(layerErrorCount, 0) || 0,
    }), [fieldErrors]);

    const frontImageClasses = [
        'item',
        currentPageIdentifier === 'front' ? 'selected' : ''
    ].join(' ').trim();
    const backImageClasses = [
        'item',
        currentPageIdentifier === 'back' ? 'selected' : ''
    ].join(' ').trim();

    const onMouseDown = useCallback(() => {

    }, []);

    return (
        <div className={className}>
            <div className="canvas" ref={previewRef} onMouseDown={onMouseDown}>
                {switcherPreview && (
                    <Canvas
                        label={currentPageIdentifier}
                        // ref={canvasRef}
                        style={{
                            ...switcherPreview.viewportMatrix.css,
                        }}
                        // preview={true}
                        layers={layers}
                        layerSelection={layerSelection}
                    >
                        <div className="screen" style={{
                            transition: animations ? undefined : 'none',
                            width: switcherPreview.screen.width + 'in',
                            height: switcherPreview.screen.height + 'in',
                            top: switcherPreview.screen.y + 'in',
                            left: switcherPreview.screen.x + 'in',
                            transform: 'rotate(' + switcherPreview.screen.rotation + 'rad)',
                            // zIndex: '-1',
                            background: 'rgba(255,255,255,0.1)',
                        }}></div>
                    </Canvas>
                )}
            </div>
            <div className="switcher">
                <Badge
                    className={frontImageClasses}
                    badgeContent={frontErrorCount}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <div style={{ width: '118px', height: '85px' }} onClick={onFocusFront}>
                        <Canvas
                            // ref={canvasRef}
                            style={{
                                transform: 'scale(.2)'
                                // ...switcherPreview.viewportMatrix.css,
                            }}
                            preview={true}
                            layers={state.front.layers}
                            label="front"
                        >
                        </Canvas>
                    </div>
                </Badge>
                <Badge
                    className={backImageClasses}
                    badgeContent={backErrorCount}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    {/* <Image
                        width={118}
                        height={85}
                        src={preview?.back?.small}
                        onClick={onFocusBack}
                        disableLoadingText
                    /> */}
                    <div style={{ width: '118px', height: '85px' }} onClick={onFocusBack}>
                        <Canvas
                            // ref={canvasRef}
                            style={{
                                transform: 'scale(.2)'
                                // ...switcherPreview.viewportMatrix.css,
                            }}
                            preview={true}
                            layers={state.back.layers}
                        >
                        </Canvas>
                    </div>
                </Badge>
            </div>
        </div >
    );
}

export default styled(PostcardCanvasSwitcher)(styles);

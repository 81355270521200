export default function clamp(value, {
    min = null, max = null, wrap = false
}) {
    if (wrap) {
        // TODO update this to handle cases where min != 0
        if (min === 0 && max !== null) {
            value %= max;
            while (value < 0) value += max;
        } else {
            throw new Error('Functionality does not exist yet.');
        }
    } else {
        if (value > max)
            value = max;
        if (value < min)
            value = min;
    }
    return value;
}

export function createClamp({ min = null, max = null, wrap = false }) {
    return value => clamp(value, { min, max, wrap });
}

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import Layer from './Layer';

const styles = ({ theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: '#fff',
    boxShadow: theme.shadows[8],
    color: '#000',
    touchAction: 'none',
    userSelect: 'none',
    transformOrigin: '0px 0px',
    '&.preview': {
        overflow: 'hidden',
    },
    '&.postcard': {
        width: '6.25in',
        height: '4.5in',
        minWidth: '6.25in',
        minHeight: '4.5in',
        borderRadius: '.25in',
    },
    '& .label': {
        fontSize: 30,
        position: 'absolute',
        top: '100%',
        left: 0,
        color: 'rgba(255,255,255,0.5)',
        fontWeight: 'bold',
    },
    '.guide-border': {
        position: 'absolute',
        top: '0px',
        left: '0px',
        outline: '3px solid rgba(255,255,255,0.5)',
        width: '6.25in',
        height: '4.5in',
        minWidth: '6.25in',
        minHeight: '4.5in',
        borderRadius: '.25in',
        // zIndex: 500,
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    '.bleed': {
        position: 'absolute',
        top: '.125in',
        left: '.125in',
        width: '6in',
        height: '4.25in',
        borderRadius: '.2in',
        boxShadow: '0 0 0 9999px rgba(255, 255, 125, 0.5)'
    },
    '.post-label': {
        position: 'absolute',
        left: '2.64in',
        top: '2.17in',
        width: '3.65in',
        height: '1.65in',
        backgroundColor: 'rgba(255, 125, 125, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '& .hidden': {
        opacity: 0,
    }
});
const VARIANTS = ['postcard'];

function Canvas(props, ref) {
    const {
        className,
        label,
        top, bottom,
        children,
        variant = 'postcard',
        layers,
        preview,
        interactive,
        layerSelection,
        selectionExists,
        guides,
        postage,
        ...forwardProps
    } = props;
    const rootClass = clsx(className, {
        [variant]: VARIANTS.includes(variant),
        preview
    });
    // return null;

    return (
        <div
            className={rootClass}
            data-type="canvas"
            ref={ref}
            {...forwardProps}>
            {/*
                First set of layers
                These layers are what the user sees
            */}
            {layers.map((layer, index) => (
                <Layer
                    preview={preview}
                    key={`${label}${index}`}
                    id={index}
                    attributes={layer}
                    selected={layerSelection?.isItemSelected(index)}
                    selectionExists={layerSelection?.hasSelection}
                />
            ))}
            {/*
                visual guides
                visual guides may obstruct the interactivity of the visible layers.
            */}
            <div
                className="guide-border"
                data-type="canvas"
            >
                {guides && (
                    <>
                        <div
                            className="bleed"
                            data-type="canvas"
                        />
                        {postage && (
                            <div
                                className="post-label"
                                data-type="canvas"
                            >
                                <Typography variant="caption">This space is reserved for postage.</Typography>
                            </div>
                        )}
                    </>
                )}
            </div>
            {label && (<div className="label">{label}</div>)}
            {/*
                hidden second set of layers
                invisible but interactive!
            */}
            {interactive && layers.map((layer, index) => (
                <Layer
                    className="hidden"
                    preview={preview}
                    key={index}
                    id={index}
                    attributes={layer}
                    selected={layerSelection.isItemSelected(index)}
                    selectionExists={layerSelection.hasSelection}
                    onChange={layerSelection.up}
                />
            ))}
            {children}
        </div>
    );
}

export default styled(forwardRef(Canvas))(styles);

import DeleteIcon from '@mui/icons-material/Delete';
import DeSelectAllIcon from '@mui/icons-material/Deselect';
import EditIcon from '@mui/icons-material/Edit';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useAsyncCallback from '../hooks/useAsyncCallback';
import useBrowserSize from '../hooks/useBrowserSize';
import usePersistentSet from '../hooks/usePersistentSet';
import useSelection from '../hooks/useSelection';
import AppCard from './AppCard';
import Loader from './Loader';


const SetToolbar = styled(({ className, active, ...props }) => (
    <Toolbar
        className={clsx(className, { active })}
        {...props} />
))(({ theme }) => ({
    zIndex: 100,
    color: theme.palette.text.primary,
    transition: theme.transitions.create('all'),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    top: 0,
    position: 'sticky',
    // zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid ' + theme.palette.divider,
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginRight: 0,
    },
    '&.active': {
        top: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[4],
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            borderRadius: 0,
            top: 0,
        }
    }
}));

const AppCardGrid = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    flexGrow: 1,
    alignContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
        // padding: 0,
        // paddingBottom: 100,
    }
}));

const SetTitle = styled(({ title, selected, ...props }) => (
    <Typography variant="h5" color="inherit" {...props}>
        {title} {selected > 0 && `(${selected})`}
    </Typography>
))(({ theme }) => ({
    flexGrow: 1,
}));

const maxWidth = 235;
const spacing = 16;
const gridPadding = 8

function getTileWidth({ current: { clientWidth: stageWidth } = {} }) {
    const stageArea = stageWidth - gridPadding * 2;
    const tileSpace = spacing;
    const maxTileArea = maxWidth + tileSpace;
    const tilesPerRow = Math.ceil(stageArea / maxTileArea);
    const tileWidth = Math.floor(stageArea / tilesPerRow) - tileSpace;
    // console.log({ maxWidth, tileSpace, stageArea, maxTileArea, stageArea, tilesPerRow, tileWidth, takenSpace: tilesPerRow * (tileWidth) })
    return tileWidth;
}

const styles = ({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('filter'),
    '&.disabled': {
        filter: 'grayscale(100%)'
    }
});

const EMPTYARRAY = [];

function PersistentSetManager(props) {
    const {
        className,
        children,
        onGet,
        onEdit,
        onChange,
        onDelete,
        disabled,
        value,
        title,
        multiple = false,
        persistentSetRef,
        cardGridPropertyMapper = item => item,
    } = props;
    const persistentSet = usePersistentSet(onGet, { initialPageSize: 20, autoCall: true, ...props });
    const {
        result,
        refresh,
        // nextPage,
        loading,
        currentPage,
        totalPages,
        // currentPageSize,
        // lastPageNumber,
        // hasNextPage,
        // hasPreviousPage,
        // previousPage,
        gotoPage,
    } = persistentSet;
    if (persistentSetRef) {
        persistentSetRef.current = persistentSet;
    }
    const records = result ? result.results : EMPTYARRAY;
    const allRecordIds = useMemo(() => records.map(record => record.id), [records]);
    const onSelection = useCallback((selection) => {
        if (multiple) {
            // TODO
        } else {
            onChange(selection, records.find(record => `${record.id}` === `${selection}`))
        }
    }, [onChange, records]);
    const {
        selection,
        selectionRef,
        // setSelection,
        clearSelection,
        allSelected,
        selectAll,
        toggleItem,
        isItemSelected,
        hasSelection
    } = useSelection({ multiple, allOptions: allRecordIds, onChange: onSelection, value });
    // const selectAll = useCallback(() => setSelection(records.map(item => item.id)), [setSelection, records]);
    const handleEdit = useCallback(
        () => {
            // this doesn't work if the record is not on the current page...
            // possible to lookup using api but why?
            // just pass along ids
            // if (multiple) {
            //     onEdit(records.filter(record => selectionRef.current.includes(record.id)));
            // } else {
            //     onEdit(records.find(record => `${record.id}` === `${selectionRef.current}`));
            // }
            onEdit(selectionRef.current);
        },
        //[multiple, onEdit, records, selectionRef]
        [onEdit, selectionRef]
    );
    const doDelete = useCallback(
        () => {
            if (multiple) {
                const selectedRecordIds = selectionRef.current;
                return Promise.all(selectedRecordIds.map(id => onDelete(id)));
            } else {
                const selectedRecordId = selectionRef.current;
                return onDelete(selectedRecordId);
            }
        },
        [multiple, onDelete, selectionRef]
    );
    const {
        callback: handleDelete,
        loading: deleteLoading,
        error: deleteError,
    } = useAsyncCallback(doDelete);
    const canEdit = Boolean(onEdit && selection);
    const canSelectAll = Boolean(!allSelected && multiple && !hasSelection);
    const canDeselect = Boolean(hasSelection);
    const canDelete = Boolean(onDelete && hasSelection);

    // component references
    const containerRef = useRef();
    const tileWidth = getTileWidth(containerRef);
    const showCardGrid = !loading && records.length > 0;

    useBrowserSize({ eventThrottle: 250 });

    const [currentWidth, forceUpdate] = useState(0);

    useEffect(() => {
        let intervalId = setInterval(() => {
            if (currentWidth !== containerRef?.current?.offsetWidth) {
                forceUpdate(containerRef.current.offsetWidth);
            }
        }, 250);
        return () => {
            clearInterval(intervalId);
        };
    }, [containerRef, tileWidth, forceUpdate, currentWidth]);

    const rootClass = clsx(className, { disabled });

    return (
        <div className={rootClass} ref={containerRef}>
            <SetToolbar active={hasSelection}>
                <SetTitle
                    title={title}
                    selected={selection && selection.length}
                />
                {totalPages > 1 && (
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                )}
                {totalPages === 1 && (
                    <span>
                        {records.length} items
                    </span>
                )}
                {canSelectAll && (
                    <IconButton
                        disabled={disabled}
                        color="inherit"
                        title="Select All"
                        onClick={selectAll}>
                        <SelectAllIcon />
                    </IconButton>
                )}
                {canEdit && (
                    <IconButton
                        disabled={disabled}
                        color="inherit"
                        title="Edit Selection"
                        onClick={handleEdit}>
                        <EditIcon />
                    </IconButton>
                )}
                {canDeselect && (
                    <IconButton
                        disabled={disabled}
                        color="inherit"
                        title="Deselect All"
                        onClick={clearSelection}>
                        <DeSelectAllIcon />
                    </IconButton>
                )}
                {canDelete && (
                    <IconButton
                        disabled={disabled}
                        color="inherit"
                        title="Delete Selection"
                        onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                )}
            </SetToolbar>

            {/* {records && (
                <pre>
                    {JSON.stringify({ selection, all: records.map(item => item.id), }, null, 4)}
                </pre>
            )} */}

            {children}

            {showCardGrid && (
                <AppCardGrid>
                    {records.map(record => (
                        <AppCard
                            disabled={disabled}
                            key={record.id}
                            onClick={() => toggleItem(record.id)}
                            onDoubleClick={onEdit && (() => onEdit(record.id))}
                            selected={isItemSelected(record.id)}
                            width={tileWidth}
                            {...cardGridPropertyMapper(record)}
                        />
                    ))}
                </AppCardGrid>
            )}

            {loading && (
                <Loader preText={`Loading ${title} `} />
            )}

            {totalPages > 1 && (
                <Toolbar sx={{ justifyContent: 'center' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(_, pageNumber) => gotoPage(pageNumber)}
                    />
                </Toolbar>
            )}
            {/* <div>

                <img src="https://maps.googleapis.com/maps/api/staticmap?size=600x400&markers=icon%3Ahttp%3A%2F%2Fwww.google.com%2Fmapfiles%2Farrow.png%7C41.39479%2C2.148768&visible=41.320004%2C2.069526%7C41.469576%2C2.22801&key=AIzaSyAGdK1qRHGaAmUhaLq17KABXXnRSmfb8vg" title="" />
            </div> */}
        </div>
    );
}

export default styled(PersistentSetManager)(styles);

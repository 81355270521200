import { useCallback, useRef } from "react";

export default function useInterval(callback, timeout) {
    const timeoutRef = useRef();

    const deactivate = useCallback(() => {
        if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
            timeoutRef.current = undefined;
        }
    }, [timeoutRef]);

    const activate = useCallback(() => {
        if (!timeoutRef.current) {
            timeoutRef.current = setInterval(callback, timeout);
        }
    }, [timeoutRef, callback, timeout]);

    const isActive = useCallback(() => {
        return Boolean(timeoutRef.current);
    }, [timeoutRef]);

    return {
        activate,
        deactivate,
        isActive
    }
}

import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import useUserAccount from 'hooks/useUserAccount';
import React from 'react';

const AVATAR_BOARDER_WIDTH = 2;
const styles = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textTransform: 'initial',
    '& .avatar': {
        marginRight: theme.spacing(1),
        border: AVATAR_BOARDER_WIDTH + 'px solid rgba(0,0,0,0.25)',
        '&:last-child': {
            marginRight: 0,
        }
    },
    '& .username': {
        marginRight: theme.spacing(1),
        ...theme.typography.button
    },
    '& .name': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ...theme.typography.h4
    },
    '&.profile': {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '.avatar': {
            border: '2px solid white',
            margin: theme.spacing(2),
        },
    },
});

function UserTag(props) {
    const {
        className,
        avatar = false,
        profile = false,
        username = false,
        firstName = false,
        lastName = false,
        avatarSize = 42
    } = props;
    const { account: { user, image }, loading } = useUserAccount();

    if (loading) {
        return null;
    }
    const rootClass = clsx(className, { profile });
    const avatarClass = clsx('avatar');
    return (
        <div className={rootClass}>
            {avatar && (
                <Avatar
                    className={avatarClass}
                    title={user.username}
                    sx={{
                        width: avatarSize - AVATAR_BOARDER_WIDTH,
                        height: avatarSize - AVATAR_BOARDER_WIDTH
                    }}
                    alt={user.username}
                    src={image}
                />
            )}
            {username && (<span className="username">{user.username}</span>)}
            {(
                firstName && lastName && (
                    <span className="name">{user.firstName} {user.lastName}</span>
                )
            ) || (
                    firstName && (<span className="name">{user.firstName}</span>)
                ) || (
                    lastName && (<span className="name">{user.lastName}</span>)
                )}

        </div>
    );
}

export default styled(UserTag)(styles);

export default class SupaCache {
    constructor(cacheName, {
        recordTypeMap = {},
    }) {
        this.recordTypeMap = recordTypeMap;
        this.recordTypes = Object.keys(recordTypeMap);
        this.databaseName = cacheName;
        this.database = null;
        this.waitOnDatabase = new Promise((accept, reject) => {
            this.dbOpenRequest = window.indexedDB.open(cacheName);
            this.dbOpenRequest.onsuccess = () => {
                this.database = this.dbOpenRequest.result;
                accept();
            };
            this.dbOpenRequest.onerror = e => {
                console.error(`Error opening or creating Database: ${this.databaseName}`, e);
                reject(e);
            };
            this.dbOpenRequest.onupgradeneeded = this.handleDatabaseUpgradeNeeded;
        });
        // this.recordTypeListeners = {};
        this.recordListeners = {};
    }

    addRecordListener(recordType, id, listener) {
        const stringId = `${id}`;
        const {
            [recordType]: recordTypeListeners = {}
        } = this.recordListeners;
        this.recordListeners[recordType] = recordTypeListeners;
        const {
            [stringId]: listeners = new Set(),
        } = recordTypeListeners;
        this.recordListeners[recordType][stringId] = listeners;
        listeners.add(listener);
    }

    removeRecordListener(recordType, id, listener) {
        const stringId = `${id}`;
        const {
            [recordType]: recordTypeListeners = {}
        } = this.recordListeners;
        this.recordListeners[recordType] = recordTypeListeners;
        const {
            [stringId]: listeners = new Set(),
        } = recordTypeListeners;
        this.recordListeners[recordType][stringId] = listeners;
        listeners.delete(listener);
    }

    updateRecordListeners(recordType, id, updatedRecord) {
        const stringId = `${id}`;
        const {
            [recordType]: {
                [stringId]: listeners = new Set()
            } = {},
        } = this.recordListeners;
        listeners.forEach(listener => { listener(updatedRecord); });
    }

    whenDbReady(action) {
        if (this.database) {
            return action();
        }
        return this.waitOnDatabase.then(action);
    }

    handleDatabaseUpgradeNeeded = (e) => {
        console.log(`Upgrade Needed Database: ${this.databaseName}`, e);
        this.database = this.dbOpenRequest.result;

        this.recordTypes.forEach((recordType) => {
            this.database.createObjectStore(recordType, this.recordTypeMap[recordType]);
        });
    }

    updateRecord(recordType, record) {
        return this.loadData({ [recordType]: record })
            .then(data => data[recordType]);
    }

    updateRecords(recordType, records) {
        return this.loadData({ [recordType]: records })
            .then(data => data[recordType]);
    }

    loadData(data) {
        return this.whenDbReady(() => {
            const recordTypes = Object.keys(data);
            if (recordTypes.length) {
                const transaction = this.database.transaction(recordTypes, 'readwrite');
                return new Promise((accept, reject) => {
                    recordTypes.forEach(recordType => {
                        const { [recordType]: records } = data;
                        const { [recordType]: { keyPath } } = this.recordTypeMap;
                        const objectStore = transaction.objectStore(recordType);
                        if (Array.isArray(records)) {
                            records.forEach(record => {
                                objectStore.put(record);
                                this.updateRecordListeners(recordType, record[keyPath], record);
                            });
                        } else {
                            objectStore.put(records);
                            this.updateRecordListeners(recordType, records[keyPath], records);
                        }
                    });
                    transaction.addEventListener('complete', () => {
                        console.log('loadData transaction success');
                        accept(data);
                    });
                    transaction.addEventListener('error', (e) => {
                        console.error('updateRecords transaction Error:', e);
                        reject(e);
                    });
                });
            }
        });
    }

    getRecord(recordType, recordId) {
        const numericRecordId = parseInt(recordId, 10) || recordId;
        const transaction = this.database.transaction(recordType, 'readonly');
        transaction.addEventListener('complete', () => console.log('Transaction Complete'));
        transaction.addEventListener('error', () => console.log('Transaction error'));
        const objectStore = transaction.objectStore(recordType);
        return new Promise((accept, reject) => {
            const query = objectStore.get(numericRecordId);
            query.addEventListener('success', (e) => {
                console.log('get record success', e);
                accept(query.result);
            });
            query.addEventListener('error', (e) => {
                console.log('query Failed', e);
                reject(e);
            });
        });
    }
}

import { useTheme } from '@emotion/react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import { Slide, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import ImageDialog from 'components/ImageDialog';
import QRCodeDialog from 'components/QRCodeDialog';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ColorToggle from '../ColorToggle';
import Canvas from './Canvas';
import DebugPoints from './DebugPoints';
import DebugVectors from './DebugVectors';
import EditorProperties from './EditorProperties';
import Layer from './Layer';
import MobileEditorProperties from './MobileEditorProperties';
import SelectionOverlay from './SelectionOverlay';
import ToolPalette from './ToolPalette';
import ViewportToolbar from './ViewportToolbar';
import { TOOL_TYPE_ADD_IMAGE, TOOL_TYPE_ADD_QRCODE, TOOL_TYPE_ADD_TEXT, TOOL_TYPE_POINTER } from './hooks/useCanvasSelection';
import { GRABBING_STATES, PIXELS_PER_INCH } from './hooks/useMouse';
import usePostcardEditor from './hooks/usePostcardEditor';



const styles = ({ theme }) => ({
    // backgroundColor: 'red',
    overscrollBehavior: 'contain',
    backgroundColor: theme.palette.mode === 'light' ? '#666' : '#666', //theme.palette.background.default,
    color: '#fff',
    overflow: 'hidden',
    flexGrow: 1,
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    position: 'relative',
    '& .screenContent': {
        display: 'flex',
        flexGrow: 1,
        overflow: 'hidden',
    },
    '&.grabbing': {
        cursor: 'grabbing !important',
    },
    '& .debug': {
        margin: 0,
        position: 'absolute',
        top: '0px',
        left: '0px',
        // color: '#666',
        padding: 50,
        maxWidth: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    '& .debugFocus': {
        margin: 0,
        position: 'absolute',
        top: '0px',
        left: '0px',
        color: '#eee',
        padding: 50,
        maxWidth: '100%',
        maxHeight: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'rgba(0,0,0,0.9)',
        overflow: 'auto',
        zIndex: 10000,
    },
    '& .workspace': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        touchAction: 'none',
        userSelect: 'none',
    },
    '& .canvasBottom': {
        transition: theme.transitions.create('all'),
        // zIndex: 100,
    },
    // '& .selectionOverlay': {
    //     // zIndex: 200,
    // },
    '& .selectionOverlay': {
        // transition: theme.transitions.create('all'),
    },
    '& .hidden': {
        opacity: 0,
    },
    '& .animations': {
        transition: theme.transitions.create('all'),
    },
    '& .canvasTop': {
        // outline: '1px solid grey',
        backgroundColor: 'transparent',
        border: '2px solid rgba(255,255,255,0.5)',
        boxSizing: 'border-box',
        '*': {
            opacity: '0% !important'
        }
    },
    '& .viewportControlContainer': {
        position: 'absolute',
        transform: 'translate(-50%, 0px)',
        marginLeft: '50%',
        bottom: theme.spacing(1),
    },
    '& .mobileEditorProperties': {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        right: '0px',
    }
});


function PostCardEditor(props) {
    const { className } = props;
    const { id } = useParams();
    const newCard = id === 'new';
    const {
        state,
        loadState,
        fieldErrors,
        pageErrors,
        layers,
        layerSelection,
        addLayer,
        viewport,
        mouseEventHandlerProps,
        mouseState,
        touchEventHandlerProps,
        touchState,
        keyboard,
        currentPageIdentifier,
        focusFront,
        focusBack,
        describeLayer,
        dropzone,
        debugPoints,
        debugVectors,
        previewProperties,
        updatePage,
        describePage,
        page,
    } = usePostcardEditor(newCard ? false : id);
    const {
        tool,
        setTool
    } = layerSelection;

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopProperties = Boolean(!mobile && !viewport.preview);
    const desktopViewportControls = Boolean(!mobile);
    const mobileProperties = Boolean(mobile && !viewport.preview);


    const selectionOverlayClass = clsx({
        hidden: viewport.preview,
        animations: viewport.animations,
    });
    const screenClass = clsx(className, {
        grabbing: GRABBING_STATES.includes(mouseState),
    });

    // const [currentTool, setCurrentTool] = useState('select');

    // Add Image Logic
    const addImageDialogOpen = Boolean(tool === TOOL_TYPE_ADD_IMAGE);
    const closeToolDialog = useCallback(() => setTool(TOOL_TYPE_POINTER), [setTool]);
    const onAddImage = useCallback((imageId, { width, height }) => {
        addLayer({
            backgroundImage: imageId,
            width: width / PIXELS_PER_INCH,
            height: height / PIXELS_PER_INCH,
            borderWidth: 0,
            backgroundColor: 'rgba(0,0,0,0)',
        });
    }, [addLayer]);

    // Add QRCode Logic
    const addQRCodeDialogOpen = Boolean(tool === TOOL_TYPE_ADD_QRCODE);
    const textTool = Boolean(tool === TOOL_TYPE_ADD_TEXT);
    const toolIsAdditive = Boolean(textTool && !layerSelection.hasSelection);


    // const mapURL = 'https://maps.googleapis.com/maps/api/staticmap?';
    const rootAttributes = {
        className: screenClass,
        style: {
            cursor: toolIsAdditive ? 'crosshair' : 'initial'
        }
    };
    if (!addImageDialogOpen) {
        Object.assign(rootAttributes, dropzone.getRootProps(rootAttributes));
    }
    return (
        <>
            <div {...rootAttributes}>
                <div
                    className="screenContent"
                    ref={viewport.screenRef}
                    data-type="workspace"
                    {...mouseEventHandlerProps}
                    {...touchEventHandlerProps}
                >
                    {!viewport.preview && (
                        <pre className={keyboard.debugMode ? "debugFocus" : "debug"}>
                            {JSON.stringify({
                                tool,
                                debugPoints,
                                debugVectors,
                                selectionProperties: layerSelection.properties,
                                loadState,
                                mouseState,
                                touchState,
                                debugMode: keyboard.debugMode,
                                preview: viewport.preview,
                                selectedLayers: layerSelection.selection,
                                viewport: viewport.viewportMatrix.destructure(),
                                state,
                            }, null, 4)}
                        </pre>
                    )}
                    <div className="workspace" />
                    <Canvas
                        guides={viewport.guides}
                        postage={currentPageIdentifier === 'front'}
                        interactive
                        className="canvasBottom"
                        label={currentPageIdentifier}
                        ref={viewport.canvasRef}
                        style={{
                            ...viewport.viewportMatrix.css,
                            transition: viewport.animations ? undefined : 'none'
                        }}
                        preview={viewport.preview}
                        layers={layers}
                        layerSelection={layerSelection}
                    >
                        {layerSelection.hasSelection && textTool && (
                            <Layer
                                textEditable
                                selected
                                attributes={layerSelection.selectedLayers[0]}
                                id={layerSelection.selection[0]}
                                onChange={layerSelection.updateSelection}
                            />
                        )}
                        {previewProperties && (
                            <Layer
                                selected
                                attributes={{
                                    ...layerSelection.properties,
                                    ...previewProperties,
                                }}
                                id="previewText"
                                highlight
                            />
                        )}
                        <DebugPoints
                            points={debugPoints}
                            style={{ ...viewport.viewportMatrix.css }}
                        />

                        <DebugVectors
                            vectors={debugVectors}
                            style={{ ...viewport.viewportMatrix.css }}
                        />
                    </Canvas>
                    <DebugPoints points={viewport.statePoints} />
                    <SelectionOverlay
                        className={selectionOverlayClass}
                        layers={layers}
                        viewport={viewport}
                        layerSelection={layerSelection}
                    />
                </div>
                {!viewport.preview && (
                    <ToolPalette
                        ref={viewport.toolsRef}
                        value={tool}
                        onChange={setTool}
                        onAddSquare={addLayer}
                    >
                        <IconButton color="inherit" disableRipple>
                            <ColorToggle
                                name="backgroundColor"
                                disabled={layerSelection.locked}
                                value={layerSelection.properties.backgroundColor}
                                onChange={layerSelection.updateSelection}
                                tooltip="Fill Color">
                                <FormatColorFillIcon />
                            </ColorToggle>
                        </IconButton>
                        <IconButton color="inherit" disableRipple>
                            <ColorToggle
                                name="borderColor"
                                disabled={layerSelection.locked}
                                value={layerSelection.properties.borderColor}
                                onChange={layerSelection.updateSelection}
                                tooltip="Border Color">
                                <BorderColorIcon />
                            </ColorToggle>
                        </IconButton>
                        <IconButton color="inherit" disableRipple>
                            <ColorToggle
                                name="color"
                                disabled={layerSelection.locked}
                                value={layerSelection.properties.color}
                                onChange={layerSelection.updateSelection}
                                tooltip="Text Color">
                                <FormatColorTextIcon />
                            </ColorToggle>
                        </IconButton>
                    </ToolPalette>
                )}
                <Slide in={desktopProperties} unmountOnExit direction="left">
                    <EditorProperties
                        ref={viewport.propertiesRef}
                        describeLayer={describeLayer}
                        layers={layers}
                        viewport={viewport}
                        state={state}
                        layerSelection={layerSelection}
                        currentPageIdentifier={currentPageIdentifier}
                        focusFront={focusFront}
                        focusBack={focusBack}
                        fieldErrors={fieldErrors}
                        pageErrors={pageErrors}
                    />
                </Slide>
                {/* This chunk of code just assigns the properties ref to a random div to prevent undefined errors in viewport */}
                {!desktopProperties && (
                    <div style={{ width: 0 }} ref={viewport.propertiesRef} />
                )}
                <Slide in={mobileProperties} unmountOnExit direction="up">
                    <MobileEditorProperties
                        className="mobileEditorProperties"
                        ref={mobileProperties && viewport.viewportToolsRef}
                        describeLayer={describeLayer}
                        layers={layers}
                        viewport={viewport}
                        state={state}
                        layerSelection={layerSelection}
                        currentPageIdentifier={currentPageIdentifier}
                        focusFront={focusFront}
                        focusBack={focusBack}
                        fieldErrors={fieldErrors}
                        pageErrors={pageErrors}
                    />
                </Slide>
                <div className="viewportControlContainer">
                    <Slide in={desktopViewportControls} unmountOnExit direction="up">
                        <ViewportToolbar
                            className="viewportControls"
                            ref={desktopViewportControls && viewport.viewportToolsRef}
                            viewport={viewport}
                            page={page}
                            updatePage={updatePage}
                            describePage={describePage}
                        />
                    </Slide>
                </div>
            </div>
            {addImageDialogOpen && (
                <ImageDialog
                    open
                    title="Add Image"
                    onClose={closeToolDialog}
                    onImage={onAddImage}
                />
            )}
            {addQRCodeDialogOpen && (
                <QRCodeDialog
                    open
                    title="Add QRCode"
                    onClose={closeToolDialog}
                    onImage={onAddImage}
                />
            )}
        </>
    );
}

export default styled(PostCardEditor)(styles);

import DetailLayout from 'components/DetailLayout';
import { MODE_WRITE } from 'components/Field';
import React from 'react';


const FONT_LAYOUT = [
    'fontFamily',
    ['fontSize', 'fontWeight'],
    'color',
    ['textAlign', 'verticalAlign'],
    // 'text'
];

export const FONT_PROPERTIES = FONT_LAYOUT.flat();

export default function FrontProperties(props) {
    const { className, disabled, properties, layerErrors, onChange, describe } = props;
    return (
        <DetailLayout
            className={className}
            layout={FONT_LAYOUT}
            value={properties}
            error={layerErrors}
            onChange={onChange}
            disabled={disabled}
            describe={describe}
            xs={6}
            FieldProps={{
                variant: 'filled',
                size: 'small',
                mode: MODE_WRITE,
            }}
        />
    );
}

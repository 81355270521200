export const PREVIEW_LAYOUT = [
    {
        name: "name",
        variant: "h5:typography",
    },
    {
        name: "description",
        multiline: true,
        rows: 3,
    },
    {
        label: 'Address Lookup',
        name: 'addSearch',
        placeholder: 'Enter Location',
    },
    "addressLine1",
    "addressLine2",
    "city",
    ["state", "zip"],
    ['dateUpdated', 'dateCreated']
];

export const CREATE_LAYOUT = [
    {
        name: "name",
        variant: "h5:typography",
    },
    {
        name: "description",
        multiline: true,
        rows: 3,
    },
    {
        label: 'Address Lookup',
        name: 'addSearch',
        placeholder: 'Enter Location',
    },
    "addressLine1",
    "addressLine2",
    "city",
    ["state", "zip"],
];

import {
    Autocomplete, Box, Button, FormControl, FormHelperText, IconButton, InputLabel, TextField as MuiTextField,
    Slider,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import ColorToggle from 'components/ColorToggle';
import FontOption from 'components/FontOption';
import Icon from 'components/Icon';
import VirtualAutocomplete from 'components/VirtualAutocomplete';
import useAddressSearch from 'hooks/useAddressSearch';
// import { useFontOptions } from 'hooks/useFontManager';
import Image from 'components/Image';
import { useMojipostClient } from 'connectors/MojipostClient';
import useAsyncCallback from 'hooks/useAsyncCallback';
import useToggle from 'hooks/useToggle';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ImageDialog from './ImageDialog';
import { useFontOptions } from './PostcardEditor/hooks/useFontManager';

const styles = ({ theme, variant }) => ({
    transition: theme.transitions.create('all'),
    //
    outline: `${theme.spacing(0)} solid transparent`,
    ...theme.shape,
    '.MuiFormLabel-root': {
        display: 'flex',
    },
    '.MuiFormLabel-asterisk': {
        color: theme.palette.error.main
    },
    ".MuiFormLabel-root, .MuiOutlinedInput-notchedOutline, .MuiInputBase-input, .MuiInputBase-multiline": {
        // opacity: 1,
        transition: theme.transitions.create(['padding', 'font-size', 'opacity', 'left', 'font-weight', 'color', 'transform']),
    },
    "&.typography-field": {
        borderTop: `solid ${theme.spacing(1)} transparent`,
        // margin: 0,
        ":not(:focus-within) .MuiFormLabel-root": {
            left: -14,
        },
        ":not(:focus-within) .MuiOutlinedInput-notchedOutline": {
            opacity: 0,
        },
        ".MuiInputBase-multiline:not(:focus-within)": {
            // remove crazy extra padding on multiline
            padding: 0,
        },
        ".MuiInputBase-input:not(:focus-within)": {
            // padding: theme.spacing(1),
            padding: theme.spacing(1) + ' 0px',
            ...(theme.typography[variant?.split(':')[0]] || {}),
            textOverflow: 'ellipsis',
        },
        // code dealing with pencil icon here
        '& .MuiInputAdornment-positionEnd': {
            // width: 0,
            opacity: 0,
            transform: 'translateX(' + theme.spacing(5) + ') rotate(145deg) scale(.2)',
            transition: theme.transitions.create('all'),
        },
        '&:hover .MuiInputAdornment-positionEnd, &:focus-within .MuiInputAdornment-positionEnd': {
            opacity: 1,
            // width: 'initial',
            transform: 'translateX(0)',
        },
        '&:focus-within .MuiInputAdornment-positionEnd': {
            color: 'inherit',
        },
        '&:hover:not(:focus-within)': {
            backgroundColor: theme.palette.action.hover,
            outline: `${theme.spacing(1)} solid ${theme.palette.action.hover}`
        },
        '& .MuiFormHelperText-root': {
            transition: theme.transitions.create('margin'),
        },
        '&:not(:focus-within) .MuiFormHelperText-root': {
            margin: 0,
        }
    },
    "&.typography-only": {
        borderTop: `solid ${theme.spacing(1)} transparent`,
        // margin: 0,
        '&:hover:not(:focus-within)': {
            backgroundColor: 'initial',
            outline: 0
            // textOverflow: 'ellipsis',
        },
        ".MuiFormLabel-root": {
            left: -14,
        },
        ".MuiOutlinedInput-notchedOutline": {
            opacity: 0,
        },
        ".MuiInputBase-multiline": {
            // remove crazy extra padding on multiline
            padding: 0,
        },
        ".MuiInputBase-input": {
            // padding: theme.spacing(1),
            padding: theme.spacing(1) + ' 0px',
            ...(theme.typography[variant?.split(':')[0]] || {}),
        },
    }
});

export const PLACEHOLDER_LABEL = 'Add $1';
export const MODE_READ = 'read';
export const MODE_EDITABLE = 'editable';
export const MODE_WRITE = 'write';

const funct = i => 1 + i;
function FormattedDateTimeOutputInner(props, ref) {
    const { value, ...forwardProps } = props;
    const displayValue = moment(value).fromNow();
    const [_, updateRenderCount] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            updateRenderCount(funct);
        }, 60000); // about a minute
        return () => {
            clearInterval(interval);
        };
    }, [updateRenderCount]);

    return (
        <span {...forwardProps} ref={ref}>
            {displayValue}
        </span>
    );
}
const FormattedDateTimeOutput = React.forwardRef(FormattedDateTimeOutputInner);

function OutputInner(props, ref) {
    const { className, value, id, type } = props;
    // console.log(props);
    let displayValue = value;
    if (type === 'datetime') {
        return (
            <FormattedDateTimeOutput
                id={id}
                className={className}
                ref={ref}
                value={value}
            />
        );
    }
    return (
        <span className={className} id={id} ref={ref}>
            {displayValue}
        </span>
    )
}

const Output = React.forwardRef(OutputInner);

function TextFieldInner({
    className,
    name,
    value,
    type,
    description = {},
    error = [],
    isDirty,
    variant,
    mode = MODE_EDITABLE,
    minValue,
    maxValue,
    formIsDirty,
    onUndo,
    disabled,
    InputProps = {},
    inputProps = {},
    describe,
    onChange,
    style,
    startAddornmentSX = {},
    ...forwardProps
}, ref) {
    const localRef = useRef();
    const innerInputRef = useRef();
    const inputRef = ref || localRef;


    const fieldHasBeenChanged = typeof isDirty === 'function'
        ? isDirty(name)
        : Boolean(isDirty);
    const typeMap = {
        image: 'file',
        decimal: 'text',
        color: 'text',
    };
    const fieldType = description?.type in typeMap
        ? typeMap[description.type]
        : description?.type;

    const syntheticVariant = !variant || variant.includes(':') || variant === 'typography'
        ? 'outlined'
        : variant;

    const properties = {
        name,
        style,
        // value: fieldValue,
        /*onChange,*/
        disabled,
        // label: mode === MODE_EDITABLE && !fieldHasBeenChanged ? undefined : description?.label,
        type: fieldType,
        label: description.label ? (
            <>
                {mode === MODE_EDITABLE && description.helpText && (
                    <Tooltip title={description.helpText}>
                        <Icon
                            name="info"
                            sx={{
                                height: 20,
                                marginLeft: 1,
                                opacity: .6,
                                order: 4,
                            }}
                        />
                    </Tooltip>
                )}
                {description.label}
            </>
        ) : null,
        required: description?.required,
        placeholder: PLACEHOLDER_LABEL.replace('$1', description?.label),
        error: Boolean(error && error.length > 0), // should work for string or array of strings
        helperText: Array.isArray(error) && error.length > 0
            ? error
            : mode === MODE_WRITE
                ? description.helpText
                : '',
        margin: 'normal',
        InputProps,
        inputProps: {
            ref: innerInputRef,
            ...inputProps,
        },
        ref: inputRef,
        onChange,
        variant: syntheticVariant,
        ...forwardProps,
        autoComplete: 'off',
    };

    if (fieldType !== 'file') {
        properties.value = value;
    }

    // const isAddressSearch = name === 'addressLine1' || name.endsWith('.addressLine1');
    const isAddressSearch = name === 'addSearch' || name?.endsWith('.addSearch');
    useAddressSearch({
        inputRef,
        onChange,
        setup: isAddressSearch,
    });

    if ((
        (mode === MODE_EDITABLE && !description?.readOnly) ||
        mode === MODE_WRITE
    ) &&
        !('endAdornment' in properties.InputProps)
    ) {
        properties.InputProps.endAdornment = (
            <InputAdornment position="end">
                {fieldHasBeenChanged ? (
                    <Tooltip title="Undo">
                        <IconButton
                            onClick={onUndo}
                            size="small"
                            color="primary"
                            edge="end"
                            disabled={disabled}
                        >
                            <Icon name="undo" />
                        </IconButton>
                    </Tooltip>
                ) : (mode !== MODE_WRITE) ? (
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() => innerInputRef.current.focus()}
                            size="small"
                            color="primary"
                            edge="end"
                            disabled={disabled}
                        >
                            <Icon name="edit" />
                        </IconButton>
                    </Tooltip>
                ) : description?.unit}
            </InputAdornment>
        );
        properties.InputLabelProps = {
            shrink: true,
        };
    }

    if (description?.icon && !('startAdornment' in properties.InputProps)) {
        properties.InputProps.startAdornment = (
            <InputAdornment position="start" sx={startAddornmentSX} >
                <Icon name={description.icon} />
            </InputAdornment>
        );
    }

    if (description?.readOnly) {
        properties.InputProps.readOnly = true;
        properties.InputProps.slots = {
            input: Output
        };
    }

    const rootClass = clsx(className, {
        "typography-field": Boolean(mode === MODE_EDITABLE && !fieldHasBeenChanged && fieldType !== 'file'),
        "typography-only": Boolean(mode === MODE_READ || description?.readOnly)
    });

    if (fieldType === 'file') {
        const color = properties.error ? 'error' : undefined;
        return (
            <FormControl
                style={style}
                error={properties.error}
            >
                <Button
                    variant="outlined"
                    component="label"
                    color={color}
                    disabled={properties.disabled}
                    startIcon={<Icon name="uploadImage" />}
                    style={{ alignSelf: 'center' }}
                >
                    Upload Profile Image
                    <input
                        type="file"
                        onChange={onChange}
                        style={{ display: 'none' }}
                        name={properties.name}
                    />
                </Button>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        )
    }

    return (
        <MuiTextField
            className={rootClass}
            {...properties}
        />
    )
}
const TextField = styled(React.forwardRef(TextFieldInner))(styles);


function ImageField(props) {
    const {
        name,
        error,
        onChange,
        disabled,
        description,
        describe,
        isDirty,
        onUndo,
        ...forwardProps
    } = props;

    const inputRef = useRef();
    const openFileUpload = () => {
        inputRef.current.click();
    };

    const color = error ? 'error' : undefined;

    const {
        label,
        icon,
    } = description;

    return (
        <FormControl
            error={error}
            {...forwardProps}
        >
            <Button
                variant="outlined"
                component="label"
                color={color}
                onClick={openFileUpload}
                disabled={disabled}
                startIcon={Boolean(icon) && (<Icon name={icon} />)}
                style={{ alignSelf: 'center' }}
            >
                Upload {label}
            </Button>
            <input
                type="file"
                onChange={onChange}
                style={{ display: 'none' }}
                name={name}
                ref={inputRef}
            />
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}


function SearchableChoiceField(props) {
    const {
        label,
        description,
        name,
        value,
        inputProps,
        onChange,
        fullWidth,
        disabled,
        renderOption: renderOptionOverride,
        ...forwardProps
    } = props;

    const handleFontWeight = (event, value) => {
        onChange({
            target: {
                name,
                value: `${value.value}`,
            }
        });
    };


    const {
        choices = [],
    } = description || {};
    const fontWeight = choices.find(item => item.value === value);
    const fontPreview = {};

    const renderOption = Boolean(renderOptionOverride)
        ? renderOptionOverride
        : (props, option) => (
            <Box
                component="li"
                sx={{
                    ...fontPreview,
                    fontWeight: option.value
                }}
                {...props}>
                {option.label}
            </Box>
        );

    return (
        <Autocomplete
            className={props.className}
            fullWidth
            blurOnSelect
            autoHighlight
            disablePortal
            disableClearable
            disabled={disabled}
            // options={value ? choices : []}
            options={choices}
            value={fontWeight || ''}
            onChange={handleFontWeight}
            // TODO: build previewState override for selection
            // onHighlightChange={(...params) => console.log(params)}
            renderOption={renderOption}
            renderInput={({ inputProps: inputParams, ...params }) => (
                <TextField
                    startAddornmentSX={{ alignSelf: 'flex-start', paddingTop: 0 }}
                    description={description}
                    inputProps={{
                        ...inputProps,
                        ...inputParams,
                    }}
                    name={name}
                    {...params}
                    {...forwardProps}
                />
            )}
        />
    );
}

function ColorField(props) {
    const {
        InputProps = {},
        value,
        name,
        onChange,
        ...forwardProps
    } = props;
    return (
        <TextField
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <ColorToggle
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            size={16}
                            name={name}
                            value={value}
                            onChange={onChange}
                            disabled={forwardProps.disabled}
                        />
                    </InputAdornment>
                ),
                ...InputProps
            }}
            value={value}
            name={name}
            onChange={onChange}
            {...forwardProps}
        />
    )
}

function FontField(props) {
    const {
        label,
        name,
        value,
        inputProps,
        onChange,
        fullWidth,
        disabled,
        ...forwardProps
    } = props;
    const handleFontChange = (event, value) => {
        onChange({
            target: {
                name,
                value: `${value}`,
            }
        });
    };
    const fonts = useFontOptions();
    const toggle = useToggle();
    const handleFontFamilyOpen = () => {
        if (fonts.options.length === 0 && !fonts.loading && !fonts.error) {
            fonts.load();
        }
        toggle.toggleOn();
    };
    console.log('FONT ', value)
    return (
        <VirtualAutocomplete
            fullWidth={fullWidth}
            blurOnSelect
            autoHighlight
            // disablePortal
            disableClearable
            loading={fonts.loading}
            name={name}
            disabled={disabled}
            options={fonts.options}
            onOpen={handleFontFamilyOpen}
            onClose={toggle.toggleOff}
            // getOptionLabel={e => console.log(e) || e}
            value={value}
            onChange={handleFontChange}
            // TODO build selection preview state
            // onHighlightChange={handleFontFamily}
            // For Future reference: this is the signiture from the example...
            // renderOption={(props, option) => [props, option]}
            renderGroup={(params) => params}
            renderOption={(props, option) => {
                return [props, <FontOption
                    text={option}
                    fontFamily={option}
                    // fontWeight={fontPreview.fontWeight}
                    {...props}
                />];
            }}
            renderInput={({ inputProps: inputParams, ...params }) => (
                <TextField
                    startAddornmentSX={{ alignSelf: 'flex-start', paddingTop: .5 }}
                    inputProps={{
                        ...inputProps,
                        ...inputParams,
                        style: {
                            fontSize: '25px',
                            fontFamily: JSON.stringify(value),
                            // fontWeight,
                            content: JSON.stringify(value),
                        }
                    }}
                    {...params}
                    {...forwardProps}
                />
            )}
        />
    );
}

function ButtonField(props) {
    const {
        description: {
            label,
            choices,
        },
        name,
        disabled,
        value,
        onChange,
    } = props;
    const handleChange = (event, value) => {
        onChange(name, value);
    };
    return (
        <FormControl fullWidth>
            <Tooltip title={label}>
                <ToggleButtonGroup
                    fullWidth
                    exclusive
                    size="small"
                    className="buttonGroup"
                    disabled={disabled}
                    value={value}
                    onChange={handleChange}
                >
                    {choices?.map(choice => (
                        <ToggleButton
                            key={choice.value}
                            value={choice.value}
                        >
                            <Icon name={choice.label} />
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Tooltip>
        </FormControl>
    );
}

function ImageReferenceField(props) {
    const {
        InputProps,
        value,
        disabled,
        onChange,
        name,
        ...forwardProps
    } = props;

    const canLoadPreview = Boolean(value);

    const client = useMojipostClient();
    const hasValue = Boolean(parseInt(value, 10));

    const {
        result: imageRecord,
        loading,
        error,
    } = useAsyncCallback(
        client.getImage,
        {
            autoCall: hasValue,
            autoCallArgs: [value],
        }
    );

    const renderAsDisabled = disabled || loading;
    const renderValue = loading
        ? 'loading...'
        : value
            ? imageRecord?.name || ""
            : "";

    const {
        state: imageDialogOpen,
        toggleOn: openDialog,
        toggleOff: closeDialog
    } = useToggle(false);

    const updateImage = (imageId) => {
        onChange(name, imageId);
        closeDialog();
    };

    const clearImage = (event) => {
        event.stopPropagation();
        onChange(name, undefined);
    };


    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {canLoadPreview ? (
                <Tooltip title="Change Image" arrow placement="top">
                    <Image
                        onClick={openDialog}
                        disabled={disabled}
                        disableLoadingText
                        height={48}
                        width={48}
                        imageId={value}
                        sx={{
                            // ...theme.shape,
                            backgroundColor: theme => theme.palette.divider,
                            marginRight: 1,
                            borderRadius: '3px',
                            overflow: 'hidden',
                        }}
                    />
                </Tooltip>
            ) : (
                <IconButton
                    onClick={openDialog}
                    color="primary"
                    sx={{
                        minHeight: 48,
                        minWidth: 48,
                        marginRight: 1
                    }}>
                    <Tooltip title="Upload Image" arrow placement="top">
                        <Icon name="uploadImage" />
                    </Tooltip>
                </IconButton>
            )}
            <TextField
                onClick={openDialog}
                disabled={renderAsDisabled}
                value={renderValue}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        value && (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={clearImage}>
                                    <Icon name="clear" />
                                </IconButton>
                            </InputAdornment>
                        )
                    ),
                    ...InputProps
                }}
                {...forwardProps}
            />
            <ImageDialog
                open={imageDialogOpen}
                title="Update Image"
                onImage={updateImage}
                onClose={closeDialog}
            />
        </Box>
    );

}

export function functionPivot(object, pivot, defaultFunction, pivotMap, pivotOverride) {
    const pivotsOveride = typeof pivotOverride === 'string'
        ? pivotOverride.split('.')
        : null;
    const pivots = pivot.split('.');
    let pivotValue = object;
    pivots.forEach(pivotStep => pivotValue = pivotValue?.[pivotStep]);
    let pivotValueOverride = object;
    pivotsOveride?.forEach(pivotStep => pivotValueOverride = pivotValueOverride?.[pivotStep]);
    const {
        [defaultFunction]: DefaultFunction,
        [pivotValue]: Function = DefaultFunction,
        [pivotValueOverride]: FunctionOverride = Function,
    } = pivotMap;
    return FunctionOverride;
}

export function RenderPivot(props) {
    const {
        pivot,
        pivotMap,
        pivotOverride,
        defaultRender,
        ...forwardProps
    } = props;

    const Component = functionPivot(forwardProps, pivot, defaultRender, pivotMap, pivotOverride);
    return (
        <Component {...forwardProps} />
    );
}

function ReferenceField(props) {
    return (
        <RenderPivot
            {...props}
            pivot="description.referenceObject"
            defaultRender="default"
            pivotMap={{
                default: TextField,
                image: ImageReferenceField,
            }}
        />
    )
}

function SliderField(props) {
    return (
        <FormControl
            fullWidth
            variant="standard"
            sx={props.sx}
        >
            <InputLabel shrink>{props.description.label}</InputLabel>
            <Stack spacing={2} direction="row" sx={{ mt: 2 }} alignItems="center">
                {props.description.icon && (
                    <Icon name={props.description.icon} />
                )}
                <Slider
                    size="small"
                    defaultValue={70}
                    valueLabelDisplay="auto"
                    value={props.value}
                    marks={props.marks}
                    onChange={props.onChange}
                    min={props.description.minValue}
                    max={props.description.maxValue}
                    name={props.name}
                    disabled={props.disabled}
                    valueLabelFormat={value => `${value}${props.description.unit}`}
                />
                <Typography variant="caption">
                    {`${props.value}${props.description.unit}`}
                </Typography>
            </Stack>
        </FormControl>
    );
}


function Field(props) {
    // const { onChange, ...forwardProps } = props;
    // const scopedOnChange = functionPivot(
    //     props,
    //     'description.type',
    //     onChange,
    //     {
    //         decimal: (...args) => {
    //             // if ()
    //             console.log('decimal onChange', ...args)
    //         },
    //     },
    //     'onChange'
    // );
    return (
        <RenderPivot
            {...props}
            // onChange={scopedOnChange}
            pivot="description.type"
            pivotOverride="renderType"
            defaultRender="string"
            pivotMap={{
                string: TextField,
                choice: SearchableChoiceField,
                color: ColorField,
                font: FontField,
                buttonChoice: ButtonField,
                image: ImageField,
                reference: ReferenceField,
                slider: SliderField,
            }}
        />
    );
}

export default Field;

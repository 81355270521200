import { useCallback, useMemo, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setXStateObject } from "./useXState";

function decodeSearchParams(searchParams) {
    let result = {};
    searchParams.forEach((value, key) => {
        result = setXStateObject({}, result, key, value);
    });
    return result;
}

function encodeSearchParams(object) {
    const nodes = Object.keys(object).map(key => ({ key, value: object[key] }));
    let searchParams = {};
    while (nodes.length) {
        const { key, value } = nodes.shift();
        if (typeof value === 'object') {
            nodes.push(...Object.keys(value).map(step => ({ key: key + '.' + step, value: value[step] })));
        } else {
            searchParams[key] = value;
        }
    }
    return searchParams;
}

export function useGETState() {
    const [searchParams, setSearchParams] = useSearchParams();

    const stateRef = useRef();
    stateRef.current = useMemo(() => decodeSearchParams(searchParams), [searchParams]);

    const setState = useCallback((...args) => setSearchParams(
        searchParams => encodeSearchParams(
            setXStateObject({}, decodeSearchParams(searchParams), ...args)
        )
    ), [setSearchParams]);

    const replaceState = useCallback((...args) => setSearchParams(
        searchParams => encodeSearchParams(
            setXStateObject({ update: true }, decodeSearchParams(searchParams), ...args)
        ),
        { replace: true }
    ), [setSearchParams]);

    return {
        state: stateRef.current,
        setState,
        replaceState
    };
}

export function useQueryParamsRead() {
    const { search, pathname } = useLocation();

    const searchInfo = useMemo(() => {
        const urlSearchParams = new URLSearchParams(search);
        const searchParams = {};
        for (var [key, value] of urlSearchParams.entries()) {
            searchParams[key] = value;
        }
        return {
            search,
            urlSearchParams,
            searchParams,
        };
    }, [search]);

    return {
        pathname,
        search,
        ...searchInfo
    };
}

export default function useQueryParams() {
    const { search, pathname } = useLocation();
    const navigate = useNavigate();

    const searchInfo = useMemo(() => {
        const urlSearchParams = new URLSearchParams(search);
        const searchParams = {};
        for (var [key, value] of urlSearchParams.entries()) {
            searchParams[key] = value;
        }
        return {
            search,
            urlSearchParams,
            searchParams,
        };
    }, [search]);

    const setParams = useCallback((params, replace = false) => {
        const { searchParams } = searchInfo;
        const newParams = typeof params === 'function' ? params(searchParams) : params;
        const urlSearchParams = new URLSearchParams(newParams);
        navigate('?' + urlSearchParams.toString(), { replace });
    }, [navigate, searchInfo]);


    return {
        pathname,
        search,
        setParams,
        ...searchInfo,
    };
}

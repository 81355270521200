import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import useToggle from '../hooks/useToggle';
import AccountPopover from './AccountPopover';
import UserTag from './UserTag';

const styles = ({ theme }) => ({
    // when radius larger than the hight or width is used, browser
    // automatically renders the radius so that one side is perfectly rounded.
    // since button is shorter than 50px it will be rendered as a pill.
    borderRadius: 50,
    padding: theme.spacing(0.5),
    minWidth: 40,
    color: 'inherit',
    '.MuiTouchRipple-root': {
        border: '2px solid ' + theme.palette.primary.dark,
    }
});

function UserMenu(props) {
    const { className, ...forwardProps } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        state: menuOpen,
        toggleOn: openMenu,
        toggleOff: closeMenu,
    } = useToggle();

    return (
        <>
            <Button className={className} onClick={openMenu} {...forwardProps}>
                <UserTag avatar username={!mobile} />
            </Button>
            <AccountPopover onClose={closeMenu} open={menuOpen} />
        </>
    );
}

export default styled(UserMenu)(styles);

import { Avatar as MuiAvatar } from "@mui/material";
import useImageLoader from "hooks/useImageSource/useImageSource";
import ErrorSplash from "./ErrorSplash";
import Loader from "./Loader";

export default function Avatar(props) {
    const {
        className,
        src,
        sx,
        onClick,
        ...forwardProps
    } = props;
    const { url, loading, error } = useImageLoader(src);

    if (error) {
        return (
            <MuiAvatar
                className={className}
                sx={sx}
                onClick={onClick}
            >
                <ErrorSplash
                    sx={sx}
                />
            </MuiAvatar>
        );
    }

    if (loading || !Boolean(url)) {
        return (
            <MuiAvatar
                className={className}
                sx={sx}
                onClick={onClick}
            >
                <Loader
                    disableLoadingText
                />
            </MuiAvatar>
        );
    }

    return (
        <MuiAvatar
            className={className}
            src={url}
            sx={sx}
            onClick={onClick}
            {...forwardProps}
        />
    );
}

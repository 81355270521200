import { Card, Divider, Fab, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from 'components/Icon';
import React, { forwardRef, useEffect, useRef } from 'react';
import { TOOL_TYPE_ADD_IMAGE, TOOL_TYPE_ADD_QRCODE, TOOL_TYPE_ADD_SQUARE, TOOL_TYPE_ADD_TEXT, TOOL_TYPE_POINTER } from './hooks/useCanvasSelection';

const styles = ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'initial',
    borderRadius: '50px',
    '.MuiTabs-root': {
        minHeight: 0,
    },
    '.MuiTab-root': {
        minWidth: 0,
        minHeight: 0,
        padding: theme.spacing(1),
    },
    '.indicator': {
        zIndex: 'auto',
        position: 'absolute',
        transition: theme.transitions.create('all'),
        top: 0,
        left: 0,
    },
    '.tool': {
        zIndex: '300',
        transition: theme.transitions.create('all'),
    },
    '.tool.selected': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: theme.palette.secondary.contrastText,
        scale: '140%',
    }
});

function alignIndicatorToSelection(selected, indicator) {
    const {
        offsetTop: selectedTop,
        offsetLeft: selectedLeft,
        offsetHeight: selectedHeight,
        offsetWidth: selectedWidth,
        dataset: {
            index
        }
    } = selected;
    const spacing = parseInt(selected.computedStyleMap().get('padding'), 10);
    const {
        offsetTop: indicatorTop,
        offsetWidth: indicatorWidth,
        offsetHeight: indicatorHeight,
    } = indicator;

    indicator.style.left = selectedLeft + (selectedWidth - indicatorWidth) / 2 + 'px';
    indicator.style.top = spacing + index * selectedHeight + (selectedHeight - indicatorHeight) / 2 + 'px';
}

const tools = [
    {
        title: 'Select',
        icon: 'panTool',
        value: TOOL_TYPE_POINTER,
    },
    {
        title: 'Text',
        icon: 'addText',
        value: TOOL_TYPE_ADD_TEXT,
    },
    {
        title: 'Square',
        icon: 'addSquare',
        value: TOOL_TYPE_ADD_SQUARE,
    },
    // {
    //     title: 'Circle',
    //     icon: 'addCircle',
    //     value: TOOL_TYPE_ADD_CIRCLE,
    // },
    {
        title: 'Image',
        icon: 'addImage',
        value: TOOL_TYPE_ADD_IMAGE,
    },
    {
        title: 'QR Code / Link',
        icon: 'addQRCode',
        value: TOOL_TYPE_ADD_QRCODE,
    },
];

function ToolPalette(props, ref) {
    const { className, value, onChange, children } = props;
    const indicatorRef = useRef();
    const selectedRef = useRef();

    useEffect(() => {
        alignIndicatorToSelection(selectedRef.current, indicatorRef.current);
    }, [selectedRef, indicatorRef, value]);

    const handleToolSelection = (event) => {
        onChange && onChange(event.currentTarget.dataset.value);
    };

    return (
        <Card className={className} ref={ref}>
            <Fab
                disableRipple
                ref={indicatorRef}
                className="indicator"
                color="secondary"
            />
            {tools.map((toolConfig, index) => {
                const shouldSelectFirstValue = Boolean(!value && !index);
                const toolSelected = Boolean(shouldSelectFirstValue || toolConfig.value === value);
                const className = toolSelected ? 'tool selected' : 'tool';
                const buttonRef = toolSelected ? selectedRef : undefined;
                return (
                    <Tooltip
                        key={toolConfig.value}
                        title={toolConfig.title}
                        placement="right"
                        arrow
                    >
                        <span>
                            <IconButton
                                ref={buttonRef}
                                className={className}
                                data-value={toolConfig.value}
                                data-index={index}
                                onClick={handleToolSelection}
                                disabled={toolSelected}
                            >
                                <Icon name={toolConfig.icon} />
                            </IconButton>
                        </span>
                    </Tooltip>
                );
            })}
            <Divider />

            {children}
        </Card>
    );
}

export default styled(forwardRef(ToolPalette))(styles);

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAsyncCallback from "./useAsyncCallback";
import useStateObject from './useStateObject';
import useThrottle from './useThrottle';

export const POST_SAVE_BEHAVIOR_NONE = 'none';
export const POST_SAVE_BAHAVIOR_REPLACE = 'replace';
export const POST_SAVE_BAHAVIOR_CLEAR = 'clear';

export const MODE_AUTO = 'auto';
export const MODE_UPDATE = 'update';
export const MODE_CREATE = 'create';

const DEFAULT_DIFF_LISTENERS = [];
function usePersistentStateObject({
    id = false,
    mode = MODE_AUTO,
    noId = false,
    onGet,
    onCreate = undefined,
    onUpdate = undefined,
    onSave = undefined,
    onDescribe = undefined,
    addListener = undefined,
    removeListener = undefined,
    autoLoad = true,
    autoSave = false,
    autoSaveReconcile = null,
    autoSaveTimeout = 5000,
    overwriteOnLoad = false,
    postSaveBehavior = POST_SAVE_BAHAVIOR_REPLACE,
    defaultFormState = {},
    diffListeners = DEFAULT_DIFF_LISTENERS,
}) {
    // flags
    const isNewRecord = Boolean(
        (mode === MODE_AUTO && !id) ||
        mode === MODE_CREATE
    );
    const fetchRecord = Boolean((id || noId) && onGet && autoLoad);
    const fetchDescription = Boolean(onDescribe);
    const describeStateRef = useRef({});

    // TODO: get metadata for HTTP OPTIONS (DESCRIPTION)
    const onDescribeWithParams = useCallback(
        () => {
            const describeResult = (
                (mode === MODE_AUTO && id && onDescribe(id)) ||
                (mode === MODE_AUTO && noId && onDescribe()) ||
                (mode === MODE_AUTO && !id && onDescribe()) ||
                (mode === MODE_CREATE && onDescribe()) ||
                (mode === MODE_UPDATE && id && onDescribe(id)) ||
                (mode === MODE_UPDATE && noId && onDescribe())
            );
            return describeResult
                .then((description) => {
                    const {
                        setInitialValue,
                        baseResetState,
                    } = describeStateRef.current;
                    // might be a better way to do this but going to calculate initial value
                    // and apply them only if not already specified
                    // these default values are the lowest priority
                    const action = isNewRecord ? 'post' : 'put';
                    let targetDescription = description?.actions[action];
                    if (!targetDescription) {
                        return false;
                    }
                    const descriptionDefaultValues = {};
                    Object.keys(targetDescription).forEach(key => {
                        const {
                            type, read_only, default: defaultValue
                        } = targetDescription[key];
                        if (!read_only && type === 'string') {
                            descriptionDefaultValues[key] = typeof defaultValue === 'string' ? defaultValue : '';
                        }
                    });
                    setInitialValue(initialValue => ({
                        ...descriptionDefaultValues,
                        ...initialValue
                    }));
                    baseResetState();
                    return targetDescription;
                })
        },
        [id, isNewRecord, onDescribe, describeStateRef, mode, noId]
    );
    const {
        result: description,
        callback: reloadDescription,
        loading: loadingDescription,
        error: descriptionError,
    } = useAsyncCallback(onDescribeWithParams, { autoCall: fetchDescription, defaultResult: {} });

    const dataTypeDiffListeners = useMemo(() => {
        const diffListeners = [];
        // const coerseFields = [];
        const nodes = Object.keys(description)
            .map(fieldName => ({
                path: [fieldName],
                description: description[fieldName],
            }));
        while (nodes.length) {
            const node = nodes.shift();
            if (node.description.type === 'object') {
                Object.keys(node.description.children)
                    .forEach(fieldName => nodes.push({
                        path: [...node.path, fieldName],
                        description: node.description.children[fieldName],
                    }));
            } else if (node.description.type === 'array') {
                node.path.push('*');
                node.description = node.description.child;
                nodes.push(node);
            } else if (node.description.type === 'polymorph') {
                Object.keys(node.description.polymorphs).forEach(
                    polymorphDescriminator => {
                        const {
                            [polymorphDescriminator]: polymorphDescriminatorValueMap,
                        } = node.description.polymorphs;
                        const updates = {};
                        Object.keys(polymorphDescriminatorValueMap)
                            .forEach(polymorphDescriminatorValue => {
                                const {
                                    [polymorphDescriminatorValue]: polymorphFields
                                } = polymorphDescriminatorValueMap;
                                updates[polymorphDescriminatorValue] = {};
                                Object.keys(polymorphFields)
                                    .forEach(polymorphFieldName => {
                                        updates[polymorphDescriminatorValue][polymorphFieldName]
                                            = 'initial' in polymorphFields[polymorphFieldName]
                                                ? polymorphFields[polymorphFieldName].initial
                                                : polymorphFields[polymorphFieldName].default
                                    });
                            });
                        diffListeners.push({
                            path: [...node.path, polymorphDescriminator],
                            callback(changeEvent) {
                                const {
                                    updateState,
                                    path,
                                    newValue,
                                    previousValue
                                } = changeEvent;
                                if (previousValue === undefined && newValue !== undefined) {
                                    // remove the last item of the path
                                    path.pop();
                                    const { [newValue]: update } = updates;
                                    updateState(...path, update);
                                }
                            }
                        });
                    }
                );
            } else if (node.description.type === 'decimal' || node.description.type === 'slider') {
                diffListeners.push({
                    path: [...node.path,],
                    callback(changeEvent) {
                        const {
                            newValue,
                            replaceState,
                            path,
                        } = changeEvent;
                        if (
                            `${newValue}`.charAt(newValue.length - 1) === '.' ||
                            `${newValue}`.trim() === ''
                        ) {
                            return;
                        }
                        let update = newValue;
                        if ('minValue' in node.description && update < node.description.minValue) {
                            if (node.description?.domainRepeat) {
                                update = update + node.description.maxValue;
                            } else {
                                update = node.description.minValue;
                            }
                        }
                        if ('maxValue' in node.description && update > node.description.maxValue) {
                            if (node.description?.domainRepeat) {
                                update = update - node.description.maxValue;
                            } else {
                                update = node.description.maxValue;
                            }
                        }
                        if (node.description.decimalPlaces) {
                            const multiplier = 10 ** node.description.decimalPlaces;
                            update = Math.round(update * multiplier) / multiplier;
                        }
                        replaceState(...path, update);
                    }
                });
            }
        }
        return diffListeners;
    }, [description]);

    const mergedDiffListeners = useMemo(() => {
        return [
            ...diffListeners,
            ...dataTypeDiffListeners
        ];
    }, [diffListeners, dataTypeDiffListeners])


    // form state
    const {
        // initial value interface
        initialValueRef,
        initialValue,
        setInitialValue,
        updateInitialValue,
        clearInitialValue,

        // state interface
        stateRef,
        state,
        setState: baseSetState,
        updateState: baseUpdateState,
        resetState: baseResetState,
        clearState: baseClearState,

        // experimental
        resetStatePath: baseResetStatePath,

        // helpers
        isDirty,
    } = useStateObject(defaultFormState, { diffListeners: mergedDiffListeners });

    // little circular dependencies here, handling with a ref
    describeStateRef.current = {
        setInitialValue,
        baseResetState,
    };

    useEffect(() => {
        const updateState = (update) => {
            setInitialValue(update);
            baseResetState();
        };
        if (addListener && Boolean(id)) {
            addListener(id, updateState);
        }
        if (removeListener && Boolean(id)) {
            return () => {
                removeListener(id, updateState);
            };
        }
    }, [id, addListener, removeListener, setInitialValue, baseResetState]);


    const asyncFetch = useCallback(
        () => {
            const result = (
                (mode === MODE_AUTO && id && onGet(id)) ||
                (mode === MODE_AUTO && noId && onGet()) ||
                (mode === MODE_UPDATE && id && onGet(id)) ||
                (mode === MODE_UPDATE && noId && onGet())
            );
            const handleResult = result => {
                if (overwriteOnLoad) {
                    setInitialValue(result);
                } else {
                    updateInitialValue(result);
                }
                baseResetState();
            };
            if (result instanceof Promise) {
                return result.then(handleResult);
            }
            handleResult(result);
            return result;
        },
        [id, onGet, overwriteOnLoad, setInitialValue, updateInitialValue, baseResetState, mode, noId]
    );

    const {
        callback: reload,
        loading,
        error: loadError,
        reset: clearLoadError,
    } = useAsyncCallback(asyncFetch, { autoCall: fetchRecord });

    const describe = useCallback(
        (...path) => {
            // 1) setting up
            let targetData = stateRef.current;
            if (path.length > 1 &&
                !Array.isArray(path[0]) &&
                typeof path[0] === 'object'
            ) {
                targetData = path.shift();
            }
            // 2) parsing path
            path = path.map(item => `${item}`.split('.')).flat();
            // 3) setting up description
            let targetDescription = description;
            if (!targetDescription) {
                return false;
            }
            if (Object.keys(targetDescription).length === 0) {
                return false;
            }

            path.forEach(step => {
                const {
                    type,
                    child,
                    polymorphs,
                    children,
                } = targetDescription;
                if (type === 'polymorph') {
                    const activePolymorphs = Object.keys(polymorphs).filter(
                        polymorphDescriminator => Object.keys(polymorphs[polymorphDescriminator]).find(
                            polymorphDescriminatorValue => step in polymorphs[polymorphDescriminator][polymorphDescriminatorValue]
                        )
                    );
                    // find will stop once targetDescription is found
                    const polymorphUsed = activePolymorphs.find(polymorphDescriminator => {
                        // targetData for polymorph
                        const {
                            [polymorphDescriminator]: polymorphDescriminatorValue
                        } = targetData || {};
                        // index into polymorphs
                        const {
                            [polymorphDescriminator]: {
                                [polymorphDescriminatorValue]: {
                                    // polymorphFields
                                    [step]: polymorphDescription
                                } = {}
                            } = {}
                        } = polymorphs;
                        if (polymorphDescription) {
                            targetDescription = polymorphDescription;
                            targetData = targetData?.[step];
                        }
                        return Boolean(polymorphDescription);
                    });
                    if (!polymorphUsed) {
                        targetDescription = children[step];
                        targetData = targetData?.[step];
                    }
                } else if (type === 'object' && children) {
                    targetDescription = children[step];
                    targetData = targetData?.[step];
                } else if (type === 'array') {
                    targetDescription = child;
                    targetData = targetData?.[step];
                } else {
                    targetDescription = targetDescription[step];
                    targetData = targetData?.[step];
                }
            });

            if (targetDescription?.type === 'polymorph') {
                // mighty polymorphing rangers!
                Object.keys(targetDescription.polymorphs).forEach(
                    polymorphDescriminator => {
                        const { [polymorphDescriminator]: polymorphDescriminatorValue } = targetData;
                        targetDescription = {
                            ...targetDescription,
                            children: {
                                ...targetDescription.children,
                                ...targetDescription.polymorphs[polymorphDescriminator]?.[polymorphDescriminatorValue]
                            }
                        }
                    }
                );
            }

            return targetDescription;
        },
        [description, stateRef]
    );

    const [validationErrors, setValidationErrors] = useState(false);
    const clearValidationErrors = useCallback(() => setValidationErrors(false), [setValidationErrors]);

    const validate = useCallback(
        (step = undefined) => {
            const description = describe();
            const state = stateRef.current;
            const validationErrors = {};
            const addValidationError = (key, message) => {
                const {
                    [key]: fieldValidationErrors = []
                } = validationErrors;
                fieldValidationErrors.push(message);
                validationErrors[key] = fieldValidationErrors;
            };

            Object.keys(description).forEach(key => {
                const field = description[key];
                const value = state[key];
                if (!field.readOnly) {
                    if (field.type === 'string') {
                        if (typeof value === 'string') {
                            // string length min/max
                            if (field.maxLength && field.maxLength < value.length) {
                                addValidationError(key, field.errorMessages.maxLength.replace('{max_length}', field.maxLength));
                            } else if (field.minLength && field.minLength > value.length) {
                                addValidationError(key, field.errorMessages.minLength.replace('{min_length}', field.minLength));
                            }
                            // various type validations
                            if (!field.allowBlank && value === '') {
                                addValidationError(key, field.errorMessages.blank);
                            }
                        } else {
                            if (field.required && value === undefined) {
                                addValidationError(key, field.errorMessages.required);
                            } else if (!field.allowNull && value === null) {
                                addValidationError(key, field.errorMessages.null);
                            } else {
                                addValidationError(key, field.errorMessages.invalid);
                            }
                        }
                    }
                }
            });
            const valid = Boolean(Object.keys(validationErrors).length === 0);
            setValidationErrors(valid ? false : validationErrors);
            return valid;
        },
        [describe, stateRef]
    );

    const asyncSave = useCallback(
        () => {
            if (validate()) {
                const saveFunc = (
                    (mode === MODE_UPDATE && onUpdate) ||
                    (mode === MODE_CREATE && onCreate) ||
                    (mode === MODE_AUTO && (id || noId) && onUpdate) ||
                    (mode === MODE_AUTO && !id && onCreate)
                );
                const saveArgs = (saveFunc === onCreate || noId)
                    ? [stateRef.current]
                    : [id, stateRef.current];
                const saveResultHandler = savedData => {
                    // TODO does resetting persistent state belong here?
                    // lol i think so
                    if (postSaveBehavior === POST_SAVE_BAHAVIOR_REPLACE) {
                        setInitialValue(savedData);
                        if (autoSaveReconcile) {
                            const initialState = initialValueRef.current;
                            const currentState = stateRef.current;
                            baseSetState(autoSaveReconcile({
                                initialState,
                                currentState,
                                savedState: savedData,
                            }));
                        } else {
                            baseResetState();
                        }
                    } else if (postSaveBehavior === POST_SAVE_BAHAVIOR_CLEAR) {
                        baseResetState();
                    }
                    return savedData;
                }
                const saveResult = saveFunc(...saveArgs);
                if (saveResult instanceof Promise) {
                    return saveResult
                        .then(saveResultHandler)
                        .then(onSave);
                }
                saveResultHandler(saveResult);
                onSave && onSave(saveResult);
                return saveResult;
            }
        }, [id, onCreate, onUpdate, onSave, stateRef, setInitialValue, initialValueRef, validate, autoSaveReconcile, baseSetState, baseResetState, postSaveBehavior, mode, noId]
    );


    const {
        callback: save,
        loading: saving,
        error: saveError,
        reset: clearSaveError
    } = useAsyncCallback(asyncSave);

    // consolidate form validation errors and server side errors
    const fieldErrors = {
        ...(validationErrors || {}),
        ...(saveError || {})
    };

    // calling will schedule/delay save in autoSaveTimeout miliseconds
    const {
        callback: saveDebounced,
        pending: pendingSave
    } = useThrottle(save, { throttle: autoSaveTimeout, delay: true });
    // here we wrap all of our state modifiers so they can support autosave
    const autoSaveWrapper = useCallback((stateModifier, ...update) => {
        stateModifier(...update);
        if (autoSave) {
            saveDebounced();
        }
    }, [autoSave, saveDebounced]);
    const setState = useCallback((...update) => autoSaveWrapper(baseSetState, ...update), [autoSaveWrapper, baseSetState]);
    const updateState = useCallback((...update) => autoSaveWrapper(baseUpdateState, ...update), [autoSaveWrapper, baseUpdateState]);
    const resetState = useCallback((...update) => autoSaveWrapper(baseResetState, ...update), [autoSaveWrapper, baseResetState]);
    const clearState = useCallback((...update) => autoSaveWrapper(baseClearState, ...update), [autoSaveWrapper, baseClearState]);
    const resetStatePath = useCallback((...update) => autoSaveWrapper(baseResetStatePath, ...update), [autoSaveWrapper, baseResetStatePath]);

    const cancel = useCallback(() => {
        resetState();
        clearSaveError();
        clearValidationErrors();
    }, [resetState, clearSaveError, clearValidationErrors]);

    // setting up loading state helpers
    const updating = Boolean(
        saving && (
            (mode === MODE_AUTO && id) ||
            mode === MODE_UPDATE
        )
    );
    const creating = Boolean(
        saving && (
            (mode === MODE_AUTO && !id) ||
            mode === MODE_CREATE
        )
    );
    const loadState = ((loading || loadingDescription) && 'loading')
        || (updating && 'updating')
        || (creating && 'creating')
        || (pendingSave && 'about to save')
        || null;

    return {
        // Form State
        // initial value interface
        initialValueRef,
        initialValue,
        setInitialValue,
        updateInitialValue,
        clearInitialValue,

        // state interface
        stateRef,
        state,
        setState,
        updateState,
        resetState,
        clearState,

        // experimental state interface
        resetStatePath,

        // description/meta
        reloadDescription,
        loadingDescription,
        description,
        descriptionError,
        describe,

        // loading
        reload,
        loading: loading || loadingDescription,
        loadError,
        clearLoadError,

        // saving
        save,
        pendingSave,
        saving,
        updating,
        creating,
        saveError,
        clearSaveError,

        // validation
        validate,
        fieldErrors,

        // helpers
        isNewRecord,
        isDirty,
        loadState,
        cancel
    }
}

export default usePersistentStateObject;

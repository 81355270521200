import { useEffect } from "react";
window.mapsInitialized = () => {
    console.log('places loaded')
};

// MORE INFO ON HERE  https://developers.google.com/maps/documentation/javascript/place-autocomplete#get-place-information


export function useAddressSearch({
    inputRef, setup, onChange,
}) {
    const googleMapsApiLoaded = false; //useScript(`//maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&loading=async&callback=gg`, setup);
    useEffect(() => {
        if (googleMapsApiLoaded) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
                componentRestrictions: { country: 'us' },
                fields: ['address_components', 'adr_address'],
                types: ['address']
            });
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const update = {
                    addSearch: '',
                    addressLine1: '',
                    zip: '',
                    city: '',
                    state: '',
                    country: '',
                };
                place.address_components.forEach(component => {
                    const componentType = component.types[0];
                    if (componentType === 'street_number') {
                        update.addressLine1 = `${component.long_name} ${update.addressLine1}`;
                    } else if (componentType === 'route') {
                        update.addressLine1 += component.short_name;
                    } else if (componentType === 'postal_code') {
                        update.zip = `${component.long_name}${update.zip}`;
                    } else if (componentType === 'postal_code_suffix') {
                        update.zip = `${update.zip}-${component.long_name}`;
                    } else if (componentType === 'locality') {
                        update.city = component.long_name;
                    } else if (componentType === 'administrative_area_level_1') {
                        update.state = component.short_name;
                    } else if (componentType === 'country') {
                        update.country = component.long_name;
                    }
                });

                // send update
                const path = inputRef.current.name.split('.');
                path.pop();
                onChange(...path, update);
                const focusName = [...path, 'addressLine2'].join('.');
                inputRef.current.form[focusName].focus();
            });
        }
    }, [googleMapsApiLoaded, inputRef, onChange]);
}
export default useAddressSearch;

import AddCreditCardIcon from '@mui/icons-material/AddCardRounded';
import AddPostcardIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import AddCreditsIcon from '@mui/icons-material/AttachMoneyRounded';
import ContactsIcon from '@mui/icons-material/ContactsRounded';
import CreateIcon from '@mui/icons-material/CreateRounded';
import PrintIcon from '@mui/icons-material/Outbox';
import AddContactIcon from '@mui/icons-material/PersonAddAltRounded';
import PostCardIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { Menu, MenuItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItemButton, { listItemButtonClasses } from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';
import {
    Link, useMatch,
    useResolvedPath,
} from 'react-router-dom';
import useToggle from '../hooks/useToggle';
import useUserAccount from '../hooks/useUserAccount';
import NewContactDialog from './NewContactDialog';

function AppMenuItemBase({ to, children, ...props }) {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });
    return (
        <ListItemButton
            component={Link}
            to={to}
            selected={match !== null}
            {...props}>
            {children}
        </ListItemButton>
    );
}

function AppMenuActionFabBase({ children, ...props }) {
    return (
        <Fab
            variant="extended"
            // component={Link}
            color="secondary"
            {...props}>
            <CreateIcon sx={{ mr: theme => theme.spacing(2) }} />
            {children}
        </Fab>
    );
}
const AppMenuActionFab = styled(AppMenuActionFabBase)(({ theme }) => ({
    margin: theme.spacing(2),
    paddingRight: theme.spacing(4)
}));

const AppMenuItem = styled(AppMenuItemBase)(({ theme }) => ({
    // minWidth: 150
    marginRight: theme.spacing(1),
    borderRadius: '0px 25px 25px 0px',
    transition: theme.transitions.create('all'),
    boxShadow: theme.shadows[0],
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    [`&.${listItemButtonClasses.selected}, &:hover`]: {
        boxShadow: theme.shadows[1],
    },
}));

const AppMenuItemText = styled(({ children, ...props }) => (
    <ListItemText
        primaryTypographyProps={{ noWrap: true }}
        {...props}>
        {children}
    </ListItemText>
))({

});

function AppMenu({ className }) {
    const {
        account,
        loading,
    } = useUserAccount();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const {
        state: newContactDialogOpen,
        toggleOff: handleCloseContactDialog,
        toggleOn: openNewContactDialog,
    } = useToggle(false);


    return (
        <List
            disablePadding
            component="nav"
            className={className}>
            <AppMenuActionFab
                id="new-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >New</AppMenuActionFab>
            <Menu
                id="new-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'new-button',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem component={Link} to="/postcards/new/edit">
                    <ListItemIcon>
                        <AddPostcardIcon />
                    </ListItemIcon>
                    <ListItemText>
                        MojiPost Postcard
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={openNewContactDialog}>
                    <ListItemIcon>
                        <AddContactIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Add Contact
                    </ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem>
                    <ListItemIcon>
                        <AddCreditsIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Add Credits
                    </ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <AddCreditCardIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Add Credit Card
                    </ListItemText>
                </MenuItem>
            </Menu>
            {newContactDialogOpen && (
                <NewContactDialog
                    open={newContactDialogOpen}
                    onClose={handleCloseContactDialog}
                />
            )}
            {/* <AppMenuItem to="/explore" >
                <ListItemIcon>
                    <ExploreIcon />
                </ListItemIcon>
                <AppMenuItemText>Explore Templates</AppMenuItemText>
            </AppMenuItem> */}
            <AppMenuItem to="/postcards" >
                <ListItemIcon>
                    <PostCardIcon />
                </ListItemIcon>
                <AppMenuItemText>Postcards</AppMenuItemText>
            </AppMenuItem>
            <AppMenuItem to="/contacts">
                <ListItemIcon>
                    <ContactsIcon />
                </ListItemIcon>
                <AppMenuItemText>Contacts</AppMenuItemText>
            </AppMenuItem>
            <AppMenuItem to="/prints">
                <ListItemIcon>
                    <PrintIcon />
                </ListItemIcon>
                <AppMenuItemText>Prints</AppMenuItemText>
            </AppMenuItem>
            <Divider sx={{ my: theme => theme.spacing(2) }} />
            {!loading && <Typography align="center" color="textSecondary" variant="h6">Credits ({account.credits})</Typography>}
            {/* <AppMenuItem to="/settings">
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
            </AppMenuItem> */}
        </List>
    );
}

export default AppMenu;

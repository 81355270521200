import { useCallback, useEffect, useRef } from 'react';

// delay=false - callback will be called exactly throttle miliseconds after throttle callback is called
// delay=true - callback will be called exactly throttle miliseconds after throttle callbacks most recent call
function useThrottle(callback, {
    throttle = 0,
    delay = false,
    debug = null,
}) {
    // there is some state to be maintained that should not re-render component
    const stateRef = useRef({
        args: null,
        timeout: false
    });
    stateRef.current.callback = callback;
    stateRef.current.throttle = throttle;

    // when component unmounts make sure to clear timout
    useEffect(() => {
        return () => {
            clearTimeout(stateRef.current.timeout);
        }
    }, [stateRef]);

    // build throttled function
    const throttledFunction = useCallback(
        (...args) => {
            const { callback, throttle, timeout } = stateRef.current;
            if (throttle > 0) {
                // if throttle > 0 then update args reference
                stateRef.current.args = args;
                // if there is no timeout set or if we are in a delayed throttle
                if (timeout === false || delay) {
                    // debug && console.log(debug, timeout ? 'recreating timeout' : 'creating timeout');
                    clearTimeout(timeout);
                    stateRef.current.timeout = setTimeout(() => {
                        stateRef.current.timeout = false;
                        // debug && console.log(debug, 'timeout triggered and cleared')
                        callback(...stateRef.current.args);

                    }, throttle);
                } else {
                    // debug && console.log(debug, 'throttled call, timeout exists ignoreing');
                }
            } else {
                // if delay <= 0 then the callback does not throttle
                // debug && console.log(debug, 'no throttle, skip timeout');
                callback(...args)
            }
        }, [stateRef, delay, debug]
    );

    return {
        callback: throttledFunction,
        pending: Boolean(stateRef.current.timeout)
    };
}

export default useThrottle;

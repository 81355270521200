import { useEffect, useMemo } from "react";
import useToggle from "../../../hooks/useToggle";

export default function useKeybaord({ layerSelection, viewport }) {
    const {
        deleteSelection,
        toggleSelectionVisibility,
        toggleSelectionLock,
    } = layerSelection;

    const {
        togglePreview,
    } = viewport;

    const {
        state: debugMode,
        toggleOn: debugOn,
        toggleOff: debugOff,
    } = useToggle(false);

    const {
        actions,
        eventTypes,
    } = useMemo(() => {
        const deleteAction = {
            on: ['keydown'],
            when: { key: 'Delete' },
            do: deleteSelection,
            description: 'Deletes your current selection.',
        };
        const hideAction = {
            on: ['keydown'],
            when: { key: 'h', ctrlKey: true },
            do: toggleSelectionVisibility,
            descriptions: 'Toggles selection visibility',
        };
        const lockAction = {
            on: ['keydown'],
            when: { key: 'l', ctrlKey: true },
            do: toggleSelectionLock,
            description: 'Toggles selection lock',
        };
        const debugModeOnAction = {
            on: ['keydown'],
            when: { key: '/' },
            do: debugOn,
            description: 'Press Down to browse debug information.',
        };
        const debugModeOffAction = {
            on: ['keyup'],
            when: { key: '/' },
            do: debugOff,
        };
        const previewModeToggleAction = {
            on: ['keyup'],
            when: { key: ' ', ctrlKey: true },
            do: togglePreview,
            description: 'Hold to display debug information'
        }
        const actionList = [deleteAction, hideAction, debugModeOnAction, debugModeOffAction, previewModeToggleAction, lockAction];
        const eventTypeSet = new Set();
        actionList.forEach(action => action.on.forEach(eventTypeSet.add.bind(eventTypeSet)));

        return {
            actions: actionList,
            eventTypes: eventTypeSet,
        };
    }, [deleteSelection, toggleSelectionVisibility, toggleSelectionLock, debugOn, debugOff, togglePreview]);

    useEffect(() => {
        const listener = event => {
            // match event to actions
            actions.forEach(action => {
                const condition = key => action.when[key] === event[key];
                if (action.on.includes(event.type) && Object.keys(action.when).every(condition) && action.do) {
                    action.do();
                    event.preventDefault();
                }
            })
        };

        // loop though to register all listeners
        eventTypes.forEach(eventType => document.body.addEventListener(eventType, listener));
        return () => {
            eventTypes.forEach(eventType => document.body.removeEventListener(eventType, listener));
        };
    }, [actions, eventTypes]);


    return {
        debugMode,
        actions,
    };
}

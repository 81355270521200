
export default class Vector {
    static from(vector) {
        return new Vector(vector);
    }
    constructor(attributes) {
        if (attributes instanceof Vector) {
            this._distance = attributes._distance;
            this._angle = attributes._angle;
            this._x = attributes._x;
            this._y = attributes._y;
        } else {
            const { distance = null, angle = null, angleDeg = null, x = null, y = null } = attributes;
            this._distance = distance;
            this._angle = angle;
            if (angleDeg !== null) {
                this.angleDeg = angleDeg;
            }
            this._x = x;
            this._y = y;
        }
    }

    // MODE_RECTANTULAR = 'rectangular';
    // MODE_POLAR = 'polar';
    // get mode() {
    //     return this._x === null && this._y === null
    //         ? MODE_POLAR
    //         : MODE_RECTANTULAR;
    // }

    get distance() {
        if (this._distance === null) {
            this._distance = Math.sqrt(Math.pow(this._x, 2) + Math.pow(this._y, 2));
        }
        return this._distance;
    }
    set distance(value) {
        this._angle = this.angle;
        this._x = null;
        this._y = null;
        this._distance = value;
    }
    get angle() {
        if (this._angle === null) {
            this._angle = Math.atan2(this._y, this._x);
        }
        while (this._angle < 0) this._angle += 2 * Math.PI;
        while (this._angle > 2 * Math.Pi) this._angle -= 2 * Math.PI;
        return this._angle;
    }
    set angle(value) {
        this._distance = this.distance;
        this._x = null;
        this._y = null;
        this._angle = value;
    }
    get angleDeg() {
        return this.angle * 180 / Math.PI;
    }
    set angleDeg(value) {
        this.angle = value * Math.PI / 180;
    }
    get x() {
        if (this._x === null) {
            this._x = this._distance * Math.cos(this._angle);
        }
        return this._x;
    }
    set x(value) {
        this._y = this.y;
        this._distance = null;
        this._angle = null;
        this._x = value;
    }
    get y() {
        if (this._y === null) {
            this._y = this._distance * Math.sin(this._angle);
        }
        return this._y;
    }
    set y(value) {
        this._x = this.x;
        this._distance = null;
        this._angle = null;
        this._y = value;
    }

    multiply(multiplier) {
        if (this._distance !== null) {
            this._distance *= multiplier;
        }
        if (this._x !== null) {
            this._x *= multiplier;
        }
        if (this._y !== null) {
            this._y *= multiplier;
        }
        return this;
    }

    multiplyAround(multiplier, point) {
        if (typeof multiplier === 'object' && 'x' in multiplier && 'y' in multiplier) {
            return this.sub(point)
                .multiplyX(multiplier.x)
                .multiplyY(multiplier.y)
                .add(point);
        }
        return this.sub(point)
            .multiply(multiplier)
            .add(point);
    }

    multiplyX(multiplier) {
        this.x *= multiplier;
        return this;
    }

    multiplyY(multiplier) {
        this.y *= multiplier;
        return this;
    }

    sub(vector) {
        this.x -= vector.x;
        this.y -= vector.y;
        return this;
    }
    add(vector) {
        this.x += vector.x;
        this.y += vector.y;
        return this;
    }

    rotate(angle) {
        this.angle += angle;
        return this;
    }
    rotateDeg(angle) {
        this.rotate(angle * Math.PI / 180);
        return this;
    }

    rotateDegAroundPoint(angle, point) {
        return this.sub(point)
            .rotateDeg(angle)
            .add(point);
    }

    rotateAroundPoint(angle, point) {
        return this.sub(point)
            .rotate(angle)
            .add(point);
    }

    getXVector() {
        return new Vector({ x: this.x, y: 0 });
    }
    getYVector() {
        return new Vector({ x: 0, y: this.y });
    }
    mirrorX() {
        this.y *= -1;
        return this;
    }
    mirrorY() {
        this.x *= -1;
        return this;
    }
    flipXY() {
        let { x, y } = this;
        this.x = y;
        this.y = x;
        return this;
    }

    toRectangular() {
        return { x: this.x, y: this.y };
    }
    toPolar() {
        return { distance: this.distance, angle: this.angle };
    }
    toPolarDeg() {
        return { distance: this.distance, angleDeg: this.angleDeg };
    }
    log(name, ...other) {
        const { x, y, distance, angle, angleDeg } = this;
        console.log({ name, x, y, distance, angle, angleDeg }, ...other);
        return this;
    }
}

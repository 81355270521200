import { useCallback, useRef } from "react";
import { setStateObject, updateStateObject } from "./useXState";

const DEFAULT_INITIAL_VALUE = {};

function useRefObject(initialValue = DEFAULT_INITIAL_VALUE, config = {}) {
    // code to manage initial Value ref
    const valueRef = useRef(initialValue);
    const setValue = useCallback(
        (...update) => valueRef.current = setStateObject(valueRef.current, ...update),
        [valueRef]
    );
    const updateValue = useCallback(
        (...update) => valueRef.current = updateStateObject(valueRef.current, ...update),
        [valueRef]
    );
    const clearValue = useCallback(
        () => valueRef.current = {},
        [valueRef]
    );
    const resultRef = useRef({});
    Object.assign(resultRef.current, {
        valueRef,
        value: valueRef.current,
        setValue,
        updateValue,
        clearValue
    });
    return resultRef.current;
}

export default useRefObject;
